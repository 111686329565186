import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { layout } from 'styled-system';
import { useI18n } from 'services/i18n';
import useSelectors from 'helpers/useSelectors';
import { Redirect } from 'react-router';
import BackgroundImageSrc from 'media/01.jpg';

import { selectors as authSelectors, actions as authActions } from 'store/auth';
import { PATH as pathStartUp } from 'scenes/start-up';

import LoginSpinner from 'components/login-spinner';

import {
  Box,
  Text,
  Button,
  Checkbox,
  LogoInbox,
} from 'ux-thebackyard';

import Input from './Input';

const BackGroundImage = styled(Box)`
    background-image: url(${BackgroundImageSrc});
    background-size: cover;
    height: 100%;
    width:100%;
    ${layout}
`;

const WelcomeContainer = styled(Box)`
    background: linear-gradient(135deg,rgba(46,52,81,.2) 0%,rgba(46,52,81,.95) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    width: 100%;
    height: 100%;
    padding: 0 2.5rem;
`;

const WelcomeTitle = styled(Text)`
    line-height: 1.1;
`;

// const WelcomeSubTitle = styled(Text)`
//     padding-top: 15px;
//     line-height: 1.5;
// `;

const Form = styled('form')`
  width: 100%;
`;

const FormContainer = styled(Box)`
    flex-direction: column;
    height: 100%;
    padding: 0 3rem;
    background-color: white;
    ${layout}
`;

const LoginLabel = styled(Box)`
  margin-bottom: 30px;
  width:100%;
`;

export const defaultUser = {
  email: '',
  password: '',
  keepSession: true,
};

const failedStyles = {
  borderColor: 'red',
};

export default () => {
  const [user, setUser] = useState(defaultUser);
  const t = useI18n();
  const dispatch = useDispatch();

  const [
    authData,
    isBusy,
    isFailed,
  ] = useSelectors(
    authSelectors.signIn.getData,
    authSelectors.signIn.getIsBusy,
    authSelectors.signIn.getIsFailed,
  );

  const handleSignIn = () => dispatch(authActions.signIn.Call(user));
  const handleOnChange = (change) => {
    setUser({ ...user, ...change });
    dispatch(authActions.signIn.NoStarted());
  };

  useEffect(() => {
    if (isFailed) setUser({ ...user, email: '', password: '' });
  }, [isFailed]);

  if (authData) return <Redirect to={pathStartUp} />;

  return (
    <Box display="flex" height="100vh">
      {isBusy && <LoginSpinner />}
      <BackGroundImage display={['none', 'none', 'block']}>
        <WelcomeContainer>
          <WelcomeTitle fontSize="xxl" fontWeight="bld">{t('welcome_edx_inbox')}</WelcomeTitle>
          {/* <WelcomeSubTitle fontSize="md" fontWeight="med">{t('customers_portal')}</WelcomeSubTitle> */}
        </WelcomeContainer>
      </BackGroundImage>
      <FormContainer centeredContent width={['100%', '100%', '35rem']} flexShrink="0">
        <LogoInbox type="vertical" alt="loginLogo" width="130px" mb="40px" />
        <LoginLabel fontSize="md">{t('login')}</LoginLabel>

        <Form onSubmit={(e) => e.preventDefault()}>
          <Input
            id="email"
            type="email"
            placeholder={t('email')}
            value={user.email}
            onChange={({ target: { value } }) => handleOnChange({ email: value })}
            {...(isFailed ? failedStyles : {})}
          />
          <Input
            id="password"
            type="password"
            placeholder={t('password')}
            value={user.password}
            onChange={({ target: { value } }) => handleOnChange({ password: value })}
            {...(isFailed ? failedStyles : {})}
          />
          <Box display="flex">
            <Checkbox
              id="remember"
              label={t('remember_me')}
              checked={user.keepSession}
              disabled // TODO: enable this functionality en next versions
              onChange={(value) => handleOnChange({ keepSession: value })}
              fontSize="sm"
            />
            <Button
              design="link"
              onClick={() => alert('developing')}
              label={t('forgot_password')}
              ml="auto"
              fontWeight="xs"
            />

          </Box>
          <Box centeredContent>
            <Button
              design="primary"
              rounded
              p="0.75rem 2.5rem"
              mt="2rem"
              id="button_submit"
              type="submit"
              disabled={!user.password || !user.email}
              onClick={handleSignIn}
              label={t('start_session')}
            />
          </Box>
        </Form>
      </FormContainer>
    </Box>
  );
};
