import React from 'react';
import { PageInbox, Box, Text } from 'ux-thebackyard';
import { Block } from '@material-ui/icons';

const UnauthorizedPage = () => (
  <PageInbox>
    <Box
      centeredContent
      height="calc(100vh - 20rem)"
      bg="white"
    >
      <Box display="flex" alignItems="center">
        <Block fontSize="large" style={{ color: 'gray' }} />
        <Text
          ml="1rem"
          fontSize="xl"
          color="gray"
        >
          No posee acceso a este servicio
        </Text>

      </Box>
    </Box>
  </PageInbox>
);

export default UnauthorizedPage;
