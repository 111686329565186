import React from 'react';
import PropTypes from 'prop-types';
import Translate from 'components/translate';

import { Box, Text } from 'ux-thebackyard';

const heads = [
  'areas',
  'rfcs',
  'company_name',
];

const List = ({ data }) => (
  <Box display="flex" flexDirection="column">
    {data?.map((d, index) => (<Text key={index} mt="5px">{d}</Text>))}
  </Box>
);
List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
};

const transmuter = (data) => {
  const {
    areaName, areaRfcs,
  } = data;

  return [
    areaName,
    <List data={areaRfcs?.map(({ code }) => code)} />,
    <List data={areaRfcs?.map(({ company }) => company)} />,
  ];
};

export default {
  heads: heads?.map((head) => <Translate label={head} />),
  transmuter,
};
