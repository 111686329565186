import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useI18n } from 'services/i18n';
import useSelectors from 'helpers/useSelectors';
import { useModal } from 'services/modal';
import { ablePurchaseOrderFields, docsTypes } from 'own/contants';
import { selectors as userSelectors } from 'store/user';

import {
  DataBox,
  Button,
  FormInbox,
  FormInboxFieldTypes,
  FormInboxValidationType,
} from 'ux-thebackyard';

import {
  actions as addPurchaseOrderActions,
  selectors as addPurchaseOrderSelectors,
} from 'store/add-purchase-order';

const NewPurchaseOrder = () => {
  const [submitBtnProps, setSubmitBtnProps] = useState({});

  const dispatch = useDispatch();
  const t = useI18n();
  const { onClose, ModalFooter } = useModal();

  const [
    rfcsIssuer,
    rfcsReceiver,

    isBusy,
    isSuccess,
    isFailed,
  ] = useSelectors(
    userSelectors.fetchRFCs.getRFCsIssuer,
    userSelectors.fetchRFCs.getRFCsReceiver,

    addPurchaseOrderSelectors.getIsBusy,
    addPurchaseOrderSelectors.getIsSuccess,
    addPurchaseOrderSelectors.getIsFailed,
  );

  useEffect(() => () => dispatch(addPurchaseOrderActions.NoStarted()), []);

  useEffect(() => {
    if (isSuccess) onClose();
  }, [isSuccess]);

  const handleUploadPurchaseOrder = (purchase) => (
    dispatch(addPurchaseOrderActions.Call({
      ...purchase,
      [ablePurchaseOrderFields.TYPE]: docsTypes.PURCHASE,
      [ablePurchaseOrderFields.DATE]: new Date(),
    }))
  );

  const itemsForm = [
    {
      label: t('number'),
      fieldType: FormInboxFieldTypes.INPUT,
      type: 'number',
      fieldName: ablePurchaseOrderFields.PURCHASE_ORDER,
      required: true,
      validations: [
        { type: FormInboxValidationType.REQUIRED },
      ],
    },
    {
      label: t('issuing_rfc'),
      enableSearch: true,
      items: rfcsIssuer,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      single: true,
      fieldName: ablePurchaseOrderFields.RFC_ISSUER,
      required: true,
      validations: [
        { type: FormInboxValidationType.REQUIRED },
      ],
    },
    {
      label: t('total'),
      fieldType: FormInboxFieldTypes.INPUT,
      type: 'number',
      fieldName: ablePurchaseOrderFields.TOTAL,
      required: true,
      validations: [
        { type: FormInboxValidationType.REQUIRED },
      ],
    },
    {
      label: t('receiver_rfc'),
      enableSearch: true,
      items: rfcsReceiver,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      single: true,
      fieldName: ablePurchaseOrderFields.RFC_RECEIVER,
      required: true,
      validations: [
        { type: FormInboxValidationType.REQUIRED },
      ],
    },
  ];
  const errorMessage = {
    type: 'error',
    text: t('error_inserting_purchase_order'),
  };

  return (
    <>
      <DataBox label={t('new_purchase_order')} separator width="35rem">
        <FormInbox
          items={itemsForm}
          onSubmit={handleUploadPurchaseOrder}
          changeExternSubmitBtnProps={setSubmitBtnProps}
        />
      </DataBox>
      <ModalFooter
        showSpinner={isBusy}
        message={isFailed && errorMessage}
      >
        <Button
          design="primary"
          rounded
          m="10px"
          p="12px 30px"
          label={t('upload')}
          {...{ ...submitBtnProps, disabled: submitBtnProps?.disabled || isBusy }}
        />
      </ModalFooter>
    </>
  );
};

export default NewPurchaseOrder;
