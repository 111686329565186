import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import FormControl from 'components/form-control';

import { Box } from 'ux-thebackyard';

const FormGroup = styled(Box)`
    margin-bottom: 1.5rem;   
`;
const FormControlLabel = styled.label`
    color: ${({ theme }) => theme.colors.primaryText};
    font-weight: 500;
    width: 100%;
    margin-bottom: .5rem;
    text-align: right;
    display: block;
`;

const FormInputGroup = ({ label, ...remainderProps }) => (
  <FormGroup>
    <Grid container alignItems="center" spacing={4}>
      <Grid item xs={12} md={3}>
        <FormControlLabel>{label}</FormControlLabel>
      </Grid>
      <Grid item xs={12} md={7}>
        <FormControl {...remainderProps} />
      </Grid>
    </Grid>
  </FormGroup>
);

FormInputGroup.propTypes = {
  label: PropTypes.string,
};

export default FormInputGroup;
