import React from 'react';
import { useI18n } from 'services/i18n';
import { PageInbox, Box } from 'ux-thebackyard';

import BasicInfo from './BasicInfo';
import EditableInfo from './EditableInfo';

const Profile = () => {
  const t = useI18n();

  return (
    <PageInbox label={t('my_account')}>
      <Box display={['block', 'block', 'flex']}>
        <BasicInfo
          height="inherit"
          width={['100%', '100%', '25%']}
        />
        <EditableInfo
          ml={['0', '0', '2rem']}
          mt={['2rem', '2rem', '0']}
          height="inherit"
          width={['100%', '100%', '75%']}
        />
      </Box>
    </PageInbox>
  );
};

export default Profile;
