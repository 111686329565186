import { combineReducers } from 'redux';
import { call } from 'redux-saga/effects';
import RS from 'helpers/RS';

const NAME = 'users';

function* fetch(api) {
  const { value } = yield call(api.userFetchUsers);
  return value;
}

const fetchRS = RS({
  nestedPath: [NAME],
  storeName: 'fetch',
  tryGenerator: fetch,
});

function* add(api, { payload: user }) {
  return yield call(api.userAddUsers, { data: { users: [user] } });
}

const addRS = RS({
  nestedPath: [NAME],
  storeName: 'add',
  tryGenerator: add,
});

function* update(api, { payload: user }) {
  return yield call(api.userUpdateUsers, { data: { users: [user] } });
}

const updateRS = RS({
  nestedPath: [NAME],
  storeName: 'update',
  tryGenerator: update,
});

function* remove(api, { payload: user }) {
  const { userEmail } = user;
  return yield call(api.userRemoveUsers, { data: { users: [{ userEmail }] } });
}

const removeRS = RS({
  nestedPath: [NAME],
  storeName: 'remove',
  tryGenerator: remove,
});

/// ////////////////////////////////////////////////////////////////////////////////

export const sagas = [
  fetchRS.sagas,
  addRS.sagas,
  updateRS.sagas,
  removeRS.sagas,
];

export const reducer = {
  [NAME]: combineReducers({
    ...fetchRS.reducer,
    ...addRS.reducer,
    ...updateRS.reducer,
    ...removeRS.reducer,
  }),
};

export const actions = {
  fetch: fetchRS.actions,
  add: addRS.actions,
  update: updateRS.actions,
  remove: removeRS.actions,
};

export const selectors = {
  fetch: fetchRS.selectors,
  add: addRS.selectors,
  update: updateRS.selectors,
  remove: removeRS.selectors,
};
