import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useSelectors from 'helpers/useSelectors';
import { purchaseOrderPropType } from 'helpers/propTypes';
import { selectors as userSelectors } from 'store/user';

import { ableParamsToFetch } from 'own/contants';
import { cfdiStatesValues } from 'own/helpers';
import { useI18n } from 'services/i18n';
import { useModal } from 'services/modal';
import {
  SvgSyncSolid,
} from 'react-line-awesome-svg';
import {
  actions as relationshipActions,
  selectors as relationshipSelectors,
} from 'store/relationship';

import {
  actions as docsActions,
  selectors as docsSelectors,
} from 'store/documents';

import DocsUploader from 'components/docs-uploader';

import {
  TableBox,
  Accordion,
  Button,
  DataBox,
  FormInbox,
  FormInboxFieldTypes,
} from 'ux-thebackyard';

import RelatedDocsTrans from 'own/table-transmuters/related-docs';
import RelateDocsTrans from 'own/table-transmuters/relate-docs';

const defaultParams = { page: 1 };

const RelateDocs = ({ doc }) => {
  const [relDocsPage, setRelDocsPage] = useState(1);
  const [params, setParams] = useState(defaultParams);

  const t = useI18n();
  const dispatch = useDispatch();
  const { ModalFooter } = useModal();

  const handleChangePage = (page) => setParams({ ...params, page });
  const handleChangeFilters = (filters) => {
    setParams({ ...params, page: 1, ...filters });

    dispatch(relationshipActions.SetSelectedItemsToRelate([]));
    dispatch(relationshipActions.SetSelectedAllToRelate(false));
  };

  const [
    rfcsIssuer,
    rfcsReceiver,

    isBusyFetchRelatedDocs,
    relatedDocs,
    paginationRelDocs,

    selectedDocsUUID,

    isBusyRelateDocs,
    isFailedRelateDocs,
    isSuccessRelateDocs,

    isBusyDocs,
    cfdis,
    paginationDocs,
  ] = useSelectors(
    userSelectors.fetchRFCs.getRFCsIssuer,
    userSelectors.fetchRFCs.getRFCsReceiver,

    relationshipSelectors.fetch.getIsBusy,
    relationshipSelectors.fetch.getData,
    relationshipSelectors.fetch.getPagination,

    relationshipSelectors.getSelectedItems,

    relationshipSelectors.add.getIsBusy,
    relationshipSelectors.add.getIsFailed,
    relationshipSelectors.add.getIsSuccess,

    docsSelectors.getDocs2IsBusy,
    docsSelectors.getDocs2,
    docsSelectors.getDocs2Pagination,
  );

  const handleFetchRelatedDocs = () => dispatch(relationshipActions.fetch.Call({ page: relDocsPage, doc }));
  const handleFetchDocs = () => dispatch(docsActions.FetchDocs2(params));

  const handleAddRelationship = () => dispatch(relationshipActions.add.Call({
    // eslint-disable-next-line no-underscore-dangle
    [ableParamsToFetch.ID_ORDEN_C]: doc?._id,
    cfdi: selectedDocsUUID,
  }));

  useEffect(() => () => {
    if (isSuccessRelateDocs) handleFetchRelatedDocs();
  }, [isSuccessRelateDocs]);

  useEffect(() => () => {
    dispatch(relationshipActions.fetch.NoStarted());
  }, []);

  useEffect(() => {
    setRelDocsPage(1);
    setParams(defaultParams);
    if (doc) dispatch(relationshipActions.SetRefDoc(doc)); // used in actions
  }, [doc]);

  useEffect(() => {
    handleFetchRelatedDocs();
  }, [relDocsPage]);

  useEffect(() => {
    handleFetchDocs();
  }, [params]);

  const itemsFilter = [
    {
      label: t('receiver_rfc'),
      enableSearch: true,
      items: rfcsReceiver,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableParamsToFetch.RFC_RECEIVER,
    },
    {
      label: t('issuing_rfc'),
      enableSearch: true,
      items: rfcsIssuer,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableParamsToFetch.RFC_ISSUER,
    },
    {
      label: t('state'),
      enableSearch: true,
      items: cfdiStatesValues,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableParamsToFetch.STATE,
    },
    {
      label: t('date'),
      fieldType: FormInboxFieldTypes.DATE_RANGE,
      fieldNameStartDate: ableParamsToFetch.DATE_IN,
      fieldNameEndDate: ableParamsToFetch.DATE_END,
    },
    {
      label: t('folio'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableParamsToFetch.FOLIO,
    },
    {
      label: t('uuid_fiscal_folio'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableParamsToFetch.UUID,
    },
  ];

  const headBtnsPropsRelDocs = [
    {
      label: t('update'), design: 'shadow', svg: SvgSyncSolid, rounded: true, onClick: handleFetchRelatedDocs,
    },
  ];

  const headBtnsPropsDocs = [
    {
      label: t('update'), design: 'shadow', svg: SvgSyncSolid, rounded: true, onClick: handleFetchDocs,
    },
  ];

  const errorMessage = { type: 'error', text: 'Error relating documents' };

  return (
    <DataBox label={t('related_docs')} separator width={['100vw', '100vw', '80vw']}>

      <DataBox
        label={t('related_docs')}
        separator
        headBtnsProps={headBtnsPropsRelDocs}
        mb="20px"
      >
        <TableBox
          transmuter={RelatedDocsTrans.transmuter}
          heads={RelatedDocsTrans.heads}
          data={relatedDocs}
          isBusy={isBusyFetchRelatedDocs}
          noFoundMessage={t('no_related_docs_found')}
          pagination={{
            page: paginationRelDocs?.page,
            totalPages: paginationRelDocs?.totalPages,
            totalItems: paginationRelDocs?.totalDocs,
            pageSize: 50, // TODO
          }}
          onChangePage={setRelDocsPage}
          height="15rem"
          stickyHeaders
        />
      </DataBox>
      <DataBox label={t('upload_documents')} separator mb="20px">
        <DocsUploader />
      </DataBox>

      <DataBox
        label={t('document_result')}
        separator
        headBtnsProps={headBtnsPropsDocs}
      >
        <Accordion label={t('filter_search')}>
          <FormInbox
            items={itemsFilter}
            value={params}
            onSubmit={handleChangeFilters}
            submitBtnProps={{ label: t('apply') }}
          />
        </Accordion>

        <TableBox
          transmuter={RelateDocsTrans.transmuter}
          heads={RelateDocsTrans.heads}
          data={cfdis}
          isBusy={isBusyDocs}
          noFoundMessage={t('no_bills_found')}
          pagination={{
            page: paginationDocs?.page,
            totalPages: paginationDocs?.totalPages,
            totalItems: paginationDocs?.totalDocs,
            pageSize: 50, // TODO
          }}
          onChangePage={handleChangePage}
          stickyHeaders
        />

      </DataBox>

      <ModalFooter
        showSpinner={isBusyRelateDocs}
        message={isFailedRelateDocs && errorMessage}
      >
        <Button
          design="primary"
          rounded
          m="10px"
          p="12px 30px"
          onClick={handleAddRelationship}
          disabled={!selectedDocsUUID.length}
          label={t('relate_documents')}
        />
      </ModalFooter>

    </DataBox>
  );
};

RelateDocs.propTypes = {
  doc: purchaseOrderPropType,
};

export default RelateDocs;
