export const ToShortDate = (date, format = 'date') => {
  let dateToshow = date;
  if (format === 'string') {
    dateToshow = new Date(dateToshow);
  }

  return (
    dateToshow.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
  );
};
