import React from 'react';
import { NotificationsInbox } from 'ux-thebackyard';
import {
  SvgBriefcaseSolid,
  SvgUser,
} from 'react-line-awesome-svg';

export const Notifications = (props) => {
  const mockedNotifications = [
    {
      icon: SvgUser,
      label: 'Nuevo usuario creado',
      date: new Date().toDateString(),
      onClick: () => alert('click over notification #1'),
    },
    {
      icon: SvgBriefcaseSolid,
      type: 'NEW_PROVIDER',
      label: 'Nuevo proveedor añadido',
      date: new Date().toDateString(),
      onClick: () => alert('click over notification #2'),
    },
  ];

  return (
    <NotificationsInbox
      items={mockedNotifications}
      checkAllAction={{
        label: 'Ver todas las notificacione',
        onClick: () => alert('click over See all notifications'),
      }}
      {...props}
    />
  );
};
