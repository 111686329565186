export const getPagination = (data) => {
  if (!data) return null;
  const { page, pages: totalPages, totalDocument: totalDocs } = data;
  if (!page || !totalPages || !totalDocs) return null;

  return ({
    page,
    totalPages,
    totalDocs,
  });
};
