import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import useSelectors from 'helpers/useSelectors';
import { selectors as userSelectors } from 'store/user';
import { SvgPencilAltSolid } from 'react-line-awesome-svg';
import { Avatar, makeStyles } from '@material-ui/core';
import { useI18n } from 'services/i18n';
import { getInitials } from 'helpers/general';

import {
  Separator,
  DataBox,
  Box,
  Button,
  Icon,
} from 'ux-thebackyard';

const useStyles = makeStyles(() => ({
  large: {
    width: '120px',
    height: '120px',
  },
}));

const UserNameLabel = styled.h3`
    text-align: center;
    margin-top: 1rem;
    margin-bottom: .25rem;
    font-size: 1.35rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primaryText};
    text-overflow: ellipsis;
    overflow: hidden;
`;
const UserEmail = styled.p`
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #98a8b4;
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const BasicInfo = (props) => {
  const [
    userName,
    userEmail,
  ] = useSelectors(
    userSelectors.fetchUser.getUserEmail,
    userSelectors.fetchUser.getUserName,
  );

  const classes = useStyles();
  const theme = useTheme();
  const t = useI18n();

  return (
    <DataBox {...props}>
      <Box centeredContent>
        <Avatar
          aria-controls="profile-popover"
          className={classes.large}
        >
          {getInitials(userName)}
        </Avatar>
      </Box>
      <UserNameLabel>{userName}</UserNameLabel>
      <UserEmail>{userEmail}</UserEmail>

      <Separator m="20px 0" border="1px dashed #eee" />

      <Button
        design="link"
        svg={() => <Icon svg={SvgPencilAltSolid} size="28px" color={theme.colors.sideBarText} />}
        label={t('edit_profile_photo')}
      />
    </DataBox>
  );
};

BasicInfo.propTypes = {
  userName: PropTypes.string,
  userEmail: PropTypes.string,
};

BasicInfo.defaultProps = {
  userName: 'Tom Green',
  userEmail: 'tgreen@edxsolutions.com',
};

export default BasicInfo;
