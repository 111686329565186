import PropTypes from 'prop-types';

export const purchaseOrderPropType = PropTypes.shape({
  noOrdenC: PropTypes.number,
  estado: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      estado: PropTypes.string,
    }),
  ),
  date: PropTypes.string,
  rfcIssuer: PropTypes.string,
  rfcReceiver: PropTypes.string,
  total: PropTypes.number,
  cfdi: PropTypes.arrayOf(PropTypes.string),
});

export const cfdiPropType = PropTypes.shape({
  serie: PropTypes.string,
  folio: PropTypes.string,
  date: PropTypes.string,
  addressPdf: PropTypes.string,
  addressXml: PropTypes.string,
  rfcIssuer: PropTypes.string,
  rfcReceiver: PropTypes.string,
  total: PropTypes.number,
  uuid: PropTypes.string,
});

const dayReportPropType = PropTypes.shape({
  dia: PropTypes.number,
  total: PropTypes.number,
});

const monthReportPropType = PropTypes.shape({
  etiqueta: PropTypes.string,
  total: PropTypes.number,
  detalles: PropTypes.arrayOf(dayReportPropType),
});

const cfdiReportPropType = PropTypes.shape({
  etiqueta: PropTypes.string,
  total: PropTypes.number,
  detalle: PropTypes.arrayOf(monthReportPropType),
});

const kpiReportPropType = PropTypes.shape({
  retencion: PropTypes.shape({
    IEPS: PropTypes.number,
    ISR: PropTypes.number,
    IVA: PropTypes.number,
    TOTAL: PropTypes.number,
  }),
  traslado: PropTypes.shape({
    IEPS: PropTypes.number,
    ISR: PropTypes.number,
    IVA: PropTypes.number,
    TOTAL: PropTypes.number,
  }),
  total: PropTypes.number,
});

const estadoReport = PropTypes.shape({
  estado: PropTypes.string,
  total: PropTypes.number,
});

export const reportsPropType = PropTypes.shape({
  totalDocument: PropTypes.number,
  kpi: kpiReportPropType,
  estados: PropTypes.arrayOf(estadoReport),

  cfdis: PropTypes.shape({
    cfdis: cfdiReportPropType,
    cfdiError: cfdiReportPropType,
  }),

  ordeDeCompra: PropTypes.shape({
    ocAtendida: cfdiReportPropType,
    ocAtendidaParcial: cfdiReportPropType,
    ocNoAtendida: cfdiReportPropType,
  }),
});

export const areaManagementPropType = PropTypes.shape({
  area: PropTypes.string,
  rfc: PropTypes.string,
  companyName: PropTypes.string,
  status: PropTypes.string,
});

export const profileManagementPropType = PropTypes.shape({
  profileName: PropTypes.string,
  amountOfUsers: PropTypes.number,
  amountOfRules: PropTypes.number,
});

export const userManagementPropType = PropTypes.shape({
  number: PropTypes.number,
  name: PropTypes.string,
  email: PropTypes.string,
  profile: PropTypes.string,
  area: PropTypes.string,
  status: PropTypes.string,
});

export const providerPropType = PropTypes.shape({
  number: PropTypes.number,
  rfc: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  blocked: PropTypes.bool,
  status: PropTypes.string,
});

export const billPropType = PropTypes.shape({
  serie: PropTypes.string,
  folio: PropTypes.string,
  date: PropTypes.string,
  rfcIssuer: PropTypes.string,
  rfcReceiver: PropTypes.string,
  status: PropTypes.string,
  total: PropTypes.number,
  uuid: PropTypes.string,
});

export const docStatePropType = PropTypes.shape({
  estado: PropTypes.string,
  date: PropTypes.string,
});

export const docStateValidationsPropType = PropTypes.shape({
  error_message: PropTypes.string,
  error_message_extra: PropTypes.string,
  valid: PropTypes.bool,
});

export const rfcPropType = PropTypes.shape({
  code: PropTypes.string,
  company: PropTypes.string,
});

export const retentionPropTypes = {
  retention: PropTypes.shape({
    IVA: PropTypes.number,
    IEPS: PropTypes.number,
    ISR: PropTypes.number,
    TOTAL: PropTypes.number,
  }),
};

export const transferPropTypes = {
  retention: PropTypes.shape({
    IVA: PropTypes.number,
    IEPS: PropTypes.number,
    TOTAL: PropTypes.number,
  }),
};
