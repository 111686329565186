import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useSelectors from 'helpers/useSelectors';

import PropTypes from 'prop-types';
import { useHasRoles } from 'services/hasRoles';
import { useModal } from 'services/modal';
import NewUpdateUser from 'scenes/modals/new-update-user';
import { actions, selectors } from 'store/users';
import {
  TableActionsInbox,
  TableActionsInboxTypes,
  Box,
  Text,
} from 'ux-thebackyard';

import Confirm from 'scenes/modals/confirm';
import Translate from 'components/translate';

const RemoveUserConfirm = ({ user }) => {
  const dispatch = useDispatch();
  const { onClose } = useModal();

  const [
    isBusyRemoveUser,
    isSuccessRemoveUser,
    isFailedRemoveUser,
  ] = useSelectors(
    selectors.remove.getIsBusy,
    selectors.remove.getIsSuccess,
    selectors.remove.getIsFailed,
  );

  useEffect(() => () => dispatch(actions.remove.NoStarted()), []);

  useEffect(() => {
    if (isSuccessRemoveUser) {
      onClose();
      dispatch(actions.fetch.Call());
    }
  }, [isSuccessRemoveUser]);

  const handleOnConfirmRemoveUser = () => dispatch(actions.remove.Call(user));

  return (
    <Confirm
      title="remove_user_confirm_title"
      message="remove_user_confirm_message"
      errorMessage={isFailedRemoveUser && 'remove_user_error_message'}
      isBusy={isBusyRemoveUser}
      onConfirm={handleOnConfirmRemoveUser}
    />
  );
};

RemoveUserConfirm.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
};

const Actions = ({ data = {} }) => {
  const { renderComponent } = useModal();

  const NewUpdateUserWrapper = () => <NewUpdateUser user={data} />;
  const handleShowModalUpdateUser = () => renderComponent(NewUpdateUserWrapper, { hideFooter: true });

  const RemoveUserConfirmWrapper = () => <RemoveUserConfirm user={data} />;
  const handleShowModalRemoveUser = () => renderComponent(RemoveUserConfirmWrapper, { hideFooter: true });

  const { user: { full, remove, update } } = useHasRoles();
  const actionsConf = [
    (update || full) && { type: TableActionsInboxTypes.EDIT, onClick: handleShowModalUpdateUser },
    (remove || full) && { type: TableActionsInboxTypes.DELETE, onClick: handleShowModalRemoveUser },
  ];

  return (
    <TableActionsInbox actions={actionsConf} />
  );
};

const heads = [
  'actions',
  'name',
  'email_title',
  'profile',
  'area',
  'state',
];

const List = ({ data }) => (
  <Box display="flex" flexDirection="column">
    {data?.map((d, index) => (<Text key={index} mt="5px">{d}</Text>))}
  </Box>
);
List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
};

const transmuter = (data) => {
  const {
    name, userEmail, userProfile, active,
  } = data;

  const { profileName, profileAreas } = userProfile;

  return [
    <Actions data={data} />,
    name,
    userEmail,
    profileName,
    <List data={profileAreas?.map(({ areaName }) => areaName)} />,
    active ? 'Activo' : 'Inactivo',
  ];
};

export default {
  heads: heads?.map((head) => <Translate label={head} />),
  transmuter,
};
