import React from 'react';
import { useI18n } from 'services/i18n';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { formatNumberToCurrency } from 'helpers/general';

import { Box } from 'ux-thebackyard';

const Card = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1.4rem;
  box-shadow:${({ theme }) => theme.shadows.lg};
`;

const CardHeader = styled(Box)`
color: #2c304d;
font-size: 2.6rem;
`;

const CardContent = styled(Box)`
color: #98a8b4;
font-size: 1.2rem;
`;

const TotalAmountInvoices = ({ total }) => {
  const t = useI18n();
  return (
    <Card>
      <CardHeader>
        {formatNumberToCurrency(total)}
      </CardHeader>
      <CardContent>
        {t('total_amount_in_invoices')}
      </CardContent>
    </Card>
  );
};

TotalAmountInvoices.propTypes = {
  total: PropTypes.number,
};

export default TotalAmountInvoices;
