import {
  createStore, compose, applyMiddleware, combineReducers,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { intlReducer, updateIntl } from 'react-intl-redux';
import { defaultTranslations } from 'helpers/intl';
import makerApi from 'api/makerApi';
import { Restore as RestoreRS } from 'helpers/RS';
import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const history = createBrowserHistory();

// Middleware
const routingMiddleware = routerMiddleware(history);
const middlewares = applyMiddleware(sagaMiddleware, routingMiddleware);

// Compose
const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose;

// Enhancers
const enhancer = composeEnhancers(middlewares);
const enhancedReducers = combineReducers({
  ...reducers,
  router: connectRouter(history),
  intl: intlReducer,
});

// Store
const store = createStore(enhancedReducers, enhancer);

// Run sagas for authentication services
const sagaServices = { api: makerApi() };
sagas.forEach((saga) => sagaMiddleware.run(saga, sagaServices));

// Internationalization
store.dispatch(updateIntl(defaultTranslations));

// RestoreRS
store.dispatch(RestoreRS()); // Used to initialize saved data in LS

export default () => ({ store, history, defaultTranslations });
