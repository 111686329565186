import React from 'react';
import { Text } from 'ux-thebackyard';
import { useI18n } from 'services/i18n';

const Translate = ({ label }) => {
  const t = useI18n();
  return (
    typeof label === 'string' ? (
      <Text>
        {t(label)}
      </Text>
    ) : label
  );
};

export default Translate;
