import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useI18n } from 'services/i18n';

import {
  SvgShareSolid,
  SvgFilePdfSolid,
} from 'react-line-awesome-svg';
import useSelectors from 'helpers/useSelectors';
import { actions as reportsActions, selectors as reportsSelectors } from 'store/reports';
import { selectors as userSelectors, actions as userActions } from 'store/user';
import useSearch from 'services/search/useSearch';
import { ableParamsToFetch } from 'own/contants';
import { CfdisPurchaseOrders, KPITotalsStatus } from 'components/reports';
import { downloadPdf } from 'helpers/reports';
import { useModal } from 'services/modal';
import ShareReport from 'scenes/modals/share-report';

import {
  Box,
  PageInbox,
  DataBox,
  FormInbox,
  FormInboxFieldTypes,
} from 'ux-thebackyard';

const Reports = () => {
  const t = useI18n();

  const dispatch = useDispatch();
  const { renderComponent } = useModal();

  const pdfRef = useRef(null);
  const [
    rfcsIssuer,
    rfcsReceiver,
    isBusy,
    statusReport,
    cfdiReport,
    kpiReport,
    purchaseReport,
  ] = useSelectors(
    userSelectors.fetchRFCs.getRFCsIssuer,
    userSelectors.fetchRFCs.getRFCsReceiver,
    reportsSelectors.getIsBusy,
    reportsSelectors.getStatusReport,
    reportsSelectors.getCFDIReport,
    reportsSelectors.getKPIReport,
    reportsSelectors.getPurchaseReport,
  );

  const { search, params, updateParams } = useSearch();

  const FetchReports = () => dispatch(reportsActions.FetchReports(params));

  useEffect(() => {
    if (!(rfcsReceiver || rfcsIssuer)) dispatch(userActions.fetchRFCs.Call());
  }, [rfcsIssuer, rfcsReceiver]);

  useEffect(() => {
    FetchReports();
  }, [search]);

  const handleDownloadPdf = () => {
    const date = new Date();
    const currentDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    downloadPdf(pdfRef.current, `Reports-${currentDate}`);
  };

  const handleSharePdf = () => renderComponent(
    () => <ShareReport element={pdfRef.current} />,
    { hideFooter: true },
  );

  const formConfig = [
    {
      label: t('issuing_rfc'),
      enableSearch: true,
      items: rfcsIssuer,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableParamsToFetch.RFC_ISSUER,
    },
    {
      label: t('receiver_rfc'),
      enableSearch: true,
      items: rfcsReceiver,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableParamsToFetch.RFC_RECEIVER,
    },
    {
      label: t('date'),
      fieldType: FormInboxFieldTypes.DATE_RANGE,
      fieldNameStartDate: ableParamsToFetch.DATE_IN,
      fieldNameEndDate: ableParamsToFetch.DATE_END,
    },
  ];

  const headBtnsProps = [
    false && { // TODO enable when api service is ready
      label: t('share'), design: 'default', svg: SvgShareSolid, onClick: handleSharePdf,
    },
    {
      label: t('download_pdf'), design: 'default', svg: SvgFilePdfSolid, onClick: handleDownloadPdf,
    },
  ];

  return (
    <PageInbox label={t('reports')}>

      <DataBox label={t('filters')} separator headBtnsProps={headBtnsProps}>
        <FormInbox
          items={formConfig}
          value={params}
          onSubmit={updateParams}
          submitBtnProps={{ label: t('apply') }}
        />
      </DataBox>
      <Box ref={pdfRef}>
        <Box marginBottom="2rem">
          <KPITotalsStatus kpiReport={kpiReport} statusReport={statusReport} isBusy={isBusy} />
        </Box>
        <Box className="page">
          <CfdisPurchaseOrders cfdiReport={cfdiReport} purchaseReport={purchaseReport} isBusy={isBusy} />
        </Box>
      </Box>
    </PageInbox>
  );
};

export default Reports;
