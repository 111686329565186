import React, { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectors as userSelectors } from 'store/user';
import PropTypes from 'prop-types';
import { some } from 'lodash';
import { createHasRole } from './helpers';

const HasRolesContext = createContext(null);

export const HasRolesProvider = ({ children }) => {
  const userProfile = useSelector(userSelectors.fetchUser.getUserProfile);
  const value = createHasRole(userProfile?.profileRoles);
  return (
    <HasRolesContext.Provider value={value}>
      {children}
    </HasRolesContext.Provider>
  );
};

HasRolesProvider.propTypes = {
  children: PropTypes.node,
};

export const useHasRoles = () => useContext(HasRolesContext);

export const hasSome = (roles) => some(Object.values(roles), (role) => role);
