import _ from 'lodash';

const pairFiletype = {
  pdf: 'xml',
  xml: 'pdf',
};

const name = (file) => file?.name.split('.')[0];

const hasFile = (filesPairs, file, filetype) => _.some(filesPairs, ({ [filetype]: refFile }) => name(refFile) === name(file));

const addIfNullAndHasPair = (filesPairs, file, filetype) => {
  const newFilesPairs = [...filesPairs];
  newFilesPairs.forEach(({ [filetype]: toChangeFile, [pairFiletype[filetype]]: refFile }, index) => {
    if (name(refFile) === name(file) && !toChangeFile) {
      newFilesPairs[index][filetype] = file;
    }
  });
  return newFilesPairs;
};

const addToFilesPairs = (filesPairs, file, filetype, refFiles) => {
  const newFilesPairs = [...filesPairs];
  const index = _.findIndex(refFiles, (ref) => name(file) === name(ref));
  newFilesPairs.push({
    [filetype]: file,
    [pairFiletype[filetype]]: index >= 0 ? refFiles[index] : null,
  });
  return newFilesPairs;
};

export const addFilesPairs = ({ filesPairs = [], xmls, pdfs }) => {
  let newFilesPairs = [...filesPairs];

  pdfs.forEach((pdf) => {
    newFilesPairs = addIfNullAndHasPair(newFilesPairs, pdf, 'pdf');
    if (hasFile(newFilesPairs, pdf, 'pdf')) return;
    newFilesPairs = addToFilesPairs(newFilesPairs, pdf, 'pdf', xmls);
  });

  xmls.forEach((xml) => {
    newFilesPairs = addIfNullAndHasPair(newFilesPairs, xml, 'xml');
    if (hasFile(newFilesPairs, xml, 'xml')) return;
    newFilesPairs = addToFilesPairs(newFilesPairs, xml, 'xml', pdfs);
  });

  // Drag nulls to end
  for (let i = 0; i < newFilesPairs.length - 1; i += 1) {
    for (let j = 0; j < newFilesPairs.length - 1; j += 1) {
      if (!newFilesPairs[j].pdf || !newFilesPairs[j].xml) {
        const t = newFilesPairs[j];
        newFilesPairs[j] = newFilesPairs[j + 1];
        newFilesPairs[j + 1] = t;
      }
    }
  }

  return newFilesPairs;
};

export const isValidPair = (pair) => pair?.pdf && pair?.xml;

export const removeSingleElements = (pairs) => pairs.filter(isValidPair);
