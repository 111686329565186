import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useSelectors from 'helpers/useSelectors';
import { useI18n } from 'services/i18n';
import { useModal } from 'services/modal';
import { actions, selectors } from 'store/auth';
import { isEmpty } from 'lodash';

import {
  DataBox, Button, FormInbox, FormInboxFieldTypes,
} from 'ux-thebackyard';

const ChangePassword = () => {
  const [state, setState] = useState({});
  const [error, setError] = useState(false);

  const t = useI18n();
  const dispatch = useDispatch();
  const { ModalFooter, onClose } = useModal();

  const [
    isBusy,
    isSuccess,
    isFailed,
  ] = useSelectors(
    selectors.changePassword.getIsBusy,
    selectors.changePassword.getIsSuccess,
    selectors.changePassword.getIsFailed,
  );

  const { oldPassword, newPassword, confirmNewPassword } = state;

  useEffect(() => () => dispatch(actions.changePassword.NoStarted()), []);

  useEffect(() => {
    setError(newPassword && confirmNewPassword && (confirmNewPassword !== newPassword));
  }, [state]);

  useEffect(() => {
    if (isSuccess) onClose();
  }, [isSuccess]);

  const handleOnSubmit = () => {
    dispatch(actions.changePassword.Call({ oldPassword, newPassword }));
  };

  const itemsForm = [
    {
      label: t('old_password'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: 'oldPassword',
      type: 'password',
    },
    {
      label: t('new_password'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: 'newPassword',
      type: 'password',
    },
    {
      label: t('confirm_password'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: 'confirmNewPassword',
      type: 'password',
    },
  ];

  // TODO incluir validaciones para eliminar esto de aqui
  const disabledSubmit = error
  || isEmpty(state)
  || !oldPassword
  || !newPassword
  || !confirmNewPassword;

  const noMatchErrorMessge = {
    type: 'error',
    text: t('no_passwords_match'),
  };

  const failedErrorMessge = {
    type: 'error',
    text: t('failed_change_password'),
  };

  return (
    <>
      <DataBox label={t('change_password')} separator width="30rem">
        <FormInbox
          items={itemsForm}
          value={state}
          onChange={setState}
        />
      </DataBox>
      <ModalFooter
        message={(error && noMatchErrorMessge) || (isFailed && failedErrorMessge)}
        showSpinner={isBusy}
      >
        <Button
          design="primary"
          rounded
          m="10px"
          p="12px 30px"
          onClick={handleOnSubmit}
          disabled={disabledSubmit}
          label={t('save')}
        />
      </ModalFooter>
    </>
  );
};

export default ChangePassword;
