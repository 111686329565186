import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import useSelectors from 'helpers/useSelectors';

import { useHasRoles } from 'services/hasRoles';
import { useModal } from 'services/modal';
import { selectors as userSelectors, actions as userActions } from 'store/user';
import Confirm from 'scenes/modals/confirm';
import {
  TableActionsInbox,
  TableActionsInboxTypes,
  Box,
  Text,
} from 'ux-thebackyard';
import Translate from 'components/translate';

const RemoveProvidersConfirm = ({ provider }) => {
  const dispatch = useDispatch();
  const { onClose } = useModal();

  const [
    isBusy,
    isSuccess,
    isFailed,
  ] = useSelectors(
    userSelectors.removeProviders.getIsBusy,
    userSelectors.removeProviders.getIsSuccess,
    userSelectors.removeProviders.getIsFailed,
  );

  useEffect(() => () => dispatch(userActions.removeProviders.NoStarted()), []);

  useEffect(() => {
    if (isSuccess) {
      onClose();
      dispatch(userActions.fetchProviders.Call());
    }
  }, [isSuccess]);

  // eslint-disable-next-line no-underscore-dangle
  const handleOnConfirmRemove = () => dispatch(userActions.removeProviders.Call(provider._id));

  return (
    <Confirm
      title="remove_provider_confirm_title"
      message="remove_provider_confirm_message"
      errorMessage={isFailed && 'remove_provider_error_message'}
      isBusy={isBusy}
      onConfirm={handleOnConfirmRemove}
    />
  );
};

RemoveProvidersConfirm.propTypes = {
  provider: PropTypes.objectOf(PropTypes.any),
};

const Actions = ({ data = {} }) => {
  const { renderComponent } = useModal();

  const RemoveProviderConfirmWrapper = () => <RemoveProvidersConfirm provider={data} />;
  const handleShowConfirmRemoveProvider = () => renderComponent(RemoveProviderConfirmWrapper, { hideFooter: true });

  const { provider: { full, remove } } = useHasRoles();

  const actions = [
    (full || remove) && { type: TableActionsInboxTypes.DELETE, onClick: handleShowConfirmRemoveProvider },
  ];

  return (
    <TableActionsInbox actions={actions} />
  );
};

const heads = [
  'actions',
  'entity_name',
  'rfcs',
  'social_reason',
];

const List = ({ items }) => (
  <Box display="flex" flexDirection="column">
    {items?.map((item, index) => (<Text key={index} mt="5px">{item}</Text>))}
  </Box>
);
List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
};

const transmuter = (data) => {
  const {
    entityName, rfcsList,
  } = data;

  return [
    <Actions data={data} />,
    entityName,
    <List items={rfcsList?.map(({ code }) => code)} />,
    <List items={rfcsList?.map(({ company }) => company)} />,
  ];
};

export default {
  heads: heads?.map((head) => <Translate label={head} />),
  transmuter,
};
