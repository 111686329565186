import { css } from 'styled-components';
import {
  flexbox,
  background,
  border,
  color,
  layout,
  position,
  shadow,
  space,
  typography,
  compose,
} from 'styled-system';
import styledSystemPropTypesBase from '@styled-system/prop-types';

export const styledSystem = css`
${compose(
    flexbox,
    background,
    border,
    color,
    layout,
    position,
    shadow,
    space,
    typography,
  )}
`;

export const styledSystemPropTypes = {
  ...styledSystemPropTypesBase.flexbox,
  ...styledSystemPropTypesBase.space,
  ...styledSystemPropTypesBase.color,
  ...styledSystemPropTypesBase.background,
  ...styledSystemPropTypesBase.border,
  ...styledSystemPropTypesBase.layout,
  ...styledSystemPropTypesBase.position,
  ...styledSystemPropTypesBase.shadow,
  ...styledSystemPropTypesBase.typography,
};
