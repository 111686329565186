import styled from 'styled-components';
import { styledSystem } from 'services/theme/helpers';

import { Input } from 'ux-thebackyard';

const FormControl = styled(Input)`
    font-size: 1rem;
    line-height: 1.25;
    outline: none;
    color: ${({ theme }) => theme.colors.primaryText};
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 0.5rem;
    width: 100%;
    font-weight: 400;
    background-color: #fff;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:disabled, &[readonly] {
        background-color: #e9ecef;
        opacity: 1;
        border:0;
    }

    &:focus{
        border-color: ${({ theme }) => theme.colors.inputBorderFocus};
    }
    ${styledSystem};
`;

export default FormControl;
