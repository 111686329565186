import { combineReducers } from 'redux';
import { call } from 'redux-saga/effects';
import RS from 'helpers/RS';

const NAME = 'system';

function* fetchDocument(api, { payload: { doc, fileType = 'zip' } }) {
  const { addressPdf, addressXml } = doc;
  let address = null;

  if (fileType === 'pdf') address = addressPdf;
  if (fileType === 'xml') address = addressXml;
  if (fileType === 'zip') address = addressPdf || addressXml;

  if (!address) throw new Error('Este documento no existe');

  const endpoint = fileType === 'zip' ? 'userFetchSystemZip' : 'userFetchSystemDocument';

  const { value } = yield call(api[endpoint], { params: { address } });
  return value;
}

const fetchDocumentRS = RS({
  nestedPath: [NAME],
  storeName: 'fetchDocument',
  tryGenerator: fetchDocument,
});

export const sagas = [
  fetchDocumentRS.sagas,
];

export const reducer = {
  [NAME]: combineReducers({
    ...fetchDocumentRS.reducer,
  }),
};

export const actions = {
  fetchDocument: fetchDocumentRS.actions,
};

export const selectors = {
  fetchDocument: fetchDocumentRS.selectors,
};
