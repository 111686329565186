/* eslint-disable react-hooks/rules-of-hooks */

import { useSelector } from 'react-redux';

const useSelectorsObject = (shape) => Object.entries(shape).reduce(
  (acc, [key, selector]) => ({
    ...acc,
    [key]: useSelector(selector),
  }),
  {},
);

const useSelectorsArray = (shape) => shape?.map(useSelector);

export default (...shape) => {
  if (shape.length > 0) {
    return useSelectorsArray(shape);
  }
  if (Array.isArray(shape)) {
    return useSelectorsArray(shape);
  }
  return useSelectorsObject(shape);
};
