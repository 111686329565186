import React, { useEffect, useState } from 'react';
import { useI18n } from 'services/i18n';

import { useModal } from 'services/modal';
import { useHasRoles } from 'services/hasRoles';
import filter, { ExceptionNumberToString } from 'helpers/filter';
import useSearch from 'services/search/useSearch';
import { useDispatch } from 'react-redux';
import useSelectors from 'helpers/useSelectors';
import { ableAreaFields, ableRfcsFields } from 'own/contants';
import { selectors as entitySelectors, actions as entityActions } from 'store/entity';
import { actions as areasActions, selectors as areasSelectors } from 'store/areas';
import { some, isEmpty } from 'lodash';

import {
  SvgPlusSolid,
  SvgSyncSolid,
} from 'react-line-awesome-svg';
import NewUpdateArea from 'scenes/modals/new-update-area';

import {
  Accordion,
  DataBox,
  TableBox,
  FormInbox,
  FormInboxFieldTypes,
} from 'ux-thebackyard';
import ManagementArea from 'own/table-transmuters/management-area';

import { roles } from '../constants';

const role = roles[0];
const valuesAbleAreaFields = Object.values(ableAreaFields);

const activeItems = [
  { label: 'Activo', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

const exceptionsToFilter = {
  [ableAreaFields.AREA_RFCS]: (value, matcher) => some(value, (val) => matcher.includes(val[ableRfcsFields.CODE])),
  [ableAreaFields.AREA_NAME]: ExceptionNumberToString,
  [ableAreaFields.ACTIVE]: (value, matcher) => (
    (value && matcher?.includes('active'))
  || (!value && matcher?.includes('inactive'))
  || !matcher
  || isEmpty(matcher)
  ),
};

const AreaManagement = () => {
  const [filteredAreas, setFilteredAreas] = useState([]);
  const t = useI18n();
  const dispatch = useDispatch();
  const { params: { [role]: params = {} }, updateParams: updateParamsT } = useSearch();
  const updateParams = (p) => updateParamsT(p, role);

  const { renderComponent } = useModal();
  const { area: { write, full } } = useHasRoles();

  const [
    areas,
    areaIsBusy,
    entity,
  ] = useSelectors(
    areasSelectors.fetch.getData,
    areasSelectors.fetch.getIsBusy,
    entitySelectors.fetch.getData,
  );

  const { rfcsList } = entity || {};

  const handleShowModalNewArea = () => renderComponent(NewUpdateArea, { hideFooter: true });
  const handleFetchAreas = () => dispatch(areasActions.fetch.Call());

  const filterArea = () => (
    setFilteredAreas(filter({
      items: areas,
      itemKeys: valuesAbleAreaFields,
      matcherItem: params,
      exceptions: exceptionsToFilter,
    }))
  );

  useEffect(() => {
    if (!areas?.length) handleFetchAreas();
  }, [areas]);

  useEffect(() => {
    if (!entity) dispatch(entityActions.fetch.Call());
  }, [entity]);

  useEffect(() => {
    filterArea();
  }, [areas, params]);

  const headBtnsProps = [
    (write || full) && {
      label: t('new'), design: 'primary', svg: SvgPlusSolid, onClick: handleShowModalNewArea,
    },
    {
      label: t('update'), design: 'shadow', svg: SvgSyncSolid, rounded: true, onClick: handleFetchAreas,
    },
  ];

  const formConfig = [
    {
      label: t('area'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableAreaFields.AREA_NAME,
    },
    {
      label: t('rfcs'),
      enableSearch: true,
      items: rfcsList,
      optionTextField: 'code',
      optionValueField: 'code',
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableAreaFields.AREA_RFCS,
    },
    {
      label: t('state'),
      items: activeItems,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      optionTextField: 'label',
      optionValueField: 'value',
      fieldName: 'active',
    },
  ];

  return (
    <DataBox label={t('area_management')} separator headBtnsProps={headBtnsProps}>

      <Accordion label={t('filter_search')}>
        <FormInbox
          items={formConfig}
          value={params}
          onChange={updateParams}
          submitBtnProps={{ display: 'none' }}
        />
      </Accordion>

      <TableBox
        transmuter={ManagementArea.transmuter}
        heads={ManagementArea.heads}
        data={filteredAreas}
        isBusy={areaIsBusy}
        noFoundMessage={t('no_areas_found')}
        stickyHeaders
      />

    </DataBox>
  );
};

export default AreaManagement;
