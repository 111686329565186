import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useI18n } from 'services/i18n';
import useSelectors from 'helpers/useSelectors';
import { useModal } from 'services/modal';
import { ableAreaFields } from 'own/contants';
import { selectors as entitySelectors, actions as entityActions } from 'store/entity';
import { actions as areasActions, selectors as areasSelectors } from 'store/areas';

import {
  DataBox,
  Button,
  FormInbox,
  FormInboxFieldTypes,
  FormInboxValidationType,
} from 'ux-thebackyard';

const defaultArea = {
  [ableAreaFields.AREA_NAME]: '',
  [ableAreaFields.AREA_RFCS]: [],
};

const NewUpdateArea = ({ area }) => {
  const [currentArea, setCurrentArea] = useState(defaultArea);
  const [submitBtnProps, setSubmitBtnProps] = useState({});

  const dispatch = useDispatch();
  const t = useI18n();

  const { onClose: onModalClose, ModalFooter } = useModal();

  const [
    entity,

    isBusyAddArea,
    isSuccessAddArea,

    isBusyUpdateArea,
    isSuccessUpdateArea,
  ] = useSelectors(
    entitySelectors.fetch.getData,

    areasSelectors.add.getIsBusy,
    areasSelectors.add.getIsSuccess,

    areasSelectors.update.getIsBusy,
    areasSelectors.update.getIsSuccess,
  );
  const { rfcsList } = entity || {};

  const handleOnSubmit = (areaT) => dispatch(areasActions[area ? 'update' : 'add'].Call(areaT));

  useEffect(() => () => {
    dispatch(areasActions.add.NoStarted());
    dispatch(areasActions.update.NoStarted());
  }, []);

  useEffect(() => {
    if (!entity) dispatch(entityActions.fetch.Call());
  }, [entity]);

  useEffect(() => {
    if (isSuccessAddArea || isSuccessUpdateArea) {
      dispatch(areasActions.fetch.Call());
      onModalClose();
    }
  }, [isSuccessAddArea, isSuccessUpdateArea]);

  useEffect(() => {
    if (area) setCurrentArea(area);
  }, [area]);

  const itemsForm = [
    {
      label: t('area'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableAreaFields.AREA_NAME,
      required: true,
      validations: [
        { type: FormInboxValidationType.REQUIRED },
      ],
    },
    {
      label: t('rfcs'),
      items: rfcsList,
      enableSearch: true,
      optionTextField: 'code',
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableAreaFields.AREA_RFCS,
      required: true,
      validations: [
        { type: FormInboxValidationType.REQUIRED },
      ],
    },
    !!area && {
      label: t('state'),
      fieldType: FormInboxFieldTypes.TOGGLE_SWITCH,
      fieldName: ableAreaFields.ACTIVE,
    },
  ];

  return (
    <>
      <DataBox label={t(area ? 'update_area' : 'new_area')} separator width="35rem">
        <FormInbox
          items={itemsForm}
          value={currentArea}
          onChange={setCurrentArea}
          changeExternSubmitBtnProps={setSubmitBtnProps}
          onSubmit={handleOnSubmit}
        />
      </DataBox>
      <ModalFooter showSpinner={isBusyAddArea || isBusyUpdateArea}>
        <Button
          design="primary"
          rounded
          m="10px"
          p="12px 30px"
          label={t('upload')}
          {...submitBtnProps}
        />
      </ModalFooter>
    </>
  );
};

NewUpdateArea.propTypes = {
  area: PropTypes.objectOf(PropTypes.any),
};

export default NewUpdateArea;
