import qs from 'query-string';
import { some } from 'lodash';

export const DEFAULT_PAGINATION = { page: 1 };

export const getPropHasChanged = (props = {}, newProps = {}) => (params) => some(params, (param) => props[param] !== newProps[param]);

export const encodeSearch = (search) => encodeURIComponent(search);
export const decodeSearch = (search) => decodeURIComponent(search);

export const paramsToSearch = (params) => qs.stringify(params, {
  arrayFormat: 'bracket',
  skipEmptyString: true,
});

export const searchToParams = (search) => qs.parse(search, {
  parseNumbers: true,
  arrayFormat: 'bracket',
  parseBooleans: true,
});

export const transmuteSearchCheckingRole = (lineSearch) => {
  const params = searchToParams(lineSearch);
  if (params.role) {
    const search = {};
    Object.keys(params).forEach((key) => {
      if (key === 'role') return;
      const roleParams = {
        ...DEFAULT_PAGINATION,
        ...searchToParams(decodeSearch(params[key])),
      };

      params[key] = roleParams;
      search[key] = encodeSearch(paramsToSearch(roleParams));
    });
    return { params, search };
  }

  const newParams = { ...DEFAULT_PAGINATION, ...params };
  const newSearch = paramsToSearch(newParams);
  return { params: newParams, search: newSearch };
};

export const paramsToSearchCheckingRole = (params) => {
  const paramsT = { ...params };
  if (params.role) {
    Object.keys(params).forEach((key) => {
      if (key === 'role') return;
      paramsT[key] = encodeSearch(paramsToSearch(params[key]));
    });
  }
  return paramsToSearch(paramsT);
};

export const searchFromRoles = (roles) => { // roles's type is [<string>]
  if (!roles) return null;

  let params = { role: true };
  roles.forEach((role) => {
    params = { ...params, [role]: DEFAULT_PAGINATION };
  });

  return paramsToSearchCheckingRole(params);
};
