import React, { useEffect } from 'react';
import {
  Route, Redirect, Switch,
} from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PrivateRoute from 'components/privateRoute';
import { userTypes } from 'own/contants';
import Login, { PATH as pathLogin } from 'scenes/login';
import Providers, { PATH as pathProviders } from 'scenes/providers';
import User, { PATH as pathUser } from 'scenes/user';
import StartUp, { PATH as pathStartUp } from 'scenes/start-up';

import { actions as startUpActions, selectors as startUpSelectors } from 'store/start-up';

import { Box } from 'ux-thebackyard';

const App = () => {
  const dispatch = useDispatch();
  const hasData = useSelector(startUpSelectors.getHasData);

  useEffect(() => {
    if (hasData) dispatch(startUpActions.Call());
  }, []);

  return (
    <Box width="100vw">
      <Switch>
        <Route path={pathLogin} component={Login} />
        <Route path={pathStartUp} component={StartUp} />
        <PrivateRoute userType={userTypes.PROVIDER} path={pathProviders} component={Providers} />
        <PrivateRoute userType={userTypes.CLIENT} path={pathUser} component={User} />
        <Route path="*" render={() => <Redirect to={pathLogin} />} />
      </Switch>
    </Box>
  );
};

export default App;
