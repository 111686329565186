import { PATH as pathProviders } from 'scenes/providers';
import { PATH as pathUser } from 'scenes/user';
import { userTypes } from 'own/contants';

const redirects = {
  [userTypes.PROVIDER]: pathProviders,
  [userTypes.CLIENT]: pathUser,
};

export const userTypeToRoute = (userType) => redirects[userType];
