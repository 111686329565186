import { createSelector } from 'reselect';
import { } from 'redux';
import {
  takeEvery, call, put,
} from 'redux-saga/effects';
import { save as saveLS, load as loadLS, remove as removeLS } from 'helpers/localStorage';

const NAME_LS_SAVE = 'EDX_SAVE';
// const COMPLETED_DELAY = 500;

const rsStates = {
  NOT_STARTED: 'NOT_STARTED',
  BUSY: 'BUSY',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

const pathReducer = (obj, key) => ((obj && obj[key] !== 'undefined') ? obj[key] : undefined);
const getNestedObject = (nestedObj, pathArr) => pathArr.reduce(pathReducer, nestedObj);

const RESET_RS = 'RESET';
export const Reset = () => ({ type: RESET_RS });

const RESTORE_RS = 'RESTORE';
export const Restore = () => ({ type: RESTORE_RS });

const RS = ({
  nestedPath = [],
  storeName,
  tryGenerator,
  catchGenerator,
  saveToLS,
}) => {
  // Actions
  const prefix = [...nestedPath, storeName].join('/');

  const BUSY = `${prefix}.BUSY`;
  const Busy = () => ({ type: BUSY, payload: null });

  const FAILED = `${prefix}.FAILED`;
  const Failed = (data) => ({ type: FAILED, payload: data });

  const NO_STARTED = `${prefix}.NO_STARTED`;
  const NoStarted = () => ({ type: NO_STARTED, payload: null });

  const SUCCESS = `${prefix}.SUCCESS`;
  const Success = () => ({ type: SUCCESS });

  const COMPLETED = `${prefix}.COMPLETED`;
  const Completed = (payload) => ({ type: COMPLETED, payload });

  const CALL = `${prefix}.CALL`;
  const Call = (payload) => ({ type: CALL, payload });

  const SET_DATA = `${prefix}.SET_DATA`;
  const SetData = (payload) => ({ type: SET_DATA, payload });

  const actions = {
    SetData,
    Call,
    NoStarted,
  };

  const UpdateLS = (data) => {
    const lsData = loadLS(NAME_LS_SAVE);
    const newData = { ...(lsData || {}), [storeName]: data };
    saveLS(NAME_LS_SAVE, newData);
  };

  function* SagaSetData({ payload: data }) {
    yield put(Completed(data));
    if (saveToLS) UpdateLS(data);
  }

  // Sagas
  function* SagaWrapper(api, action) {
    try {
      yield put(Busy());
      const response = yield call(tryGenerator, api, action);

      yield put(Success());
      // yield delay(COMPLETED_DELAY);

      yield put(SetData(response));
    } catch (e) {
      console.warn(`An error occurred calling the RS saga in "${prefix}": ${e}`);
      yield put(Failed(e));
      if (catchGenerator) yield call(catchGenerator);
    }
  }

  function* restoreRS() {
    const lsData = loadLS(NAME_LS_SAVE);
    if (lsData) {
      const { [storeName]: restoredData } = lsData;
      yield put(Completed(restoredData));
    }
  }

  function* resetRS() {
    yield put(Completed(null));
    removeLS(NAME_LS_SAVE);
  }

  function* sagas({ api }) {
    yield takeEvery(CALL, SagaWrapper, api);
    yield takeEvery(RESET_RS, resetRS);
    yield takeEvery(SET_DATA, SagaSetData);
    if (saveToLS) yield takeEvery(RESTORE_RS, restoreRS);
  }

  // Reducer
  const defaultState = {
    rs: rsStates.NOT_STARTED,
    data: null,
    errorMessage: null,
  };

  const reducer = (state = defaultState, action) => {
    switch (action.type) {
      case BUSY:
        return {
          ...state,
          rs: rsStates.BUSY,
          errorMessage: null,
        };
      case FAILED:
        return {
          ...state,
          rs: rsStates.FAILED,
          errorMessage: action.payload,
          data: null,
        };
      case SUCCESS:
        return {
          ...state,
          rs: rsStates.SUCCESS,
        };
      case COMPLETED:
        return {
          ...state,
          rs: rsStates.NOT_STARTED,
          data: action.payload,
          errorMessage: null,
        };
      case NO_STARTED:
        return {
          ...state,
          rs: rsStates.NOT_STARTED,
        };
      default:
        return state;
    }
  };

  // Selectors
  const getStore = (state) => getNestedObject(state, [...nestedPath, storeName]);

  const getIsNotStarted = createSelector(getStore, ({ rs }) => rs === rsStates.NOT_STARTED);
  const getIsBusy = createSelector(getStore, ({ rs }) => rs === rsStates.BUSY);
  const getIsSuccess = createSelector(getStore, ({ rs }) => rs === rsStates.SUCCESS);
  const getIsFailed = createSelector(getStore, ({ rs }) => rs === rsStates.FAILED);
  const getData = createSelector(getStore, ({ data }) => data);
  const getErrorMessage = createSelector(getStore, ({ errorMessage }) => errorMessage);

  const selectors = {
    getIsNotStarted,
    getIsBusy,
    getIsSuccess,
    getIsFailed,
    getData,
    getErrorMessage,
  };

  return {
    reducer: { [storeName]: reducer },
    selectors,
    actions,
    sagas,
  };
};

export default RS;
