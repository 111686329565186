import React from 'react';
import PropTypes from 'prop-types';
import { useI18n } from 'services/i18n';
import styled from 'styled-components';

import { ChartJs, Box, DataBox } from 'ux-thebackyard';

const ChartContainer = styled(Box)`
  height: 100%;
  position: relative;
  max-width: 90%;
  margin: 0 auto;
`;

const DocumentsByStatus = ({ statusReport }) => {
  const t = useI18n();
  const getPieChartData = () => ({
    type: 'pie',
    data: {
      labels: statusReport?.map(({ estado }) => estado),
      datasets: [
        {
          data: statusReport?.map(({ total }) => total),
          backgroundColor: [
            '#88ab3f',
            '#e6b52e',
            '#cc4927',
            '#747a83',
            '#4064aa',
            '#7685be',
            '#483e77',
            '#736893',
            '#a49ab8',
          ],
        },
      ],
    },
    options: {
      responsive: true,
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          boxWidth: 10,
          padding: 10,
          fontFamily: "'Montserrat', 'sans-serif'",
        },
      },
      layout: {
        padding: {
          left: 50,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      scales: {
        xAxes: [{
          display: false,
          gridLines: {
            drawOnChartArea: false,
          },
        }],
        yAxes: [{
          display: false,
          gridLines: {
            drawOnChartArea: false,
          },
        }],
      },
    },
  });

  return (
    <DataBox label={t('documents_by_status')}>
      <ChartContainer centeredContent>
        {statusReport && <ChartJs {...getPieChartData()} />}
      </ChartContainer>
    </DataBox>
  );
};

DocumentsByStatus.propTypes = {
  statusReport: PropTypes.arrayOf(
    PropTypes.shape({
      estado: PropTypes.string,
      total: PropTypes.number,
    }),
  ),
};
export default DocumentsByStatus;
