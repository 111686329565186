import React from 'react';
import { useDispatch } from 'react-redux';
import useSelectors from 'helpers/useSelectors';
import { useI18n } from 'services/i18n';
import { actions as authActions } from 'store/auth';
import { selectors as userSelectors } from 'store/user';

import { push, getLocation } from 'connected-react-router';
import { PATH as pathProfileBase } from 'pages/profile';

import {
  ProfileInbox,
} from 'ux-thebackyard';

export const Profile = (props) => {
  const dispatch = useDispatch();
  const t = useI18n();

  const [
    { pathname },
    userName,
  ] = useSelectors(
    getLocation,
    userSelectors.fetchUser.getUserName,
  );

  const baseRoute = `/${pathname.split('/')[1]}`;
  const routeToProfile = `${baseRoute}${pathProfileBase}`;

  const items = [
    { label: t('my_account'), onClick: () => dispatch(push(routeToProfile)) },
    { label: t('my_subscription'), onClick: () => {} },
    { label: t('logout'), separator: true, onClick: () => dispatch(authActions.signOut.Call()) },
  ];

  return (
    <ProfileInbox items={items} userName={userName} {...props} />
  );
};
