import { call } from 'redux-saga/effects';
import RS from 'helpers/RS';

function* shareReport(api, { payload: data }) {
  const response = yield call(api.sendReportToEmail, { data });
  return response;
}

const shareRS = RS({
  nestedPath: [],
  storeName: 'share',
  tryGenerator: shareReport,
});

export const sagas = [
  shareRS.sagas,
];

export const { actions, selectors, reducer } = shareRS;
