import React from 'react';
import PropTypes from 'prop-types';
import { docStatePropType } from 'helpers/propTypes';
import styled, { useTheme } from 'styled-components';

import { SvgCalendar } from 'react-line-awesome-svg';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { cfdiStates } from 'own/contants';

// TODO usar iconos de react-line-awesome-svg en vez de material

import {
  Text,
  Box,
  Icon,
} from 'ux-thebackyard';

const Row = styled(Box)`
    display: block;
    margin: 0;
    padding: 1.8rem 1.07rem;
    border: 0 none;    
    background: transparent;
    &:not(:first-child){
        border-bottom: 0.07rem solid #eee !important;
    }
`;

const MediaBody = styled(Box)`
    padding: 0 0.8rem;
`;

/**
 * TODO esto es innecesario, no usar de ser posible custom clases
 * aplicar los estilos directamente sobre el elemento Box, Text
 */
const Title = styled(Box)`

    h4 {
        font-size: 1.2rem;
        color: ${({ theme }) => theme.colors.primaryText};
        margin-bottom: 0.3rem;
        margin-top:0;
        font-weight: 500;
    }
    
`;

const TextWrapper = styled(Text)`
 margin-left: 5px;
`;

const ErrorMessage = styled.p`
  font-size: 0.95rem;
  color: ${({ theme }) => theme.colors.errorTextMessage};
  margin: 0;
`;

const Meta = styled(Box)`
    font-size: 0.85rem;
    padding-top: 0.8rem;
    font-weight: 600;
    color:${({ theme }) => theme.colors.placeholderText};
`;

const StateRow = ({ state, isLast, validations }) => {
  const theme = useTheme();
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
  };
  const { estado, date } = state;
  const isError = cfdiStates.ERROR === estado;

  const valuesValidations = validations && Object.keys(validations)?.map((key) => validations[key]);

  return (
    <Row>
      <Box display="flex" alignItems="center">
        <Box>
          {isError ? (
            <HighlightOffIcon
              fontSize="large"
              htmlColor={isLast ? theme.colors.errorIconColor : theme.colors.disabledIconColor}
            />
          ) : (
            <CheckCircleOutlineIcon
              fontSize="large"
              htmlColor={isLast ? theme.colors.successIconColor : theme.colors.disabledIconColor}
            />
          )}
        </Box>
        <MediaBody>
          <Title>
            <h4>{estado}</h4>
          </Title>
          {isError && (
          <Box>
            {valuesValidations?.map((validation, index) => (!validation.valid && (
              <ErrorMessage key={index}>{validation.error_message}</ErrorMessage>
            )))}
          </Box>
          )}
          <Meta display="flex" alignItems="center">
            <Icon color={theme.colors.placeholderText} size="15px" svg={SvgCalendar} />
            <TextWrapper>
              {new Intl.DateTimeFormat('es-ES', options).format(new Date(date))}
            </TextWrapper>
          </Meta>
        </MediaBody>
      </Box>
    </Row>
  );
};

StateRow.propTypes = {
  state: docStatePropType,
  isLast: PropTypes.bool,
  validations: PropTypes.objectOf(PropTypes.any),
};

export default StateRow;
