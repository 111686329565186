import React from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';
import { Text } from 'ux-thebackyard';
import { typeDocToName } from 'own/contants';

const StyledField = styled(Text)`
    cursor: default;
`;

const TypeDocField = ({ type }) => (
  <StyledField title={typeDocToName[type]}>
    {type}
  </StyledField>
);

TypeDocField.propTypes = {
  type: PropType.string,
};

export default TypeDocField;
