import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useSelectors from 'helpers/useSelectors';
import { useI18n } from 'services/i18n';
import { ableParamsToFetch } from 'own/contants';
import { typeDocNameItemsDropdownSelect, cfdiStatesValues } from 'own/helpers';

import { actions as docsActions, selectors as docsSelectors } from 'store/documents';
import { actions as reportsActions, selectors as reportsSelectors } from 'store/reports';
import {
  SvgSyncSolid,
} from 'react-line-awesome-svg';
import { selectors as userSelectors, actions as userActions } from 'store/user';

import useSearch from 'services/search/useSearch';
import { paramsToSearch } from 'services/search/helpers';

import { CfdisPurchaseOrders } from 'components/reports';
import { hasSome, useHasRoles } from 'services/hasRoles';

import {
  TableBox,
  PageInbox,
  Accordion,
  DataBox,
  FormInbox,
  FormInboxFieldTypes,
} from 'ux-thebackyard';
import CFDIClientTrans from 'own/table-transmuters/cfdi-client';
import CFDIProviderTrans from 'own/table-transmuters/cfdi-provider';

const Home = () => {
  const dispatch = useDispatch();
  const { search, params, updateParams } = useSearch();
  const { page, ...remainderparams } = params;
  const { reports } = useHasRoles();
  const t = useI18n();
  const hasSomeReport = hasSome(reports);

  const [
    rfcsIssuer,
    rfcsReceiver,

    cfdis,
    isBusyDocs,
    pagination,

    isBusyReports,
    cfdiReport,
    purchaseReport,

    isClient,
  ] = useSelectors(
    userSelectors.fetchRFCs.getRFCsIssuer,
    userSelectors.fetchRFCs.getRFCsReceiver,

    docsSelectors.getDocs1,
    docsSelectors.getDocs1IsBusy,
    docsSelectors.getDocs1Pagination,

    reportsSelectors.getIsBusy,
    reportsSelectors.getCFDIReport,
    reportsSelectors.getPurchaseReport,

    userSelectors.fetchUser.getIsClient,
  );
  const tableTrans = isClient ? CFDIClientTrans : CFDIProviderTrans;

  const FetchDocs = () => dispatch(docsActions.FetchDocs1(params));
  const FetchReports = () => dispatch(reportsActions.FetchReports(params));
  const handleChangePage = (pageT) => updateParams({ page: pageT });

  useEffect(() => {
    if (!(rfcsReceiver || rfcsIssuer)) dispatch(userActions.fetchRFCs.Call());
  }, [rfcsIssuer, rfcsReceiver]);

  useEffect(() => {
    FetchDocs();
  }, [search]);

  const remainderSearch = paramsToSearch(remainderparams);
  useEffect(() => {
    FetchReports();
  }, [remainderSearch]);

  const formConfig = [
    {
      label: t('issuing_rfc'),
      enableSearch: true,
      items: rfcsIssuer,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableParamsToFetch.RFC_ISSUER,
    },
    {
      label: t('receiver_rfc'),
      enableSearch: true,
      items: rfcsReceiver,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableParamsToFetch.RFC_RECEIVER,
    },
    {
      label: t('state'),
      enableSearch: true,
      items: cfdiStatesValues,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableParamsToFetch.STATE,
    },
    {
      label: t('date'),
      fieldType: FormInboxFieldTypes.DATE_RANGE,
      fieldNameStartDate: ableParamsToFetch.DATE_IN,
      fieldNameEndDate: ableParamsToFetch.DATE_END,
    },
    {
      label: t('uuid_fiscal_folio'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableParamsToFetch.UUID,
    },
    {
      label: t('total'),
      fieldType: FormInboxFieldTypes.NUMERIC_RANGE,
      fieldNameStartValue: ableParamsToFetch.TOTAL_IN,
      fieldNameEndValue: ableParamsToFetch.TOTAL_END,
    },
    {
      label: t('serie'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableParamsToFetch.SERIE,
    },
    {
      label: t('folio'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableParamsToFetch.FOLIO,
      type: 'number',
    },
    {
      label: t('type'),
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      items: typeDocNameItemsDropdownSelect,
      optionTextField: 'name',
      optionValueField: 'type',
      fieldName: ableParamsToFetch.TYPE,
    },
  ];
  const headBtnsProps = [
    {
      label: t('update'), design: 'shadow', svg: SvgSyncSolid, rounded: true, onClick: FetchDocs,
    },
  ];

  return (
    <PageInbox label={t('welcome_to_edx_inbox')}>

      {hasSomeReport && <CfdisPurchaseOrders cfdiReport={cfdiReport} purchaseReport={purchaseReport} isBusy={isBusyReports} />}

      <DataBox
        label={t('cfdis')}
        headBtnsProps={headBtnsProps}
        separator
      >
        <Accordion label={t('filter_search')}>
          <FormInbox
            items={formConfig}
            value={params}
            onSubmit={updateParams}
            submitBtnProps={{ label: t('apply') }}
          />
        </Accordion>

        <TableBox
          transmuter={tableTrans.transmuter}
          heads={tableTrans.heads}
          data={cfdis}
          isBusy={isBusyDocs}
          noFoundMessage={t('no_bills_found')}
          pagination={{
            page: pagination?.page,
            totalPages: pagination?.totalPages,
            totalItems: pagination?.totalDocs,
            pageSize: 50, // TODO
          }}
          onChangePage={handleChangePage}
          stickyHeaders
        />
      </DataBox>
    </PageInbox>

  );
};

export default Home;
