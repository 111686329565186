import React from 'react';
import { useModal } from 'services/modal';
import { ToShortDate } from 'helpers/date';
import { formatNumberToCurrency } from 'helpers/general';
import RelatedDocs from 'scenes/modals/related-docs';
import DownloadFile from 'scenes/modals/download-file';
import TypeDocField from 'components/type-doc-field';

import {
  TableActionsInbox,
  TableActionsInboxTypes,
} from 'ux-thebackyard';
import StateButton from 'components/state-button';
import Translate from 'components/translate';

const Actions = ({ data = {} }) => {
  const { renderComponent } = useModal();
  const handleOpenRelatedDocs = () => renderComponent(() => <RelatedDocs doc={data} />);
  const handleDownload = (fileType, title) => renderComponent(() => <DownloadFile fileType={fileType} title={title} data={data} />);

  const actions = [
    { type: TableActionsInboxTypes.RELATED_DOCS, onClick: handleOpenRelatedDocs },
    { type: TableActionsInboxTypes.DOWNLOAD_PDF, onClick: () => handleDownload('pdf', 'download_pdf') },
    { type: TableActionsInboxTypes.DOWNLOAD_XML, onClick: () => handleDownload('xml', 'download_xml') },
    { type: TableActionsInboxTypes.DOWNLOAD_ZIP, onClick: () => handleDownload('zip', 'download_zip') },
  ];

  return (
    <TableActionsInbox actions={actions} />
  );
};

const heads = [
  'actions',
  'type',
  'serie',
  'folio',
  'date',
  'issuing_rfc',
  'receiver_rfc',
  'state',
  'total',
  'uuid_fiscal_folio',
];

const transmuter = (data) => {
  const {
    uuid, serie, folio, date, rfcIssuer, rfcReceiver, total, type,
  } = data;

  return [
    <Actions data={data} />,
    <TypeDocField type={type} />,
    serie,
    folio,
    ToShortDate(date, 'string'),
    rfcIssuer,
    rfcReceiver,
    <StateButton data={data} />,
    formatNumberToCurrency(total),
    uuid,
  ];
};

export default {
  heads: heads?.map((head) => <Translate label={head} />),
  transmuter,
};
