import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, actions } from 'store/modal';

import {
  ModalFooter as ModalFooterUX,
  Modal,
} from 'ux-thebackyard';

const ModalContext = createContext(null);

export const ModalProvider = ({ children }) => {
  const dispatch = useDispatch();
  const components = useSelector(selectors.getComponents);
  const hasComponents = !!components.length;

  const onClose = () => dispatch(actions.PopComponentToRender());
  const renderComponent = (component, options) => (
    dispatch(actions.PushComponentToRender({ component, options }))
  );

  const ModalFooter = (props) => <ModalFooterUX onClose={onClose} {...props} />;

  const value = {
    renderComponent, ModalFooter, onClose,
  };

  return (
    <ModalContext.Provider value={value}>
      {hasComponents && (
        components?.map(({ component: Component, options }, index) => {
          const { hideFooter, blockOnClose } = options || {};
          return (
            <Modal
              key={index}
              onClose={blockOnClose ? undefined : onClose}
            >
              <Component />
              {!hideFooter && <ModalFooter />}
            </Modal>
          );
        })
      )}
      {children}
    </ModalContext.Provider>
  );
};

//
ModalProvider.propTypes = {
  children: PropTypes.node,
};

export const useModal = () => useContext(ModalContext);
