export default {
  colors: {
    simpleText: '#000000',

    primaryText: '#2c304d',

    placeholderText: '#98a8b4',

    errorTextMessage: '#94a4b0',

    sideBarIconActive: '#97bf3d',
    sideBarIconInactive: '#98a8b4',
    sideBarText: '#aea9c3',

    billsActionIcon: '#98a8b4',
    billsActionIconHover: '#FFFFFF',
    billsActionIconBackgroundHover: '#97bf3d',

    dataCardDefaultTitleColor: '#00000080',
    dataCardPrimaryTitleColor: '#97bf3d',

    styledForm: '#342868',

    profileItemText: '#5d5386',

    sidebarBackground: '#2c304d',

    tableTextColor: '#5d5386',
    tableHead: '#342868',
    tableTh: '#e0dfe9',

    dataBoxText: '#2c304d',

    inputText: '#2c304d',
    inputBorder: '#eee',
    inputBorderFocus: '#5d5386',

    // Buttons
    shadowButtonText: '#5d5386',

    primaryButtonBackground: '#97bf3d',
    primaryButtonHover: '#88ab3f',
    primaryButtonText: '#FFFFFF',

    successButtonBackground: '#60c400',
    successButtonText: '#FFFFFF',
    successButtonHover: '#00662B',

    errorButtonBackground: '#fe195e',
    errorButtonText: '#FFFFFF',
    errorButtonHover: '#c9302c',

    infoButtonBackground: '#5bc0de',
    infoButtonText: '#FFFFFF',
    infoButtonHover: '#31b0d5',

    defaultButtonBackground: '#e4e8f0 ',
    defaultButtonText: '#2c304d',
    defaultButtonHover: '#2C304d',
    defaultButtonTextHover: '#FFFFFF',

    //
    inboxSpinner: '#2c304d',

    // PAGINATION
    pagination: '#aea9c3',
    paginationActive: '#5d5386',

    dropdownSelectBackground: '#f8f9fa',
    dropdownSelectText: '#999',
    dropdownSelectTextSelected: '#2c304d',

    accordionText: '#5d5386',
    accordionTextHover: '#97bf3d',

    //
    chartSuccess: 'green',
    chartError: 'red',
    chartInfo1: 'gray',

    errorIconColor: '#ce4926',
    successIconColor: '#8aab3c',
    disabledIconColor: '#e1e2e4',

    toggleSwitchTrueBackground: '#8aab3c',
    toggleSwitchDisabledBackground: '#e1e2e4',
    toggleSwitchFalseBackground: '#ce4926',

    toggleSwitchTrueDisabledBackground: '#9aac70',
    toggleSwitchFalseDisabledBackground: '#ce7f6c',
  },

  shadows: {
    xs: '0px 0px 1px 1px rgba(52, 40, 104, 0.08)',
    sm: '0px 0px 2px 1px rgba(52, 40, 104, 0.08)',
    md: '0px 0px 4px 1px rgba(52, 40, 104, 0.08)',
    lg: '0px 0px 15px 1px rgba(52, 40, 104, 0.08)',
    xl: '0px 0px 15px 3px rgba(52, 40, 104, 0.08)',
    xxl: '0px 0px 15px 5px rgba(52, 40, 104, 0.08)',
  },

  fontSizes: {
    xs: '0.7rem',
    sm: '0.9rem',
    md: '1rem',
    lg: '1.3rem',
    xl: '2rem',
    xxl: '2.5rem',
    xxxl: '3.2rem',
  },

  fontWeights: {
    reg: '300',
    med: '500',
    sem: '600',
    bld: '700',
  },

  default: {
    fontFamily: "'Montserrat', sans-serif",
    backgroundColor: '#f2f3f8',
  },

  // breakpoints: ['300px', '600px', '900px'],
};
