import React, { useEffect, useState } from 'react';
import { useI18n } from 'services/i18n';

import useSearch from 'services/search/useSearch';
import { useDispatch } from 'react-redux';
import filter from 'helpers/filter';

import { useHasRoles } from 'services/hasRoles';

import { actions as usersActions, selectors as usersSelectors } from 'store/users';
import { actions as profilesActions, selectors as profilesSelectors } from 'store/profiles';

import {
  SvgPlusSolid,
  SvgSyncSolid,
} from 'react-line-awesome-svg';
import useSelectors from 'helpers/useSelectors';
import { useModal } from 'services/modal';
import { ableUserFields, ableProfileFields } from 'own/contants';
import NewUpdateUser from 'scenes/modals/new-update-user';

import {
  Accordion,
  DataBox,
  TableBox,
  FormInbox,
  FormInboxFieldTypes,
} from 'ux-thebackyard';
import ManagementUser from 'own/table-transmuters/management-user';

import { roles } from '../constants';

const role = roles[2];

const valuesAbleUserFields = Object.values(ableUserFields);

const exceptionsToFilter = {
  [ableUserFields.USER_PROFILE]: (value, matcher) => matcher.includes(value[ableProfileFields.PROFILE_NAME]),
};

const UserManagement = () => {
  const [filteredUsers, setFilteredUsers] = useState([]);
  const t = useI18n();

  const dispatch = useDispatch();
  const { renderComponent } = useModal();

  const { params: { [role]: params = {} }, updateParams: updateParamsT } = useSearch();
  const updateParams = (p) => updateParamsT(p, role);

  const [
    users,
    userIsBusy,
    profiles,
  ] = useSelectors(
    usersSelectors.fetch.getData,
    usersSelectors.fetch.getIsBusy,
    profilesSelectors.fetch.getData,
  );

  const handleFetchUsers = () => dispatch(usersActions.fetch.Call());
  const handleShowModalNewUser = () => renderComponent(NewUpdateUser, { hideFooter: true });

  const filterUsers = () => (
    setFilteredUsers(filter({
      items: users,
      itemKeys: valuesAbleUserFields,
      matcherItem: params,
      exceptions: exceptionsToFilter,
    }))
  );

  const { user: { full, write } } = useHasRoles();
  const headBtnsProps = [
    (write || full) && {
      label: t('new'), design: 'primary', svg: SvgPlusSolid, onClick: handleShowModalNewUser,
    },
    {
      label: t('update'), design: 'shadow', svg: SvgSyncSolid, rounded: true, onClick: handleFetchUsers,
    },
  ];

  const formConfig = [
    {
      label: t('name'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableUserFields.NAME,
    },
    {
      label: t('email'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableUserFields.USER_EMAIL,
    },
    {
      label: t('profile'),
      enableSearch: true,
      items: profiles,
      optionTextField: ableProfileFields.PROFILE_NAME,
      optionValueField: ableProfileFields.PROFILE_NAME,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableUserFields.USER_PROFILE,
    },
  ];

  useEffect(() => {
    if (!users) handleFetchUsers();
  }, [users]);

  useEffect(() => {
    if (!profiles) dispatch(profilesActions.fetch.Call());
  }, [profiles]);

  useEffect(() => {
    filterUsers();
  }, [users, params]);

  return (
    <DataBox label={t('user_management')} separator headBtnsProps={headBtnsProps}>
      <Accordion label={t('filter_search')}>
        <FormInbox
          items={formConfig}
          value={params}
          onChange={updateParams}
          submitBtnProps={{ display: 'none' }}
        />
      </Accordion>

      <TableBox
        transmuter={ManagementUser.transmuter}
        heads={ManagementUser.heads}
        data={filteredUsers}
        isBusy={userIsBusy}
        noFoundMessage={t('no_users_found')}
        stickyHeaders
      />
    </DataBox>
  );
};

export default UserManagement;
