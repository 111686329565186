import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useModal } from 'services/modal';
import { useHasRoles } from 'services/hasRoles';
import useSelectors from 'helpers/useSelectors';
import NewUpdateArea from 'scenes/modals/new-update-area';
import Confirm from 'scenes/modals/confirm';
import {
  TableActionsInbox,
  TableActionsInboxTypes,
  Box,
  Text,
} from 'ux-thebackyard';

import Translate from 'components/translate';

import {
  actions as areasActions,
  selectors as areasSelectors,
} from 'store/areas';

const RemoveAreaConfirm = ({ area }) => {
  const dispatch = useDispatch();
  const { onClose } = useModal();

  const [
    isBusyRemoveArea,
    isSuccessRemoveArea,
    isFailedRemoveArea,
  ] = useSelectors(
    areasSelectors.remove.getIsBusy,
    areasSelectors.remove.getIsSuccess,
    areasSelectors.remove.getIsFailed,
  );

  useEffect(() => () => dispatch(areasActions.remove.NoStarted()), []);

  useEffect(() => {
    if (isSuccessRemoveArea) {
      onClose();
      dispatch(areasActions.fetch.Call());
    }
  }, [isSuccessRemoveArea]);

  // eslint-disable-next-line no-underscore-dangle
  const handleOnConfirmRemoveArea = () => dispatch(areasActions.remove.Call(area._id));

  return (
    <Confirm
      title="remove_area_confirm_title"
      message="remove_area_confirm_message"
      errorMessage={isFailedRemoveArea && 'remove_area_error_message'}
      isBusy={isBusyRemoveArea}
      onConfirm={handleOnConfirmRemoveArea}
    />
  );
};

RemoveAreaConfirm.propTypes = {
  area: PropTypes.objectOf(PropTypes.any),
};

const Actions = ({ data = {} }) => {
  const { renderComponent } = useModal();

  const NewUpdateAreaWrapper = () => <NewUpdateArea area={data} />;
  const handleShowModalUpdateArea = () => renderComponent(NewUpdateAreaWrapper, { hideFooter: true });

  const RemoveAreaConfirmWrapper = () => <RemoveAreaConfirm area={data} />;
  const handleShowConfirmRemoveArea = () => renderComponent(RemoveAreaConfirmWrapper, { hideFooter: true });

  const { area: { full, remove, update } } = useHasRoles();
  const { mutable } = data;

  const actions = [
    (update || full) && mutable && { type: TableActionsInboxTypes.EDIT, onClick: handleShowModalUpdateArea },
    (remove || full) && mutable && { type: TableActionsInboxTypes.DELETE, onClick: handleShowConfirmRemoveArea },
  ];

  return (
    <TableActionsInbox actions={actions} />
  );
};

const heads = [
  'actions',
  'area',
  'rfcs',
  'company_name',
  'state',
];

const List = ({ data }) => (
  <Box display="flex" flexDirection="column">
    {data?.map((d, index) => (<Text key={index} mt="5px">{d}</Text>))}
  </Box>
);
List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
};

const transmuter = (data) => {
  const {
    areaName, active, areaRfcs,
  } = data;

  return [
    <Actions data={data} />,
    areaName,
    <List data={areaRfcs?.map(({ code }) => code)} />,
    <List data={areaRfcs?.map(({ company }) => company)} />,
    active ? 'Activa' : 'Inactiva',
  ];
};

export default {
  heads: heads?.map((head) => <Translate label={head} />),
  transmuter,
};
