import { reducer as authReducer } from './auth';
import { reducer as documentsReducer } from './documents';
import { reducer as reportsReducer } from './reports';
import { reducer as uploadDocsReducer } from './uploadDocs';
import { reducer as entityReducer } from './entity';
import { reducer as userReducer } from './user';
import { reducer as addPurchaseOrderReducer } from './add-purchase-order';
import { reducer as shareReportReducer } from './share-report';

import { reducer as usersReducer } from './users';
import { reducer as profilesReducer } from './profiles';
import { reducer as areasReducer } from './areas';
import { reducer as startUpReducer } from './start-up';
import { reducer as relationshipReducer } from './relationship';
import { reducer as systemReducer } from './system';

import { reducer as modalReducer } from './modal';
import { reducer as configReducer, NAME as configName } from './config';
import { reducer as layoutReducer, NAME as layoutName } from './layout';

export default {
  ...authReducer,
  ...documentsReducer,
  ...reportsReducer,
  ...uploadDocsReducer,
  ...entityReducer,
  ...userReducer,
  ...addPurchaseOrderReducer,
  ...shareReportReducer,
  ...modalReducer,

  ...usersReducer,
  ...profilesReducer,
  ...areasReducer,
  ...startUpReducer,
  ...relationshipReducer,
  ...systemReducer,

  [configName]: configReducer,
  [layoutName]: layoutReducer,
};
