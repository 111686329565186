import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { styledSystem } from 'services/theme/helpers';
import { rgba } from 'polished';

import { Box, Text } from 'ux-thebackyard';

const Container = styled(Box)`
  width: 100%;
  position: relative;
  margin-bottom: 1.5rem;
`;

const StyledInput = styled('input')`
  width: 100%;
  height: 2.5rem;
  border: none;
  border-bottom: solid 1px;
  border-color: ${({ theme }) => rgba(theme.colors.placeholderText, 0.5)};
  outline: none;
  ${styledSystem}
`;

const StyledPlaceholder = styled(Text)`
  color: ${({ theme }) => theme.colors.placeholderText};
  position: absolute;
  left: 0.7rem;
  top: ${({ value }) => (value ? '-1rem' : '0.8rem')};
  pointer-events: none;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  ${Container}:focus-within & {
    color: ${({ theme }) => rgba(theme.colors.styledForm, 0.7)};
    top: -1rem;
  }
`;

const Input = ({ placeholder, value, ...restProps }) => (
  <Container>
    <StyledInput value={value} {...restProps} />
    <StyledPlaceholder value={value} fontSize="xs" fontWeight="rem">{placeholder}</StyledPlaceholder>
  </Container>
);

Input.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default Input;
