import { createSelector } from 'reselect';

import { call } from 'redux-saga/effects';
import RS from 'helpers/RS';
import { removeNoAllowedParams, checkFetchReportsParams } from 'helpers/api';

import {
  paramsToFetchCFDIReport,
  paramsToFetchPurchaseReport,
  paramsToFetchKPIReport,
  paramsToFetchStatusReport,
  ableParamsToFetchReports,
} from 'own/contants';

function* FetchReports(api, { payload: params }) {
  const allowedParams = removeNoAllowedParams(params, Object.values(ableParamsToFetchReports));
  const updatedParams = checkFetchReportsParams(allowedParams);

  const report1 = yield call(api.getReports, {
    params: {
      ...updatedParams,
      ...paramsToFetchCFDIReport,
      ...paramsToFetchKPIReport,
      ...paramsToFetchStatusReport,
    },
  });

  const report2 = yield call(api.getReports, {
    params: {
      ...updatedParams,
      ...paramsToFetchPurchaseReport,
    },
  });

  return { ...report1, ...report2 };
}

const ReportsRS = RS({
  nestedPath: [],
  storeName: 'reports',
  tryGenerator: FetchReports,
});

export const sagas = [
  ReportsRS.sagas,
];

export const { reducer } = ReportsRS;

export const actions = {
  FetchReports: ReportsRS.actions.Call,
};

// Selectors
const getReportsData = ReportsRS.selectors.getData;

const getCFDIReport = createSelector(
  getReportsData,
  (data) => data?.cfdis,
);

const getStatusReport = createSelector(
  getReportsData,
  (data) => data?.estados,
);

const getKPIReport = createSelector(
  getReportsData,
  (data) => data?.kpi,
);

const getPurchaseReport = createSelector(
  getReportsData,
  (data) => data?.ordeDeCompra,
);

export const selectors = {
  getIsBusy: ReportsRS.selectors.getIsBusy,
  getCFDIReport,
  getStatusReport,
  getKPIReport,
  getPurchaseReport,
};
