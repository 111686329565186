import React from 'react';
import { docStatePropType } from 'helpers/propTypes';
import PropTypes from 'prop-types';
import { cfdiStates } from 'own/contants';
import styled from 'styled-components';
import { useModal } from 'services/modal';
import StateHistory from 'scenes/modals/state-history';

import { Button } from 'ux-thebackyard';

const StyledButton = styled(Button)`
  padding: 3.5px 7px;
  text-align: left;
`;

const StateButton = ({ data, ...remainderProps }) => {
  const { renderComponent } = useModal();
  const { estado } = data;
  const lastState = estado[estado.length - 1];

  const handleButtonClick = () => renderComponent(() => <StateHistory data={data} />);

  const isError = lastState.estado === cfdiStates.ERROR;

  return (
    <StyledButton
      rounded
      fontSize="sm"
      minWidth={isError ? 'auto' : '127px'}
      justifyContent={isError ? 'center' : 'start'}
      fontWeight={isError ? 'sem' : 'med'}
      design={isError ? 'error' : 'link'}
      style={{ whiteSpace: isError ? 'unset' : 'pre-line' }}
      onClick={handleButtonClick}
      label={lastState.estado}
      {...remainderProps}
    />
  );
};

StateButton.propTypes = {
  data: PropTypes.shape({
    estado: PropTypes.arrayOf(docStatePropType),
  }),
};

export default StateButton;
