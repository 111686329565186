import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { PATH as loginPath } from 'scenes/login';
import { selectors as userSelectors } from 'store/user';

import { userTypes } from 'own/contants';

const PrivateRoute = ({ userType, ...props }) => {
  const currentUserType = useSelector(userSelectors.fetchUser.getUserType);

  return userType === currentUserType
    ? <Route {...props} />
    : <Redirect to={loginPath} />;
};

PrivateRoute.propTypes = {
  userType: PropTypes.oneOf(Object.values(userTypes)),
};

export default PrivateRoute;
