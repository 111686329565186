import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push, getLocation } from 'connected-react-router';
import { useI18n } from 'services/i18n';
import {
  SvgChartBarSolid,
  SvgHomeSolid,
  SvgUserFriendsSolid,
  SvgFileAltSolid,
  SvgShoppingCartSolid,
  SvgCopy,
} from 'react-line-awesome-svg';
import { SideBarInbox } from 'ux-thebackyard';

import { PATH as pathUserRegistration } from 'pages/users-registration';

import { PATH as pathHome } from 'pages/home';
import { PATH as pathReports } from 'pages/reports';
import { PATH as pathCFDIs } from 'pages/cfdis';
import { PATH as pathPaymentComplements } from 'pages/payment-complements';
import { PATH as pathPurchaseOrders } from 'pages/purchase-orders';

import { addBaseRoute } from '../constants';

const SideBar = (props) => {
  const t = useI18n();
  const dispatch = useDispatch();
  const { pathname } = useSelector(getLocation);

  const handlePushRoute = (route) => dispatch(push(addBaseRoute(route)));
  const handleIsActive = (route) => pathname === addBaseRoute(route);

  const items = [
    {
      label: t('home'),
      svg: SvgHomeSolid,
      onClick: () => handlePushRoute(pathHome),
      active: handleIsActive(pathHome),
    },
    {
      label: t('reports'),
      svg: SvgChartBarSolid,
      onClick: () => handlePushRoute(pathReports),
      active: handleIsActive(pathReports),
    },
    {
      label: t('purchase_orders'),
      svg: SvgShoppingCartSolid,
      onClick: () => handlePushRoute(pathPurchaseOrders),
      active: handleIsActive(pathPurchaseOrders),
    },
    {
      label: t('cfdis'),
      svg: SvgFileAltSolid,
      onClick: () => handlePushRoute(pathCFDIs),
      active: handleIsActive(pathCFDIs),
    },
    {
      label: t('payment_complements'),
      svg: SvgCopy,
      onClick: () => handlePushRoute(pathPaymentComplements),
      active: handleIsActive(pathPaymentComplements),
    },
    {
      label: t('users_registration'),
      svg: SvgUserFriendsSolid,
      onClick: () => handlePushRoute(pathUserRegistration),
      active: handleIsActive(pathUserRegistration),
    },
  ];

  return <SideBarInbox items={items} {...props} />;
};

export default SideBar;
