import qs from 'query-string';
import {
  pageSize,
  getDefaultDates,
  ableParamsToFetchDocs,
} from 'own/contants';

export const paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'comma' });

export const checkFetchDocsParams = (params) => {
  if (params[ableParamsToFetchDocs.UUID]) {
    return {
      [ableParamsToFetchDocs.UUID]: params[ableParamsToFetchDocs.UUID],
    };
  }

  return {
    [ableParamsToFetchDocs.PAGE]: 1,
    [ableParamsToFetchDocs.PAGE_SIZE]: pageSize,
    ...getDefaultDates(),
    ...params,
  };
};

export const checkFetchReportsParams = (params) => ({
  ...getDefaultDates(),
  ...params,
});

export const removeNoAllowedParams = (params, allowedKeys) => {
  const newParams = { ...params };
  Object.keys(params).forEach((key) => {
    if (!allowedKeys.includes(key)) {
      delete newParams[key];
    }
  });
  return newParams;
};
