import { some } from 'lodash';
import { roles, modifiers } from 'own/contants';

const has = (userRoles, modifier, role) => some(userRoles, (r) => r === `${modifier}-${role}`);

const create = (userRoles, modifier) => {
  let data = {};
  Object.keys(roles).forEach((key) => {
    data = { ...data, [key]: has(userRoles, modifier, roles[key]) };
  });
  return data;
};

export const createHasRole = (userRoles) => {
  let data = {};
  Object.keys(modifiers).forEach((key) => {
    data = { ...data, [key]: create(userRoles, modifiers[key]) };
  });
  return data;
};
