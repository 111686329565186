import React from 'react';
import PropTypes from 'prop-types';
import { retentionPropTypes, transferPropTypes } from 'helpers/propTypes';
import { DataBox } from 'ux-thebackyard';
import { useI18n } from 'services/i18n';
import CustomizedTabs from './Tabs';

const TaxType = ({ kpiReport }) => {
  const t = useI18n();
  return (
    <DataBox label={t('tax_type')} separator>
      <CustomizedTabs kpiReport={kpiReport} />
    </DataBox>
  );
};

TaxType.propTypes = {
  kpiReport: PropTypes.shape({
    traslado: PropTypes.shape(transferPropTypes),
    retencion: PropTypes.shape(retentionPropTypes),
  }),
};

export default TaxType;
