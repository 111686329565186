import React, { useEffect } from 'react';
import { useI18n } from 'services/i18n';

import useSearch from 'services/search/useSearch';
import { useHasRoles, hasSome } from 'services/hasRoles';
import {
  PageInbox,
} from 'ux-thebackyard';
import AreaManagement from './AreaManagement';
import ProfileManagement from './ProfileManagement';
import UserManagement from './UserManagement';

import { roles } from '../constants';

const UsersRegistration = () => {
  const t = useI18n();
  const { createRoles } = useSearch();

  useEffect(() => {
    createRoles(roles);
  }, []);

  const { area, profile, user } = useHasRoles();
  const hasSomeArea = hasSome(area);
  const hasSomeProfile = hasSome(profile);
  const hasSomeUser = hasSome(user);

  return (
    <PageInbox label={t('users_registration')}>
      {hasSomeArea && <AreaManagement />}
      {hasSomeProfile && <ProfileManagement />}
      {hasSomeUser && <UserManagement />}
    </PageInbox>
  );
};

export default UsersRegistration;
