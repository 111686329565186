import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ToShortDate } from 'helpers/date';
import { useSelector, useDispatch } from 'react-redux';
import { useModal } from 'services/modal';
import { formatNumberToCurrency } from 'helpers/general';
import useSelectors from 'helpers/useSelectors';
import { actions, selectors } from 'store/relationship';
import { docsTypes } from 'own/contants';
import Confirm from 'scenes/modals/confirm';
import { TableActionsInbox, TableActionsInboxTypes } from 'ux-thebackyard';
import TypeDocField from 'components/type-doc-field';
import DownloadFile from 'scenes/modals/download-file';
import StateButton from 'components/state-button';
import Translate from 'components/translate';

const RemoveRelatedDocsConfirm = ({ doc, refDoc }) => {
  const dispatch = useDispatch();
  const { onClose } = useModal();

  const [
    isBusy,
    isSuccess,
    isFailed,
  ] = useSelectors(
    selectors.remove.getIsBusy,
    selectors.remove.getIsSuccess,
    selectors.remove.getIsFailed,
  );

  useEffect(() => () => dispatch(actions.remove.NoStarted()), []);

  useEffect(() => {
    if (isSuccess) {
      onClose();
      dispatch(actions.fetch.Call({ page: 1, doc: refDoc }));
    }
  }, [isSuccess]);

  const handleOnConfirmRemove = () => dispatch(actions.remove.Call({ refDoc, doc })); // TODO

  return (
    <Confirm
      title="remove_relationship_confirm_title"
      message="remove_relationship_confirm_message"
      errorMessage={isFailed && 'remove_relationship_error_message'}
      isBusy={isBusy}
      onConfirm={handleOnConfirmRemove}
    />
  );
};

RemoveRelatedDocsConfirm.propTypes = {
  doc: PropTypes.objectOf(PropTypes.any),
  refDoc: PropTypes.objectOf(PropTypes.any),
};

const Actions = ({ data = {} }) => {
  const { renderComponent } = useModal();

  const { addressXml, addressPdf, type: currentDocType } = data;
  const hasFilesToDownload = addressXml && addressPdf;

  const refDoc = useSelector(selectors.getRefDoc);

  const { type: refDocType } = refDoc;

  const ableRemoveRelatedDoc = (
    (refDocType === docsTypes.PURCHASE)
    && (currentDocType !== docsTypes.PURCHASE)
    && (currentDocType !== docsTypes.P)
  );
  const RemoveRelatedDocConfirmWrapper = () => <RemoveRelatedDocsConfirm doc={data} refDoc={refDoc} />;
  const handleShowConfirmRemoveRelatedDoc = () => renderComponent(RemoveRelatedDocConfirmWrapper, { hideFooter: true });
  const handleDownload = (fileType, title) => renderComponent(() => <DownloadFile fileType={fileType} title={title} data={data} />);

  const actionsConf = [
    hasFilesToDownload && { type: TableActionsInboxTypes.DOWNLOAD_PDF, onClick: () => handleDownload('pdf', 'download_pdf') },
    hasFilesToDownload && { type: TableActionsInboxTypes.DOWNLOAD_XML, onClick: () => handleDownload('xml', 'download_xml') },
    hasFilesToDownload && { type: TableActionsInboxTypes.DOWNLOAD_ZIP, onClick: () => handleDownload('zip', 'download_zip') },
    ableRemoveRelatedDoc && { type: TableActionsInboxTypes.UNRELATED_DOCS, onClick: handleShowConfirmRemoveRelatedDoc },
  ];

  return (
    <TableActionsInbox actions={actionsConf} />
  );
};

const heads = [
  'actions',
  'type',
  'serie',
  'folio',
  'date',
  'issuing_rfc',
  'receiver_rfc',
  'state',
  'total',
  'uuid_fiscal_folio',
];

const transmuter = (data) => {
  const {
    uuid, serie, folio, date, rfcIssuer, rfcReceiver, total, type,
  } = data;

  return [
    <Actions data={data} />,
    <TypeDocField type={type} />,
    serie,
    folio,
    ToShortDate(date, 'string'),
    rfcIssuer,
    rfcReceiver,
    <StateButton data={data} />,
    formatNumberToCurrency(total),
    uuid,
  ];
};

export default {
  heads: heads?.map((head) => <Translate label={head} />),
  transmuter,
};
