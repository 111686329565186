import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { call, put, select } from 'redux-saga/effects';
import RS, { Reset as ResetRS } from 'helpers/RS';

const NAME = 'auth';

function* signIn(api, { payload: { email, password } }) {
  const data = yield call(api.signIn, { email, password });
  return data;
}

const signInRS = RS({
  nestedPath: [NAME],
  storeName: 'signIn',
  tryGenerator: signIn,
  saveToLS: true,
});

/// ////////////////////////////////////////////////////////////////////////////////

function* signOut(api) {
  yield call(api.signOut);
  yield put(ResetRS());
  return null;
}

const signOutRS = RS({
  nestedPath: [NAME],
  storeName: 'signOut',
  tryGenerator: signOut,
});
/// ////////////////////////////////////////////////////////////////////////////////

function* completeNewPassword(api, { payload: password }) {
  const authData = yield select(signInRS.selectors.getData);
  const data = yield call(api.completeNewPassword, { authData, password });
  yield put(signInRS.actions.SetData(data));
  return null;
}

const completeNewPasswordRS = RS({
  nestedPath: [NAME],
  storeName: 'completeNewPassword',
  tryGenerator: completeNewPassword,
});

/// ////////////////////////////////////////////////////////////////////////////////

function* changePassword(api, { payload: { newPassword, oldPassword } }) {
  const authData = yield select(signInRS.selectors.getData);
  const data = yield call(api.changePassword, { authData, newPassword, oldPassword });

  // yield put(signInRS.actions.SetData(data));
  return data;
}

const changePasswordRS = RS({
  nestedPath: [NAME],
  storeName: 'changePassword',
  tryGenerator: changePassword,
});

/// ////////////////////////////////////////////////////////////////////////////////

export const sagas = [
  signInRS.sagas,
  signOutRS.sagas,
  completeNewPasswordRS.sagas,
  changePasswordRS.sagas,
];

export const reducer = {
  [NAME]: combineReducers({
    ...signInRS.reducer,
    ...signOutRS.reducer,
    ...completeNewPasswordRS.reducer,
    ...changePasswordRS.reducer,
  }),
};

export const actions = {
  signIn: signInRS.actions,
  signOut: signOutRS.actions,
  completeNewPassword: completeNewPasswordRS.actions,
  changePassword: changePasswordRS.actions,
};

// Selectors

const getIsSupport = createSelector(
  signInRS.selectors.getData,
  (data) => !!data?.signInUserSession?.accessToken?.payload['cognito:groups'],
);

export const selectors = {
  signIn: signInRS.selectors,
  signOut: signOutRS.selectors,
  completeNewPassword: completeNewPasswordRS.selectors,
  changePassword: changePasswordRS.selectors,

  getIsSupport,
};
