import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router';
import { ableParamsToFetch } from 'own/contants';
import {
  getPropHasChanged,
  paramsToSearchCheckingRole,
  searchFromRoles,
} from './helpers';

import { SearchContext } from './context';

const useSearch = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { search, params } = useContext(SearchContext);

  const createRoles = (roles) => {
    if (!params.role) {
      dispatch(push({ pathname, search: searchFromRoles(roles) }));
    }
  };

  const updateParams = (props = {}, role) => {
    // return if has incompatibility with role
    if ((role && !params.role) || (!role && params.role)) {
      return;
    }

    const roleParams = role ? params[role] : params;
    const newParams = { ...roleParams, ...props };
    const propHasChanged = getPropHasChanged(roleParams, newParams);

    // When updating rfcIssuer, rfcReceiver, dateRange reset page
    if (propHasChanged([
      ableParamsToFetch.RFC_ISSUER,
      ableParamsToFetch.RFC_RECEIVER,
      ableParamsToFetch.DATE_END,
      ableParamsToFetch.DATE_IN,
      ableParamsToFetch.TOTAL_END,
      ableParamsToFetch.TOTAL_IN,
      ableParamsToFetch.UUID,
      ableParamsToFetch.STATE,
    ])) {
      newParams.page = 1;
    }

    // console.log('newParams', newParams);
    const paramsWithRole = role
      ? {
        ...params,
        [role]: newParams,
      }
      : newParams;

    const newSearch = paramsToSearchCheckingRole(paramsWithRole);
    dispatch(push({ pathname, search: newSearch }));
  };

  return {
    search,
    params,
    updateParams,
    createRoles,
  };
};

export default useSearch;
