import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useI18n } from 'services/i18n';
import useSelectors from 'helpers/useSelectors';
import { remove, findIndex, isNumber } from 'lodash';
import FileInput from 'components/file-input';
import styled from 'styled-components';
import {
  actions as actionsUploadDoc,
  selectors as selectorsUploadDocs,
} from 'store/uploadDocs';
import {
  Box,
  Button,
  Spinner,
} from 'ux-thebackyard';
import FileListItem, { states } from './FileListItem';
import { addFilesPairs, removeSingleElements, isValidPair } from '../helpers';

const FilesContainer = styled(Box)`
    margin-top: 1rem;
    position: relative;
`;

const Scrollable = styled(Box)`
    max-height: 20rem;
    overflow-y: auto;
`;

const SpinnerContainer = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.2);
`;

const FileUploader = () => {
  const [filesPairs, setFilesPairs] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [currentIndexToUpload, setCurrentIndexToUpload] = useState(null);
  const ref = useRef();
  const dispatch = useDispatch();
  const t = useI18n();
  const hasValidPairs = !!removeSingleElements(filesPairs).length;
  const hasPairs = !!filesPairs.length;

  const [
    isBusy,
    isFailed,
    isSuccess,
  ] = useSelectors(
    selectorsUploadDocs.getIsBusy,
    selectorsUploadDocs.getIsFailed,
    selectorsUploadDocs.getIsSuccess,
  );

  const handleDelete = (index) => setFilesPairs(remove(filesPairs, (files, i) => index !== i));
  const handleAdd = ({ xmls, pdfs }) => setFilesPairs(addFilesPairs({ filesPairs, xmls, pdfs }));

  const handleSelectFiles = (e) => {
    const files = [...e.target.files];
    const xmls = files.filter((f) => f.name.endsWith('.xml'));
    const pdfs = files.filter((f) => f.name.endsWith('.pdf'));
    handleAdd({ xmls, pdfs });
    ref.current.value = '';
  };

  const handleStartUpload = () => setUploading(true);
  const handleStopUpload = () => setUploading(false);

  const handleUploadNextPair = () => {
    const nextIndexToUpload = isNumber(currentIndexToUpload)
      ? findIndex(filesPairs, (pair, index) => index > currentIndexToUpload && isValidPair(pair))
      : 0;

    if (nextIndexToUpload >= 0) {
      setCurrentIndexToUpload(nextIndexToUpload);
      dispatch(actionsUploadDoc.UploadDocs(filesPairs[nextIndexToUpload]));
    } else {
      handleStopUpload();
      setCurrentIndexToUpload(null);
    }
  };

  const handleClearList = () => setFilesPairs([]);

  useEffect(() => {
    if (uploading && !isBusy) handleUploadNextPair();
  }, [isBusy, uploading]);

  useEffect(() => {
    if (uploading && isFailed) {
      const newFilesPairs = [...filesPairs];
      newFilesPairs[currentIndexToUpload].state = states.FAILED;
      setFilesPairs(newFilesPairs);
    }
  }, [isFailed, uploading]);

  useEffect(() => {
    if (uploading && isSuccess) {
      const newFilesPairs = [...filesPairs];
      newFilesPairs[currentIndexToUpload].state = states.UPLOADED;
      setFilesPairs(newFilesPairs);
    }
  }, [isSuccess, uploading]);

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center">
        <FileInput
          multiple
          accept=".pdf,.xml"
          onChange={handleSelectFiles}
          flexGrow="1"
          ref={ref}
          disabled={uploading}
        />
        <Button
          design="default"
          mt="5px"
          ml="10px"
          p="0.7rem 1.5rem"
          onClick={handleClearList}
          disabled={!hasPairs || uploading}
          label={t('clear_list')}
        />

        <Button
          design="info"
          mt="5px"
          ml="10px"
          p="0.7rem 1.5rem"
          onClick={handleStartUpload}
          disabled={!hasValidPairs || uploading}
          label={t('upload')}
        />
      </Box>
      <FilesContainer>
        {uploading && <SpinnerContainer centeredContent><Spinner /></SpinnerContainer>}
        <Scrollable>
          {!!filesPairs?.length && (
            filesPairs?.map((filesPair, index) => (
              <FileListItem
                key={index}
                filesPair={filesPair}
                onDelete={() => handleDelete(index)}
              />
            ))
          )}
        </Scrollable>
      </FilesContainer>
    </Box>
  );
};

export default FileUploader;
