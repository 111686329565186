import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from 'ux-thebackyard';

const Card = styled(Box)`
  width: 100%;
  background-color: white;
  text-align: center;
`;

const CardHeader = styled(Box)`
  color:${(props) => props.color};
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: 1rem;
`;

const CardContent = styled(Box)`
  color:${(props) => props.color};  
  font-size: 1rem;
  max-width: 150px;
`;

const NumberCard = ({
  number, label, primaryTextColor, secondaryTextColor,
}) => (
  <Card>
    <CardHeader color={primaryTextColor}>
      {number}
    </CardHeader>
    <CardContent
      color={secondaryTextColor}
    >
      {label}
    </CardContent>
  </Card>
);

NumberCard.propTypes = {
  number: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  primaryTextColor: PropTypes.string,
  secondaryTextColor: PropTypes.string,
};

export default NumberCard;
