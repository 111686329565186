import React, { createContext } from 'react';
import { useSelector } from 'react-redux';
import { getSearch } from 'connected-react-router';
import PropTypes from 'prop-types';
import { transmuteSearchCheckingRole } from './helpers';

export const SearchContext = createContext(null);

export const SearchProvider = ({ children }) => {
  const lineSearch = useSelector(getSearch).slice(1);
  const { search, params } = transmuteSearchCheckingRole(lineSearch);
  return (
    <SearchContext.Provider value={{ search, params }}>
      {children}
    </SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: PropTypes.node,
};
