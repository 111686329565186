const config = {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: 'us-east-2',
    BUCKET: 'notes-app1-files',
  },
  apiGateway: {
    REGION: 'us-east-2',
  },
  cognito: {
    REGION: 'us-east-2',
    USER_POOL_ID: 'us-east-2_Dk8pTBnkw',
    APP_CLIENT_ID: '6qe25li51enjnrqqrhri2g88tb',
    IDENTITY_POOL_ID: 'us-east-2:2c81523d-da1e-45f1-9c47-d8b60187b84a',
  },
};

export const MANAGEMENT = 'MANAGEMENT';
export const INGEST = 'INGEST';
export const DOCUMENTS = 'DOCUMENTS';

const {
  REACT_APP_API_MANAGEMENT,
  REACT_APP_API_DOCUMENTS,
  REACT_APP_API_INGEST,
} = process.env;

export const endpointsURL = {
  [MANAGEMENT]: REACT_APP_API_MANAGEMENT,
  [DOCUMENTS]: REACT_APP_API_DOCUMENTS,
  [INGEST]: REACT_APP_API_INGEST,
};

export default {
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: MANAGEMENT,
        endpoint: endpointsURL.MANAGEMENT,
        region: config.apiGateway.REGION,
      },
      {
        name: DOCUMENTS,
        endpoint: endpointsURL.DOCUMENTS,
        region: config.apiGateway.REGION,
      },
      {
        name: INGEST,
        endpoint: endpointsURL.INGEST,
        region: config.apiGateway.REGION,
      },
    ],
  },
};
