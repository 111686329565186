import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { call } from 'redux-saga/effects';
import RS from 'helpers/RS';
import { userTypes, ableParamsToFetchDocs } from 'own/contants';

const NAME = 'user';

/// USER ////////////////////////////////////////////////////////////////////////////////
function* fetchUser(api) {
  const { value } = yield call(api.userFetchUser);
  return value;
}

const fetchUserRS = RS({
  nestedPath: [NAME],
  storeName: 'fetchUser',
  tryGenerator: fetchUser,
  saveToLS: true,
});

const getUser = fetchUserRS.selectors.getData;

const getUserEmail = createSelector(
  getUser,
  (data) => data?.userEmail,
);

const getUserName = createSelector(
  getUser,
  (data) => data?.name,
);

const getUserType = createSelector(
  getUser,
  (data) => data?.userType,
);

const getIsClient = createSelector(
  getUserType,
  (data) => data === userTypes.CLIENT,
);

const getUserProfile = createSelector(
  getUser,
  (data) => data?.userProfile,
);

const getEntityId = createSelector(
  getUser,
  (data) => data?.entityId,
);

const getUserAreas = createSelector(
  getUserProfile,
  (data) => data?.profileAreas,
);

const getUserRoles = createSelector(
  getUserProfile,
  (data) => data?.profileRoles,
);

/// USER PROVIDERS ////////////////////////////////////////////////////////////////////////////////

function* fetchProviders(api) {
  const { value } = yield call(api.userFetchUserProviders);
  return value;
}

const fetchProvidersRS = RS({
  nestedPath: [NAME],
  storeName: 'fetchProviders',
  tryGenerator: fetchProviders,
});

function* addProviders(api, { payload: provider }) {
  return yield call(api.userAddUserProviders, { data: { providers: [provider] } });
}

const addProvidersRS = RS({
  nestedPath: [NAME],
  storeName: 'addProviders',
  tryGenerator: addProviders,
});

function* removeProviders(api, { payload: providerId }) {
  return yield call(api.userRemoveUserProviders, { data: { providers: [providerId] } });
}

const removeProvidersRS = RS({
  nestedPath: [NAME],
  storeName: 'removeProviders',
  tryGenerator: removeProviders,
});

/// USER RFC////////////////////////////////////////////////////////////////////////////////

function* fetchRFCs(api) {
  const { value } = yield call(api.userFetchUserRFCs);
  return value;
}

const fetchRFCsRS = RS({
  nestedPath: [NAME],
  storeName: 'fetchRFCs',
  tryGenerator: fetchRFCs,
});

const getRFCs = fetchRFCsRS.selectors.getData;

const getRFCsIssuer = createSelector(
  getRFCs,
  (rfcs) => rfcs?.senderRfcs,
);

const getRFCsReceiver = createSelector(
  getRFCs,
  (rfcs) => rfcs?.receptorRfcs,
);

const getMyRFCsFields = createSelector(
  getRFCsIssuer,
  getRFCsReceiver,
  getUserType,
  (rfcIssuer, rfcReceiver, userType) => (userType === userTypes.CLIENT
    ? { key: ableParamsToFetchDocs.RFC_RECEIVER, value: rfcReceiver }
    : { key: ableParamsToFetchDocs.RFC_ISSUER, value: rfcIssuer }),
);

// fetchNotification ///////////////////////////////////////////////////////////////////

function* fetchNotifications(api) {
  const { value } = yield call(api.userFetchNotifications);
  return value;
}

const fetchNotificationsRS = RS({
  nestedPath: [NAME],
  storeName: 'fetchNotifications',
  tryGenerator: fetchNotifications,
});

/// ////////////////////////////////////////////////////////////////////////////////

export const sagas = [
  fetchUserRS.sagas,
  fetchRFCsRS.sagas,
  fetchProvidersRS.sagas,
  addProvidersRS.sagas,
  removeProvidersRS.sagas,
  fetchNotificationsRS.sagas,
];

export const reducer = {
  [NAME]: combineReducers({
    ...fetchUserRS.reducer,
    ...fetchRFCsRS.reducer,
    ...fetchProvidersRS.reducer,
    ...addProvidersRS.reducer,
    ...removeProvidersRS.reducer,
    ...fetchNotificationsRS.reducer,
  }),
};

export const actions = {
  fetchUser: fetchUserRS.actions,
  fetchRFCs: fetchRFCsRS.actions,
  fetchProviders: fetchProvidersRS.actions,
  addProviders: addProvidersRS.actions,
  removeProviders: removeProvidersRS.actions,
  fetchNotifications: fetchNotificationsRS.actions,
};

export const selectors = {
  // USER
  fetchUser: {
    ...fetchUserRS.selectors,
    getEntityId,
    getUserName,
    getUserEmail,
    getUserType,
    getUserProfile,
    getIsClient,
    getUserAreas,
    getUserRoles,
  },

  fetchRFCs: {
    ...fetchRFCsRS.selectors,
    getRFCsIssuer,
    getRFCsReceiver,
    getMyRFCsFields,
  },

  fetchNotifications: fetchNotificationsRS.selectors,

  fetchProviders: fetchProvidersRS.selectors,
  addProviders: addProvidersRS.selectors,
  removeProviders: removeProvidersRS.selectors,
};
