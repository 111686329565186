import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import Layout from 'components/layout';

import Profile, { PATH as pathProfile } from 'pages/profile';
import { useHasRoles, hasSome } from 'services/hasRoles';
import AuthorizedRoute from 'components/authorized-route';

import Home, { PATH as pathHome } from 'pages/home';
import Reports, { PATH as pathReports } from 'pages/reports';
import CFDIS, { PATH as pathCfdis } from 'pages/cfdis';
import UserRegistration, { PATH as pathUsersRegistration } from 'pages/users-registration';

import Providers, { PATH as pathProviders } from 'pages/providers';
import PurchaseOrders, { PATH as pathPurchaseOrders } from 'pages/purchase-orders';

import SideBar from './SideBar';
import { PATH as parentPath, addBaseRoute } from '../constants';

const User = () => {
  const {
    document, reports, area, profile, user, provider,
  } = useHasRoles();
  const handleRedirectToHome = () => <Redirect to={addBaseRoute(pathHome)} />;

  return (
    <Layout sideBar={SideBar}>
      <Switch>
        <Route exact path={parentPath} render={handleRedirectToHome} />
        <AuthorizedRoute
          hasAccess={hasSome(document) || hasSome(reports)}
          path={addBaseRoute(pathHome)}
          component={Home}
        />
        <AuthorizedRoute
          hasAccess={hasSome(reports)}
          path={addBaseRoute(pathReports)}
          component={Reports}
        />
        <AuthorizedRoute
          hasAccess={hasSome(document)}
          path={addBaseRoute(pathCfdis)}
          component={CFDIS}
        />
        <AuthorizedRoute
          hasAccess={hasSome(document)}
          path={addBaseRoute(pathPurchaseOrders)}
          component={PurchaseOrders}
        />

        <AuthorizedRoute
          hasAccess={hasSome(provider)}
          path={addBaseRoute(pathProviders)}
          component={Providers}
        />

        <AuthorizedRoute
          hasAccess={hasSome(user) || hasSome(area) || hasSome(profile)}
          path={addBaseRoute(pathUsersRegistration)}
          component={UserRegistration}
        />
        <Route path={addBaseRoute(pathProfile)} component={Profile} />
        <Route path="*" render={handleRedirectToHome} />
      </Switch>
    </Layout>
  );
};

export default User;
