import { combineReducers } from 'redux';
import { call } from 'redux-saga/effects';

import RS from 'helpers/RS';

const NAME = 'entity';

// Entity ///////////////////////////////////////////////////////////////////
function* fetch(api) {
  const { value } = yield call(api.userFetchEntity);
  return value;
}

const fetchRS = RS({
  nestedPath: [NAME],
  storeName: 'fetch',
  tryGenerator: fetch,
});

/// ////////////////////////////////////////////////////////////////////////////////

export const sagas = [
  fetchRS.sagas,
];

export const reducer = {
  [NAME]: combineReducers({
    ...fetchRS.reducer,
  }),
};

export const actions = {
  fetch: fetchRS.actions,
};

export const selectors = {
  fetch: fetchRS.selectors,
};
