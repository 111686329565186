import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useI18n } from 'services/i18n';
import { useModal } from 'services/modal';
import { ableUserFields, ableProfileFields } from 'own/contants';
import { some } from 'lodash';
import { actions as usersActions, selectors as usersSelectors } from 'store/users';
import { selectors as profilesSelectors, actions as profilesActions } from 'store/profiles';

import useSelectors from 'helpers/useSelectors';

import {
  DataBox,
  Button,
  FormInbox,
  FormInboxFieldTypes,
  FormInboxValidationType,
} from 'ux-thebackyard';

const defaultUser = {
  [ableUserFields.NAME]: '',
  [ableUserFields.USER_EMAIL]: '',
  [ableUserFields.USER_PROFILE]: '',
};

const NewUpdateUser = ({ user }) => {
  const [currentUser, setCurrentUser] = useState(defaultUser);
  const [submitBtnProp, setSubmitBtnProp] = useState({});
  const [errorMatchWithUser, setErrorMatchWithUser] = useState(false);

  const dispatch = useDispatch();
  const t = useI18n();

  const { onClose: onModalClose, ModalFooter } = useModal();

  const [
    profiles,

    isBusyAddUser,
    isSuccessAddUser,
    isFailedAddUser,

    isBusyUpdateUsers,
    isSuccessUpdateUsers,
    isFailedUpdateUsers,

    users,
  ] = useSelectors(
    profilesSelectors.fetch.getData,

    usersSelectors.add.getIsBusy,
    usersSelectors.add.getIsSuccess,
    usersSelectors.add.getIsFailed,

    usersSelectors.update.getIsBusy,
    usersSelectors.update.getIsSuccess,
    usersSelectors.update.getIsFailed,

    usersSelectors.fetch.getData,
  );

  useEffect(() => () => {
    dispatch(usersActions.add.NoStarted());
    dispatch(usersActions.update.NoStarted());
  }, []);

  useEffect(() => {
    if (!users) dispatch(usersActions.fetch.Call());
  }, [users]);

  useEffect(() => () => {
    if (!profiles?.length) dispatch(profilesActions.fetch.Call());
  }, [profiles]);

  useEffect(() => {
    if (isSuccessAddUser || isSuccessUpdateUsers) {
      dispatch(usersActions.fetch.Call());
      onModalClose();
    }
  }, [isSuccessAddUser, isSuccessUpdateUsers]);

  useEffect(() => {
    if (user) {
      const { userEmail, userProfile, name } = user;
      setCurrentUser({
        name,
        userEmail,
        // eslint-disable-next-line no-underscore-dangle
        userProfile: userProfile._id,
      });
    }
  }, [user]);

  const itemsForm = [
    {
      label: t('name'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableUserFields.NAME,
      required: true,
      validations: [
        { type: FormInboxValidationType.REQUIRED },
      ],
    },
    !user && {
      label: t('email_title'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableUserFields.USER_EMAIL,
      required: true,
      validations: [
        { type: FormInboxValidationType.REQUIRED },
        { type: FormInboxValidationType.EMAIL },
      ],
      errorMessage: errorMatchWithUser && t('email_exist_already'),
    },
    {
      label: t('profile'),
      items: profiles,
      optionTextField: ableProfileFields.PROFILE_NAME,
      optionValueField: ableProfileFields.ID,
      enableSearch: true,
      single: true,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableUserFields.USER_PROFILE,
      required: true,
      validations: [
        { type: FormInboxValidationType.REQUIRED },
      ],
    },
  ];

  const handleOnSubmit = (userT) => dispatch(usersActions[user ? 'update' : 'add'].Call(userT));

  const matchEmailWithCreatedUser = (newEmail) => ((users && newEmail) ? some(users, ({ userEmail }) => userEmail === newEmail) : false);

  useEffect(() => {
    setErrorMatchWithUser(matchEmailWithCreatedUser(currentUser[ableUserFields.USER_EMAIL]));
  }, [currentUser]);

  const isFailed = isFailedAddUser || isFailedUpdateUsers;
  const isSuccess = isSuccessAddUser || isSuccessUpdateUsers;

  const message = (isFailed || isSuccess) && {
    type: (isFailed && 'error') || (isSuccess && 'success'),
    text:
      (isFailedAddUser && t('add_user_failed_message'))
      || (isSuccessAddUser && t('add_user_success_message'))
      || (isFailedUpdateUsers && t('update_user_failed_message'))
      || (isSuccessUpdateUsers && t('update_user_success_message')),
  };

  return (
    <>
      <DataBox label={t(user ? 'update_user' : 'new_user')} separator width="35rem">
        <FormInbox
          items={itemsForm}
          value={currentUser}
          onChange={setCurrentUser}
          onSubmit={handleOnSubmit}
          changeExternSubmitBtnProps={setSubmitBtnProp}
        />
      </DataBox>
      <ModalFooter
        showSpinner={isBusyAddUser || isBusyUpdateUsers}
        message={message}
      >
        <Button
          design="primary"
          rounded
          m="10px"
          p="12px 30px"
          label={t('upload')}
          {...submitBtnProp}
        />

      </ModalFooter>
    </>
  );
};

NewUpdateUser.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
};

export default NewUpdateUser;
