// TODO after fix that, dynamic read json files
import en from './locales/en.json';
import es from './locales/es.json';

const messages = {
  en,
  es,
};

const defaultLocale = 'es';

export const defaultTranslations = {
  locale: defaultLocale,
  messages: messages[defaultLocale],
  defaultLocale,
};
