import React from 'react';
import PropTypes from 'prop-types';
import { useI18n } from 'services/i18n';
import styled, { useTheme } from 'styled-components';
import { NumberCard } from 'components/data-card';
import { splitCommaSeparatedArray } from 'helpers/general';
import { isEmpty } from 'lodash';

import {
  DataBox,
  Box,
  Spinner,
  ChartJs,
} from 'ux-thebackyard';

const SubContainer = styled(Box)`
  margin: 1rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Totals = ({ cfdiReport, purchaseReport }) => {
  const theme = useTheme();
  const t = useI18n();

  const cardsData = [
    { number: cfdiReport?.cfdis?.total, label: 'cfdis_received' },
    { number: cfdiReport?.cfdiError?.total, label: 'cfdis_with_error' },
    { number: purchaseReport?.ocAtendida?.total, label: 'completed_oc' },
    { number: purchaseReport?.ocNoAtendida?.total, label: 'not_attended_oc' },
    { number: purchaseReport?.ocAtendidaParcial?.total, label: 'partial_attended_oc' },
  ];
  return (
    <Box>
      {cardsData.map((data) => (
        <NumberCard
          key={data.label}
          number={data.number || 0}
          label={t(data.label)}
          primaryTextColor={theme.colors.dataCardDefaultTitleColor}
          secondaryTextColor={theme.colors.primaryText}
        />
      ))}
    </Box>
  );
};

const colors = [
  '#88ab3f',
  '#cc4927',
  '#4064aa',
  '#e6b52e',
  '#483e77',
];

const CfdisPurchaseOrders = ({ cfdiReport, purchaseReport, isBusy }) => {
  const t = useI18n();
  const months = splitCommaSeparatedArray(t('short_months_comma_separate'));

  const getBarChartData = () => {
    if (!cfdiReport || !purchaseReport) return null;

    const dataArray = [
      cfdiReport.cfdis,
      cfdiReport.cfdiError,
      purchaseReport.ocAtendida,
      purchaseReport.ocNoAtendida,
      purchaseReport.ocAtendidaParcial,
    ];

    let maxLengthIndex = -1;
    let maxLength = 0;

    dataArray.forEach((data, index) => {
      if (isEmpty(data.detalle)) return;
      const currentLength = data.detalle.detalle.length;
      if (currentLength > maxLength) {
        maxLength = currentLength;
        maxLengthIndex = index;
      }
    });

    if (!maxLength) return null;

    const labels = dataArray[maxLengthIndex].detalle.detalle.map(({ month, year }) => (`${months[month - 1]}/${year}`));

    const datasets = dataArray.map((data, index) => {
      if (isEmpty(data.detalle)) {
        return {
          label: data.etiqueta,
          backgroundColor: colors[index],
          data: [],
        };
      }
      const paddingAmount = maxLength - data.detalle.detalle.length;
      const padding = paddingAmount ? Array(paddingAmount).fill(0) : [];
      return ({
        label: data.etiqueta,
        backgroundColor: colors[index],
        data: [...padding, ...data.detalle.detalle.map(({ total }) => total)],
      });
    });

    return {
      type: 'bar',
      data: {
        labels,
        datasets,
      },
    };
  };

  const data = getBarChartData();

  return (
    <DataBox label={t('cfdis_orders_purchase')}>
      {isBusy ? (
        <Box centeredContent width="100%" height="40rem">
          <Spinner />
        </Box>
      ) : (
        <Box display="flex">
          <SubContainer flexGrow="1">
            {data && <ChartJs {...data} />}
          </SubContainer>
          <SubContainer>
            <Totals cfdiReport={cfdiReport} purchaseReport={purchaseReport} />
          </SubContainer>
        </Box>
      )}
    </DataBox>
  );
};

const commonPropTypes = {

  cfdiReport: PropTypes.objectOf(PropTypes.shape({
    cfdis: PropTypes.shape({
      total: PropTypes.number,
    }),
    cfdiError: PropTypes.shape({
      total: PropTypes.number,
    }),
  })),
  purchaseReport: PropTypes.objectOf(PropTypes.shape({
    ocAtendida: PropTypes.shape({
      total: PropTypes.number,
    }),
    ocNoAtendida: PropTypes.shape({
      total: PropTypes.number,
    }),
    ocAtendidaParcial: PropTypes.shape({
      total: PropTypes.number,
    }),
  })),
};

Totals.propTypes = { ...commonPropTypes };

CfdisPurchaseOrders.propTypes = {
  ...commonPropTypes,
  isBusy: PropTypes.bool,
};

export default CfdisPurchaseOrders;
