import { call } from 'redux-saga/effects';
import RS from 'helpers/RS';

function* UploadDocs(api, { payload: { pdf, xml } }) {
  const data = { cfdi_xml: xml, cfdi_pdf: pdf };
  return yield call(api.uploadDocs, { data });
}

const UploadDocsRS = RS({
  nestedPath: [],
  storeName: 'uploadDocs',
  tryGenerator: UploadDocs,
});

export const sagas = [
  UploadDocsRS.sagas,
];

export const { reducer } = UploadDocsRS;

export const actions = {
  UploadDocs: UploadDocsRS.actions.Call,
};

// Selectors
export const selectors = {
  getIsBusy: UploadDocsRS.selectors.getIsBusy,
  getIsSuccess: UploadDocsRS.selectors.getIsSuccess,
  getIsFailed: UploadDocsRS.selectors.getIsFailed,
};
