import React from 'react';
import { useTheme } from 'styled-components';
import { MoneyCard } from 'components/data-card';
import { retentionPropTypes } from 'helpers/propTypes';

const Retention = ({ retention }) => {
  const theme = useTheme();

  return (
    <div>
      <MoneyCard
        price={retention?.IVA}
        label="IVA"
        primaryTextColor={theme.colors.dataCardDefaultTitleColor}
        secondaryTextColor={theme.colors.sideBarIconInactive}
      />
      <MoneyCard
        price={retention?.IEPS}
        label="IEPS"
        primaryTextColor={theme.colors.dataCardDefaultTitleColor}
        secondaryTextColor={theme.colors.sideBarIconInactive}
      />
      <MoneyCard
        price={retention?.ISR}
        label="ISR"
        primaryTextColor={theme.colors.dataCardDefaultTitleColor}
        secondaryTextColor={theme.colors.sideBarIconInactive}
      />
      <MoneyCard
        price={retention?.TOTAL}
        label="Total de retenciones"
        primaryTextColor={theme.colors.dataCardPrimaryTitleColor}
        secondaryTextColor={theme.colors.sideBarIconInactive}
      />
    </div>
  );
};

Retention.propTypes = retentionPropTypes;

export default Retention;
