import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useI18n } from 'services/i18n';
import useSelectors from 'helpers/useSelectors';
import useSearch from 'services/search/useSearch';
import { remove } from 'lodash';
import {
  typeDocNameItemsDropdownSelect,
  cfdiStatesValues,
} from 'own/helpers';
import {
  actions as docsActions,
  selectors as docsSelectors,
} from 'store/documents';

import { selectors as userSelectors } from 'store/user';

import {
  SvgSyncSolid,
} from 'react-line-awesome-svg';

import {
  ableParamsToFetch,
  cfdiTypes,
} from 'own/contants';

import {
  DataBox,
  Accordion,
  TableBox,
  FormInbox,
  FormInboxFieldTypes,
} from 'ux-thebackyard';

import CFDIClientTrans from 'own/table-transmuters/cfdi-client';
import CFDIProviderTrans from 'own/table-transmuters/cfdi-provider';

import { roles } from '../constants';

const itemsDropdownSelectExceptP = [...typeDocNameItemsDropdownSelect];
remove(itemsDropdownSelectExceptP, ({ type }) => type === cfdiTypes.P);

const paramsToFetchEveryCFDIExceptPC = {
  [ableParamsToFetch.TYPE]: itemsDropdownSelectExceptP.map(({ type }) => type),
};

const role = roles[0];

const CFDISTable = () => {
  const dispatch = useDispatch();
  const t = useI18n();
  const { search: { [role]: search = '' }, params: { [role]: params = {} }, updateParams: updateParamsT } = useSearch();
  const updateParams = (p) => updateParamsT(p, role);

  const [
    rfcsIssuer,
    rfcsReceiver,

    cfdis,
    isBusy,
    pagination,

    isClient,
  ] = useSelectors(
    userSelectors.fetchRFCs.getRFCsIssuer,
    userSelectors.fetchRFCs.getRFCsReceiver,

    docsSelectors.getDocs1,
    docsSelectors.getDocs1IsBusy,
    docsSelectors.getDocs1Pagination,

    userSelectors.fetchUser.getIsClient,
  );
  const tableTrans = isClient ? CFDIClientTrans : CFDIProviderTrans;
  const itemsDropdownSelectTypeDocs = isClient ? itemsDropdownSelectExceptP : typeDocNameItemsDropdownSelect;

  const paramsToFetchType = isClient ? paramsToFetchEveryCFDIExceptPC : {}; // Except PC if Client
  const FetchDocs = () => dispatch(docsActions.FetchDocs1({
    ...paramsToFetchType,
    ...params,
  }));

  const handleChangePage = (pageT) => updateParams({ page: pageT });

  useEffect(() => {
    FetchDocs();
  }, [search]);

  const formConfig = [
    {
      label: t('receiver_rfc'),
      enableSearch: true,
      items: rfcsReceiver,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableParamsToFetch.RFC_RECEIVER,
    },
    {
      label: t('issuing_rfc'),
      enableSearch: true,
      items: rfcsIssuer,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableParamsToFetch.RFC_ISSUER,
    },
    {
      label: t('state'),
      enableSearch: true,
      items: cfdiStatesValues,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableParamsToFetch.STATE,
    },
    {
      label: t('date'),
      fieldType: FormInboxFieldTypes.DATE_RANGE,
      fieldNameStartDate: ableParamsToFetch.DATE_IN,
      fieldNameEndDate: ableParamsToFetch.DATE_END,
    },
    {
      label: t('uuid_fiscal_folio'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableParamsToFetch.UUID,
    },
    {
      label: t('total'),
      fieldType: FormInboxFieldTypes.NUMERIC_RANGE,
      fieldNameStartValue: ableParamsToFetch.TOTAL_IN,
      fieldNameEndValue: ableParamsToFetch.TOTAL_END,
    },
    {
      label: t('serie'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableParamsToFetch.SERIE,
    },
    {
      label: t('folio'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableParamsToFetch.FOLIO,
      type: 'number',
    },
    {
      label: t('type'),
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      items: itemsDropdownSelectTypeDocs,
      optionTextField: 'name',
      optionValueField: 'type',
      fieldName: ableParamsToFetch.TYPE,
    },
  ];

  const headBtnsProps = [
    {
      label: t('update'), design: 'shadow', svg: SvgSyncSolid, rounded: true, onClick: FetchDocs,
    },
  ];

  return (
    <DataBox
      label={t('cfdis')}
      headBtnsProps={headBtnsProps}
      separator
    >
      <Accordion label={t('filter_search')}>
        <FormInbox
          items={formConfig}
          value={params}
          onSubmit={updateParams}
          submitBtnProps={{ label: t('apply') }}
        />
      </Accordion>
      <TableBox
        transmuter={tableTrans.transmuter}
        heads={tableTrans.heads}
        data={cfdis}
        isBusy={isBusy}
        noFoundMessage={t('no_bills_found')}
        pagination={{
          page: pagination?.page,
          totalPages: pagination?.totalPages,
          totalItems: pagination?.totalDocs,
          pageSize: 50, // TODO
        }}
        onChangePage={handleChangePage}
        stickyHeaders
      />
    </DataBox>
  );
};

export default CFDISTable;
