import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { SearchProvider } from 'services/search/context';
import { I18nProvider } from 'services/i18n';
import { ThemeProvider } from 'services/theme';
import { ModalProvider } from 'services/modal';
import { HasRolesProvider } from 'services/hasRoles';

import makerStore from 'store/makerStore';

import App from './App';

const { store, history } = makerStore();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <SearchProvider>
        <I18nProvider>
          <ThemeProvider>
            <ModalProvider>
              <HasRolesProvider>
                <App />
              </HasRolesProvider>
            </ModalProvider>
          </ThemeProvider>
        </I18nProvider>
      </SearchProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
