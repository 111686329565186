import { Auth, Amplify, API as API_AWS } from 'aws-amplify';
import { paramsSerializer } from 'helpers/api';

import authConfig, {
  INGEST, MANAGEMENT, DOCUMENTS,
} from './authConfig';

Amplify.configure(authConfig);

const API = async({
  method,
  endpoint,
  path,
  params,
  data: body,
}) => {
  const headers = {
    userAuthorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
  };

  const pathWithQueries = params ? `${path}?${paramsSerializer(params)}` : path;
  return API_AWS[method](endpoint, pathWithQueries, { body, headers });
};

export default () => ({
  // AUTH
  signIn: ({ email: username, password }) => Auth.signIn({ username, password }),
  signUp: ({ email: username, password }) => Auth.signUp({ username, password }),
  signOut: () => Auth.signOut(),

  completeNewPassword: ({ authData, password }) => Auth.completeNewPassword(authData, password),

  changePassword: ({ authData, oldPassword, newPassword }) => Auth.changePassword(authData, oldPassword, newPassword),

  isAutheticated: () => Auth.currentSession(),

  // /////////////////////////////////////////////////////////////////////DOCUMENTS
  getDocs: (args) => API({
    method: 'get', endpoint: DOCUMENTS, path: '/search', ...args,
  }),

  insertDocument: (args) => API({
    method: 'post', endpoint: DOCUMENTS, path: '/insert', ...args,
  }),

  // TODO
  sendReportToEmail: (args) => API({
    method: 'post', endpoint: DOCUMENTS, path: '/insert', ...args,
  }),

  getReports: (args) => API({
    method: 'get', endpoint: DOCUMENTS, path: '/reports', ...args,
  }),

  // RELATIONSHIPS
  fetchRelationships: (args) => API({
    method: 'get', endpoint: DOCUMENTS, path: '/related', ...args,
  }),

  addRelationships: (args) => API({
    method: 'put', endpoint: DOCUMENTS, path: '/purchase/relationship', ...args,
  }),

  removeRelationship: (args) => API({
    method: 'del', endpoint: DOCUMENTS, path: '/purchase/relationship/delete', ...args,
  }),

  // /////////////////////////////////////////////////////////////////////INGESTA
  uploadDocs: (args) => API({
    method: 'post', endpoint: INGEST, path: '/feed_data', ...args,
  }),

  // /////////////////////////////////////////////////////////////////////MANAGEMENT
  // USER
  userFetchUser: (args) => API({
    method: 'get', endpoint: MANAGEMENT, path: '/user/retrieve', ...args, // OK
  }),
  userFetchUserRFCs: (args) => API({
    method: 'get', endpoint: MANAGEMENT, path: '/user/retrieve/rfcs', ...args, // OK
  }),
  userFetchUserProviders: (args) => API({
    method: 'get', endpoint: MANAGEMENT, path: '/user/retrieve/providers', ...args, // OK
  }),
  userAddUserProviders: (args) => API({
    method: 'post', endpoint: MANAGEMENT, path: '/user/add/providers', ...args, // OK
  }),
  userRemoveUserProviders: (args) => API({
    method: 'del', endpoint: MANAGEMENT, path: '/user/remove/providers', ...args, // OK
  }),
  userFetchUserProfiles: (args) => API({
    method: 'get', endpoint: MANAGEMENT, path: '/user/list/preProfiles', ...args,
  }),
  userFetchNotifications: (args) => API({
    method: 'get', endpoint: MANAGEMENT, path: '/user/retrieve/notifications', ...args, // OK
  }),

  // USER AREAS
  userFetchAreas: (args) => API({
    method: 'get', endpoint: MANAGEMENT, path: '/user/retrieve/areas', ...args, // OK
  }),
  userAddAreas: (args) => API({
    method: 'post', endpoint: MANAGEMENT, path: '/user/add/areas', ...args, // OK
  }),
  userUpdateAreas: (args) => API({
    method: 'put', endpoint: MANAGEMENT, path: '/user/update/areas', ...args, // OK
  }),
  userRemoveAreas: (args) => API({
    method: 'del', endpoint: MANAGEMENT, path: '/user/remove/areas', ...args, // OK
  }),

  // USER PROFILES
  userFetchProfiles: (args) => API({
    method: 'get', endpoint: MANAGEMENT, path: '/user/retrieve/profiles', ...args, // OK
  }),
  userAddProfiles: (args) => API({
    method: 'post', endpoint: MANAGEMENT, path: '/user/add/profiles', ...args, // OK
  }),
  userUpdateProfiles: (args) => API({
    method: 'put', endpoint: MANAGEMENT, path: '/user/update/profiles', ...args, // OK
  }),
  userRemoveProfiles: (args) => API({
    method: 'del', endpoint: MANAGEMENT, path: '/user/remove/profiles', ...args, // OK
  }),

  // USER USERS
  userFetchUsers: (args) => API({
    method: 'get', endpoint: MANAGEMENT, path: '/user/retrieve/users', ...args, // OK
  }),
  userAddUsers: (args) => API({
    method: 'post', endpoint: MANAGEMENT, path: '/user/add/users', ...args, // OK
  }),
  userUpdateUsers: (args) => API({
    method: 'put', endpoint: MANAGEMENT, path: '/user/update/users', ...args, // OK
  }),
  userUpdateUsersStatus: (args) => API({
    method: 'put', endpoint: MANAGEMENT, path: '/user/update/users/status', ...args, // OK
  }),
  userRemoveUsers: (args) => API({
    method: 'del', endpoint: MANAGEMENT, path: '/user/remove/users', ...args, // OK
  }),

  // ENTITY
  userFetchEntity: (args) => API({
    method: 'get', endpoint: MANAGEMENT, path: '/user/retrieve/entity', ...args, // OK
  }),
  userUpdateEntity: (args) => API({
    method: 'put', endpoint: MANAGEMENT, path: '/user/update/entity', ...args, // OK
  }),
  userUpdateEntityStatus: (args) => API({
    method: 'put', endpoint: MANAGEMENT, path: '/user/update/entity/status', ...args, // OK
  }),

  // SYSTEM
  userAddPreInfo: (args) => API({
    method: 'post', endpoint: MANAGEMENT, path: '/entity/add/preInfo', ...args, // CHECK
  }),
  userFetchSystemPreProfiles: (args) => API({
    method: 'get', endpoint: MANAGEMENT, path: '/user/list/preProfiles', ...args, // CHECK
  }),
  userFetchSystemPreRules: (args) => API({
    method: 'get', endpoint: MANAGEMENT, path: '/user/list/preRules', ...args, // CHECK
  }),
  userFetchSystemPreRoles: (args) => API({
    method: 'get', endpoint: MANAGEMENT, path: '/user/list/preRoles', ...args, // CHECK
  }),
  userFetchSystemZip: (args) => API({
    method: 'get', endpoint: MANAGEMENT, path: '/user/retrieve/zip', ...args, // OK
  }),
  userFetchSystemDocument: (args) => API({
    method: 'get', endpoint: MANAGEMENT, path: '/user/retrieve/document', ...args, // OK
  }),
  userFetchSystemMassiveDocuments: (args) => API({
    method: 'get', endpoint: MANAGEMENT, path: '/user/retrieve/massive/zip', ...args, // CHECK
  }),
});
