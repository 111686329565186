import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #eee',
  },
  indicator: {
    backgroundColor: '#FFFFFF',
    borderBottom: '2px solid #5d5386 ',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      'Montserrat', 'sans-serif',
    ].join(','),
    '&:hover': {
      color: '#97bf3d',
      opacity: 1,
    },
    '&$selected': {
      color: '#97bf3d',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#97bf3d',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export { AntTab, AntTabs };
