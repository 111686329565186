import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useI18n } from 'services/i18n';
import PropTypes from 'prop-types';
import { retentionPropTypes, transferPropTypes } from 'helpers/propTypes';
import TabPanel from './TabPanel';
import { AntTab, AntTabs } from './AntTab';

import Transfer from './Transfer';
import Retention from './Retention';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
}));

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

export default function CustomizedTabs({ kpiReport }) {
  const classes = useStyles();
  const t = useI18n();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <AntTabs
          value={value}
          onChange={handleChange}
          centered
          aria-label="ant example"
        >
          <AntTab label={t('transfer')} {...a11yProps(0)} />
          <AntTab label={t('retention')} {...a11yProps(1)} />
        </AntTabs>
        <TabPanel value={value} index={0}>
          <Transfer transfer={kpiReport?.traslado} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Retention retention={kpiReport?.retencion} />
        </TabPanel>
      </div>
    </div>
  );
}

CustomizedTabs.propTypes = {
  kpiReport: PropTypes.shape({
    traslado: PropTypes.shape(transferPropTypes),
    retencion: PropTypes.shape(retentionPropTypes),
  }),
};
