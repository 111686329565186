import React from 'react';
import { useTheme } from 'styled-components';
import Modal from '@material-ui/core/Modal';

import {
  Box,
  Spinner,
  LogoInbox,
} from 'ux-thebackyard';

const LoginSpinner = () => {
  const theme = useTheme();
  return (
    <Modal open>
      <Box bg="rgba(255,255,255,0.5)" centeredContent flexDirection="column" width="100%" height="100%">
        <LogoInbox type="vertical" alt="spinnerLogo" width="100px" marginBottom="15px" />
        <Spinner size="4rem" width="6px" color={theme.colors.inboxSpinner} />
      </Box>
    </Modal>
  );
};

export default LoginSpinner;
