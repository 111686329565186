import React, { createContext } from 'react';
import { IntlProvider } from 'react-intl-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

export const ServiceContext = createContext();

const ServiceProvider = ({ children }) => {
  const intl = useIntl();

  const value = (id, opts = {}) => {
    if (!id) return null;
    const { type, defaultMessage = '', ...options } = opts;
    return intl.formatMessage({ id, defaultMessage }, options);
  };

  return (
    <ServiceContext.Provider value={value}>
      {children}
    </ServiceContext.Provider>
  );
};

ServiceProvider.propTypes = {
  children: PropTypes.node,
};

export const I18nProvider = ({ children }) => (
  <IntlProvider>
    <ServiceProvider>
      {children}
    </ServiceProvider>
  </IntlProvider>
);

I18nProvider.propTypes = {
  children: PropTypes.node,
};
