import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import Layout from 'components/layout';

import Profile, { PATH as pathProfile } from 'pages/profile';
import AuthorizedRoute from 'components/authorized-route';
import { useHasRoles, hasSome } from 'services/hasRoles';

import Home, { PATH as pathHome } from 'pages/home';
import Reports, { PATH as pathReports } from 'pages/reports';
import CFDIs, { PATH as pathCFDIs } from 'pages/cfdis';
import UsersRegistration, { PATH as pathUserRegistration } from 'pages/users-registration';
import PaymentComplements, { PATH as pathPaymentComplements } from 'pages/payment-complements';
import PurchaseOrders, { PATH as pathPurchaseOrders } from 'pages/purchase-orders';

import SideBar from './SideBar';

import { PATH as parentPath, addBaseRoute } from '../constants';

const Provider = () => {
  const {
    document, reports, area, profile, user,
  } = useHasRoles();
  const handleRedirectToHome = () => <Redirect to={addBaseRoute(pathHome)} />;

  return (
    <Layout sideBar={SideBar}>
      <Switch>
        <Route exact path={parentPath} render={handleRedirectToHome} />
        <AuthorizedRoute
          hasAccess={hasSome(document) || hasSome(reports)}
          path={addBaseRoute(pathHome)}
          component={Home}
        />
        <AuthorizedRoute
          hasAccess={hasSome(document)}
          path={addBaseRoute(pathCFDIs)}
          component={CFDIs}
        />
        <AuthorizedRoute
          hasAccess={hasSome(document)}
          path={addBaseRoute(pathPaymentComplements)}
          component={PaymentComplements}
        />
        <AuthorizedRoute
          hasAccess={hasSome(document)}
          path={addBaseRoute(pathPurchaseOrders)}
          component={PurchaseOrders}
        />
        <AuthorizedRoute
          hasAccess={hasSome(reports)}
          path={addBaseRoute(pathReports)}
          component={Reports}
        />

        <AuthorizedRoute
          hasAccess={hasSome(user) || hasSome(area) || hasSome(profile)}
          path={addBaseRoute(pathUserRegistration)}
          component={UsersRegistration}
        />

        <Route path={addBaseRoute(pathProfile)} component={Profile} />
        <Route path="*" render={handleRedirectToHome} />
      </Switch>
    </Layout>
  );
};

export default Provider;
