import React from 'react';
import PropTypes from 'prop-types';
import { useI18n } from 'services/i18n';
import { docStatePropType } from 'helpers/propTypes';

import {
  DataBox,
  Box,
} from 'ux-thebackyard';

import StateRow from './StateRow';

const StateHistory = ({ data }) => {
  const t = useI18n();
  const { estado, validations } = data || {};

  return (
    <DataBox label={t('status_historial')} separator width="500px">
      <Box display="flex" flexDirection="column-reverse">
        {estado?.map((state, index) => (
          <StateRow
            key={index}
            validations={validations}
            state={state}
            isLast={index === estado.length - 1}
          />
        ))}
      </Box>
    </DataBox>
  );
};

StateHistory.propTypes = {
  data: PropTypes.shape({
    estado: PropTypes.arrayOf(docStatePropType),
  }),
};
export default StateHistory;
