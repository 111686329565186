import {
  every,
  isUndefined,
  isString,
  isBoolean,
  filter,
} from 'lodash';

// This exception convert to string before to compare,
export const ExceptionNumberToString = (value, matcher) => value.toString().toLowerCase().includes(matcher.toString().toLowerCase());

const matchValues = (key, value, matcher, exceptions) => {
  if (isUndefined(value) && isUndefined(matcher)) return true;
  if (isUndefined(value)) return true;
  if (isUndefined(matcher)) return true;

  if (exceptions && exceptions[key]) return exceptions[key](value, matcher);
  if (isBoolean(value) && isBoolean(matcher)) return value === matcher;
  if (isString(value) && isString(matcher)) return value.toLowerCase().includes(matcher.toLowerCase().trim());

  return false;
};

export default ({
  items,
  itemKeys,
  matcherItem,
  /**
   * exceptions is a object that has matching exception, and should be similar to:
   * const exceptions = {
   *    [key]: (itemValue, matcherValue) => logic to match itemvalue (item[key]) and  matcherValue (matcher[key]),
   *    ...
   * };
   */
  exceptions,
}) => (
  filter(items, (item) => every(itemKeys, (key) => matchValues(key, item[key], matcherItem[key], exceptions)))
);
