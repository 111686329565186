import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useI18n } from 'services/i18n';

import useSelectors from 'helpers/useSelectors';
import {
  actions as docsActions,
  selectors as docsSelectors,
} from 'store/documents';
import { useHasRoles } from 'services/hasRoles';
import { selectors as userSelectors, actions as userActions } from 'store/user';
import {
  purchaseOrderStates,
  paramsToFetchPurchaseOrders,
  ableParamsToFetch,
} from 'own/contants';
import useSearch from 'services/search/useSearch';
import { useModal } from 'services/modal';
import {
  SvgPlusSolid,
  SvgSyncSolid,
} from 'react-line-awesome-svg';

import NewPurchaseOrder from 'scenes/modals/new-purchase-order';

import {
  TableBox,
  PageInbox,
  Accordion,
  DataBox,
  FormInbox,
  FormInboxFieldTypes,
} from 'ux-thebackyard';
import PurchaseOrdersClientTrans from 'own/table-transmuters/purchase-orders-client';
import PurchaseOrdersProviderTrans from 'own/table-transmuters/purchase-orders-provider';

const valuesPurchaseOrderStates = Object.values(purchaseOrderStates);

const PurchaseOrders = () => {
  const dispatch = useDispatch();
  const t = useI18n();
  const { search, params, updateParams } = useSearch();

  const { renderComponent } = useModal();
  const [
    rfcsIssuer,
    rfcsReceiver,
    purchaseOrders,
    isBusy,
    pagination,

    isClient,
  ] = useSelectors(
    userSelectors.fetchRFCs.getRFCsIssuer,
    userSelectors.fetchRFCs.getRFCsReceiver,
    docsSelectors.getDocs1,
    docsSelectors.getDocs1IsBusy,
    docsSelectors.getDocs1Pagination,

    userSelectors.fetchUser.getIsClient,
  );
  const tableTrans = isClient ? PurchaseOrdersClientTrans : PurchaseOrdersProviderTrans;

  const FetchDocs = () => dispatch(docsActions.FetchDocs1({
    ...params,
    ...paramsToFetchPurchaseOrders,
  }));

  const handleChangePage = (pageT) => updateParams({ page: pageT });
  const handleOpenNewPurchase = () => renderComponent(NewPurchaseOrder, { hideFooter: true });

  useEffect(() => {
    if (!(rfcsReceiver || rfcsIssuer)) dispatch(userActions.fetchRFCs.Call());
  }, [rfcsIssuer, rfcsReceiver]);

  useEffect(() => {
    FetchDocs();
  }, [search]);

  const formConfig = [
    {
      label: t('total'),
      fieldType: FormInboxFieldTypes.NUMERIC_RANGE,
      fieldNameStartValue: ableParamsToFetch.TOTAL_IN,
      fieldNameEndValue: ableParamsToFetch.TOTAL_END,
    },
    {
      label: t('document_number'),
      fieldType: FormInboxFieldTypes.INPUT,
      type: 'number',
      fieldName: ableParamsToFetch.PURCHASE_ORDER,
    },
    {
      label: t('date'),
      fieldType: FormInboxFieldTypes.DATE_RANGE,
      fieldNameStartDate: ableParamsToFetch.DATE_IN,
      fieldNameEndDate: ableParamsToFetch.DATE_END,
    },
    {
      label: t('issuing_rfc'),
      enableSearch: true,
      items: rfcsIssuer,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableParamsToFetch.RFC_ISSUER,
    },
    {
      label: t('receiver_rfc'),
      enableSearch: true,
      items: rfcsReceiver,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableParamsToFetch.RFC_RECEIVER,
    },
    {
      label: t('state'),
      enableSearch: true,
      items: valuesPurchaseOrderStates,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableParamsToFetch.STATE,
    },
  ];

  const { document: { full, write } } = useHasRoles();
  const headBtnsProps = [
    isClient && (full || write) && {
      label: t('new'), design: 'primary', svg: SvgPlusSolid, onClick: handleOpenNewPurchase,
    },
    {
      label: t('update'), design: 'shadow', svg: SvgSyncSolid, rounded: true, onClick: FetchDocs,
    },
  ];

  return (
    <PageInbox label={t('purchase_orders')}>
      <DataBox
        label={t('purchase_order')}
        headBtnsProps={headBtnsProps}
        separator
      >
        <Accordion label={t('filter_search')}>
          <FormInbox
            items={formConfig}
            value={params}
            onSubmit={updateParams}
            submitBtnProps={{ label: t('apply') }}
          />
        </Accordion>

        <TableBox
          transmuter={tableTrans.transmuter}
          heads={tableTrans.heads}
          data={purchaseOrders}
          isBusy={isBusy}
          height="calc(100vh - 430px)"
          noFoundMessage={t('no_purchase_orders_found')}
          pagination={pagination && {
            page: pagination?.page,
            totalPages: pagination?.totalPages,
            totalItems: pagination?.totalDocs,
            pageSize: 50, // TODO
          }}
          onChangePage={handleChangePage}
          stickyHeaders
        />

      </DataBox>
    </PageInbox>

  );
};

export default PurchaseOrders;
