import { addMonths } from 'date-fns';

export const cfdiTypes = {
  I: 'I',
  E: 'E',
  N: 'N',
  T: 'T',
  P: 'P',
};

export const docsTypes = {
  ...cfdiTypes,
  PURCHASE: 'purchase',
};

export const typeDocToName = { // TODO traslate in the future
  [docsTypes.I]: 'Ingreso',
  [docsTypes.E]: 'Egreso',
  [docsTypes.N]: 'Nómina',
  [docsTypes.T]: 'Traslado',
  [docsTypes.P]: 'Comprobante de Pago',
  [docsTypes.PURCHASE]: 'Orden de Compra',
};

export const cfdiStates = {
  RECEIVED: 'Recibido',
  VALID: 'Válido',
  ERROR: 'Con Error',
  VALID_WITH_PURCHASE_ORDER: 'Valido con Orden de Compra',
  PAID: 'Pagado',
  CANCELLED: 'Cancelado',
};

export const purchaseOrderStates = {
  COMPLETED: 'Completada',
  NO_ATTENDED: 'NoAtendida',
  PARTIAL_ATTENDED: 'Parcial',
};

export const pageSize = 50;

export const ableParamsToFetchDocs = {
  TYPE: 'type',
  UUID: 'uuid',
  RFC_ISSUER: 'rfcIssuer',
  RFC_RECEIVER: 'rfcReceiver',
  DATE_END: 'dateEnd',
  DATE_IN: 'dateIn',
  FOLIO: 'folio',
  PAGE: 'page',
  PAGE_SIZE: 'pageSize',
  PURCHASE_ORDER: 'noOrdenC',
  TOTAL_IN: 'totalIn',
  TOTAL_END: 'totalEnd',
  KEY_SORT: 'keySort', // key (query param) used to sort (default: date)
  ACED_NECK: 'acedNeck', // to sort descendent or ascendent (1 or -1)
  STATE: 'status',
  SERIE: 'serie',
  ID_ORDEN_C: 'idOrdenC',
};

export const ableParamsToFetchReports = {
  REPORT_STATUS: 'reportStatus',
  REPORT_CFDI: 'reportCfdi',
  REPORT_PURCHASE: 'reportPurch',
  REPORT_KPI: 'reportKpi',
  TYPE: 'type',
  UUID: 'uuid',
  RFC_ISSUER: 'rfcIssuer',
  RFC_RECEIVER: 'rfcReceiver',
  DATE_END: 'dateEnd',
  DATE_IN: 'dateIn',
  FOLIO: 'folio',
};

export const ableParamsToFetch = {
  ...ableParamsToFetchDocs,
  ...ableParamsToFetchReports,
};

export const ablePurchaseOrderFields = {
  PURCHASE_ORDER: 'noOrdenC',
  RFC_ISSUER: 'rfcIssuer',
  RFC_RECEIVER: 'rfcReceiver',
  TOTAL: 'total',
  DATE: 'date',
  STATE: 'estado',
  PATH: 'path',
  TYPE: 'type',
  CFDI: 'cfdi',
};

export const ableProfileFields = {
  PROFILE_NAME: 'profileName',
  PROFILE_AREAS: 'profileAreas',
  PROFILE_ROLES: 'profileRoles',
  PROFILE_RULES: 'profileRules',
  ID: '_id',
};

export const ableUserFields = {
  NAME: 'name',
  USER_PROFILE: 'userProfile',
  USER_EMAIL: 'userEmail',
  USER_TYPE: 'userType',
  ENTITY_ID: 'entityId',
  ID: '_id',
  ACTIVE: 'active',
};

export const ableProvidersFields = {
  ENTITY_NAME: 'entityName',
  RFC_LIST: 'rfcsList',
  ADMIN_USER: 'adminUser',
};

export const ableAreaFields = {
  AREA_NAME: 'areaName',
  AREA_RFCS: 'areaRfcs',
  MUTABLE: 'mutable',
  ACTIVE: 'active',
  ID: '_id',
};

export const ableRuleFields = {
  RULE_TYPE: 'ruleType',
  RULE_OBJECT: 'ruleObject',
  RULE_VALUE: 'ruleValue',
  RULE_OPERATOR: 'ruleOperator',
  ID: '_id',
};

export const ableRfcsFields = {
  CODE: 'code',
  COMPANY: 'company',
};

// PARAMS to FETCH
export const paramsToFetchStatusReport = {
  [ableParamsToFetchReports.REPORT_STATUS]: 1,
};

export const paramsToFetchCFDIReport = {
  [ableParamsToFetchReports.REPORT_CFDI]: 1,
};

export const paramsToFetchPurchaseReport = {
  [ableParamsToFetchReports.REPORT_PURCHASE]: 1,
  [ableParamsToFetchReports.TYPE]: docsTypes.PURCHASE,
};

export const paramsToFetchKPIReport = {
  [ableParamsToFetchReports.REPORT_KPI]: 1,
};

export const paramsToFetchPurchaseOrders = {
  [ableParamsToFetchDocs.TYPE]: docsTypes.PURCHASE,
};

export const getDefaultDates = () => {
  const today = new Date();
  const past = addMonths(today, -11);
  const newPast = new Date(past.getFullYear(), past.getMonth(), 1);
  return ({
    dateEnd: today.toISOString(),
    dateIn: newPast.toISOString(),
  });
};

export const userTypes = {
  CLIENT: 'CLIENT',
  PROVIDER: 'PROVIDER',
};

export const roles = {
  full: 'FULL',
  write: 'WRITE',
  update: 'UPDATE',
  remove: 'REMOVE',
  read: 'READ',
};

export const modifiers = {
  user: 'USER',
  provider: 'PROVIDER',
  profile: 'PROFILE',
  area: 'AREA',
  entity: 'ENTITY',
  rules: 'RULES',
  roles: 'ROLES',
  rfc: 'RFC',
  predefinedinfo: 'PREDEFINEDINFO',
  document: 'DOCUMENT',
  relationship: 'RELATIONSHIP',
  reports: 'REPORTS',
  sat: 'SAT',
  notification: 'NOTIFICATION',
};
