import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { styledSystem } from 'services/theme/helpers';

import {
  Box,
  Text,
  Input,
} from 'ux-thebackyard';

const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    margin-bottom: 14px;
    ${styledSystem};
`;

const Label = styled(Text)`
    margin-bottom: 7px;
`;

const CustomLabel = styled(Text)`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 1.5rem;
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 1.7;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      display: block;
      height: 1.5rem;
      padding: .375rem .75rem;
      line-height: 1.7;
      color: #495057;
      content: '${(props) => props.buttonText}';
      background-color: #e9ecef;
      border-left: inherit;
      border-radius: 0 .25rem .25rem 0;
}`;

const StyledInput = styled(Input)`
   padding: 0.5rem;
   border-radius: 4px;
   border: ${({ theme }) => `solid 1px ${theme.colors.inputBorder || '#000000'}`};
   color: ${({ theme }) => theme.colors.inputText || '#000000'};
   line-height: 1.25;
   transition: border-color .15s ease-in-out;
   position: relative;
   z-index: 2;
   width: 100%;
   height: calc(1.5rem);
   margin: 0;
   opacity: 0;
   &:focus {
       border-color:${({ theme }) => theme.colors.inputBorderFocus};
       outline: none;
   }
   &:disabled{
    cursor: not-allowed;
  };
`;

const FileInputContainer = styled(Box)`
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5rem);
    margin-bottom: 0;
    `;

const FileInput = forwardRef(({
  multiple, accept,
  placeholder, label, onChange, onFocus, onBlur, onClick, readOnly, disabled, ...remainderProps
}, ref) => (
  <Container {...remainderProps}>
    <Label fontSize="sm">{label}</Label>
    <FileInputContainer>
      <StyledInput
        type="file"
        readOnly={readOnly}
        accept={accept}
        multiple={multiple}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
        onClick={onClick}
        disabled={disabled}
      />
      <CustomLabel fontSize="sm" buttonText="Explorar">{placeholder}</CustomLabel>
    </FileInputContainer>
  </Container>
));

FileInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,

};

export default FileInput;
