import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import UnauthorizedPage from 'components/unauthorized-page';

const AuthorizedRoute = ({ hasAccess, component, ...props }) => (
  <Route {...{ ...props, component: hasAccess ? component : UnauthorizedPage }} />
);

AuthorizedRoute.propTypes = {
  hasAccess: PropTypes.bool,
  component: PropTypes.func,
};

export default AuthorizedRoute;
