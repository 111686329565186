import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatNumberToCurrency } from 'helpers/general';
import { Box } from 'ux-thebackyard';

const Card = styled(Box)`
  width: 100%;
  background-color: white;
  text-align: center;
`;

const CardHeader = styled(Box)`
  color:${(props) => props.color};
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1rem;
`;

const CardContent = styled(Box)`
  color:${(props) => props.color};  
`;

const MoneyCard = ({
  price, label, primaryTextColor, secondaryTextColor,
}) => (
  <Card>
    <CardHeader color={primaryTextColor}>
      {formatNumberToCurrency(price || 0)}
    </CardHeader>
    <CardContent
      color={secondaryTextColor}
    >
      {label}
    </CardContent>
  </Card>
);

MoneyCard.defaultProps = {
  price: 0,
};

MoneyCard.propTypes = {
  price: PropTypes.number,
  label: PropTypes.string.isRequired,
  primaryTextColor: PropTypes.string,
  secondaryTextColor: PropTypes.string,
};

export default MoneyCard;
