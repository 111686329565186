import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Box,
  Spinner,
} from 'ux-thebackyard';
import TotalAmountInvoices from './TotalAmountInvoice';
import TaxType from './tax-type';
import StatusPieChart from './StatusPieChart';

const SubContainer = styled(Box)`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const KPITotalsStatus = ({ kpiReport, statusReport, isBusy }) => {
  if (isBusy) {
    return (
      <Box centeredContent width="100%" height="20rem">
        <Spinner />
      </Box>
    );
  }

  return (
    <Box display="flex">
      <Box display="flex" flexDirection="column">
        <SubContainer>
          <TotalAmountInvoices total={kpiReport?.total || 0} />
        </SubContainer>
        <SubContainer flexGrow="1" mt="2rem">
          <TaxType kpiReport={kpiReport} />
        </SubContainer>
      </Box>

      <SubContainer flexGrow="1" ml="2rem">
        <StatusPieChart statusReport={statusReport} />
      </SubContainer>
    </Box>
  );
};

KPITotalsStatus.propTypes = {
  isBusy: PropTypes.bool,
  kpiReport: PropTypes.shape({
    total: PropTypes.number,
  }),
  statusReport: PropTypes.arrayOf(PropTypes.any),
};

export default KPITotalsStatus;
