import { call } from 'redux-saga/effects';
import RS from 'helpers/RS';
// import { ableParamsToFetch, docsTypes } from 'store/contants';

function* addPurchaseOrder(api, { payload: data }) {
  const params = {
    // [ableParamsToFetch.TYPE]: docsTypes.PURCHASE,
  };

  const response = yield call(api.insertDocument, { params, data });
  return response;
}

const addPurchaseOrderRS = RS({
  nestedPath: [],
  storeName: 'addPurchaseOrder',
  tryGenerator: addPurchaseOrder,
});

export const sagas = [
  addPurchaseOrderRS.sagas,
];

export const { reducer, selectors, actions } = addPurchaseOrderRS;
