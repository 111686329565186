import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useI18n } from 'services/i18n';
import useSelectors from 'helpers/useSelectors';
import { useModal } from 'services/modal';
import { some } from 'lodash';

import {
  DataBox,
  Button,
  FormInbox,
  FormInboxFieldTypes,
  FormInboxValidationType,
} from 'ux-thebackyard';

import {
  ableProvidersFields,
} from 'own/contants';

import { selectors as userSelectors, actions as userActions } from 'store/user';
import { selectors as usersSelectors, actions as usersActions } from 'store/users';

const NewProvider = () => {
  const [currentProvider, setCurrentProvider] = useState({});
  const [errorMatchWithUser, setErrorMatchWithUser] = useState(false);
  const [submitBtnProps, setSubmitBtnProps] = useState({});

  const dispatch = useDispatch();
  const t = useI18n();

  const { onClose: onModalClose, ModalFooter } = useModal();

  const [
    addProviderIsBusy,
    addProviderIsSuccess,

    users,
  ] = useSelectors(
    userSelectors.addProviders.getIsBusy,
    userSelectors.addProviders.getIsSuccess,

    usersSelectors.fetch.getData,
  );

  const handleAddProvider = (newProv) => dispatch(userActions.addProviders.Call(newProv));
  const handleOnChange = (newCurrentProvider) => {
    setCurrentProvider(newCurrentProvider);
  };

  const matchEmailWithCreatedUser = (newEmail) => ((users && newEmail) ? some(users, ({ userEmail }) => userEmail === newEmail) : false);

  useEffect(() => {
    setErrorMatchWithUser(matchEmailWithCreatedUser(currentProvider?.adminUser?.email));
  }, [currentProvider]);

  useEffect(() => {
    if (!users) dispatch(usersActions.fetch.Call());
  }, [users]);

  useEffect(() => {
    if (addProviderIsSuccess) {
      dispatch(userActions.fetchProviders.Call());
      onModalClose();
    }
  }, [addProviderIsSuccess]);

  const itemsForm = [
    {
      label: t('entity_name'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableProvidersFields.ENTITY_NAME,
      required: true,
      validations: [
        { type: FormInboxValidationType.REQUIRED },
      ],
    },
    {
      label: t('admin_name'),
      fieldType: FormInboxFieldTypes.INPUT_FIELD,
      fieldName: ableProvidersFields.ADMIN_USER,
      ownField: 'name',
      required: true,
      validations: [
        { type: FormInboxValidationType.REQUIRED },
      ],
    },
    {
      label: t('admin_email'),
      fieldType: FormInboxFieldTypes.INPUT_FIELD,
      fieldName: ableProvidersFields.ADMIN_USER,
      ownField: 'email',
      required: true,
      validations: [
        { type: FormInboxValidationType.REQUIRED },
        { type: FormInboxValidationType.EMAIL },
      ],
      errorMessage: errorMatchWithUser && t('email_exist_already'),
    },
    {
      label: t('rfcs'),
      fieldType: FormInboxFieldTypes.ADD_PAIR,
      fieldName: ableProvidersFields.RFC_LIST,
      dataLabel: 'Rfcs',
      primaryKey: 'code',
      secondaryKey: 'company',
      required: true,
      validations: [
        { type: FormInboxValidationType.REQUIRED },
      ],
    },
  ];

  return (
    <>
      <DataBox label={t('new_provider')} separator width="35rem">
        <FormInbox
          items={itemsForm}
          value={currentProvider}
          onChange={handleOnChange}
          onSubmit={handleAddProvider}
          changeExternSubmitBtnProps={setSubmitBtnProps}
        />
      </DataBox>
      <ModalFooter showSpinner={addProviderIsBusy}>
        <Button
          design="primary"
          rounded
          m="10px"
          p="12px 30px"
          onClick={handleAddProvider}
          label={t('upload')}
          {...submitBtnProps}
        />
      </ModalFooter>
    </>
  );
};

export default NewProvider;
