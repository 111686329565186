import * as actions from './actions';

const defaultState = {
  collapsed: true,
};

const reducer = (stateP = defaultState, action = {}) => {
  const state = { ...stateP };

  switch (action.type) {
    case actions.COLLAPSE:
      return {
        ...state,
        collapsed: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
