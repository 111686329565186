import React from 'react';
import {
  ThemeProvider,
  createGlobalStyle,
} from 'styled-components';
import theme from './default-theme';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.default.fontFamily};
    background-color: ${(props) => props.theme.default.backgroundColor};
    margin:0;
  }
`;

// eslint-disable-next-line react/prop-types
export default ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
);
