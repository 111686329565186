import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHasRoles } from 'services/hasRoles';
import { useModal } from 'services/modal';
import { useSelector, useDispatch } from 'react-redux';
import useSelectors from 'helpers/useSelectors';
import NewUpdateProfile from 'scenes/modals/new-update-profile';
import { actions as profilesActions, selectors as profilesSelectors } from 'store/profiles';
import { selectors as usersSelectors } from 'store/users';

import { isUndefined } from 'lodash';
import Confirm from 'scenes/modals/confirm';
import { ableProfileFields } from 'own/contants';

import { TableActionsInbox, TableActionsInboxTypes } from 'ux-thebackyard';
import Translate from 'components/translate';

const RemoveProfileConfirm = ({ profile }) => {
  const dispatch = useDispatch();
  const { onClose } = useModal();

  const [
    isBusyRemoveProfile,
    isSuccessRemoveProfile,
    isFailedRemoveProfile,
  ] = useSelectors(
    profilesSelectors.remove.getIsBusy,
    profilesSelectors.remove.getIsSuccess,
    profilesSelectors.remove.getIsFailed,
  );

  useEffect(() => () => dispatch(profilesActions.remove.NoStarted()), []);

  useEffect(() => {
    if (isSuccessRemoveProfile) {
      onClose();
      dispatch(profilesActions.fetch.Call());
    }
  }, [isSuccessRemoveProfile]);

  // eslint-disable-next-line no-underscore-dangle
  const handleOnConfirmRemoveProfile = () => dispatch(profilesActions.remove.Call(profile._id));

  return (
    <Confirm
      title="remove_profile_confirm_title"
      message="remove_profile_confirm_message"
      errorMessage={isFailedRemoveProfile && 'remove_profile_error_message'}
      isBusy={isBusyRemoveProfile}
      onConfirm={handleOnConfirmRemoveProfile}
    />
  );
};

RemoveProfileConfirm.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any),
};

const Actions = ({ data = {} }) => {
  const { renderComponent } = useModal();

  const NewUpdateProfileWrapper = () => <NewUpdateProfile profile={data} />;
  const handleShowModalUpdateProfile = () => renderComponent(NewUpdateProfileWrapper, { hideFooter: true });

  const RemoveAreaConfirmWrapper = () => <RemoveProfileConfirm profile={data} />;
  const handleShowConfirmRemoveProfile = () => renderComponent(RemoveAreaConfirmWrapper, { hideFooter: true });

  const { profile: { full, remove, update } } = useHasRoles();

  const actions = [
    (update || full) && { type: TableActionsInboxTypes.EDIT, onClick: handleShowModalUpdateProfile },
    (remove || full) && { type: TableActionsInboxTypes.DELETE, onClick: handleShowConfirmRemoveProfile },
  ];

  return (
    <TableActionsInbox actions={actions} />
  );
};

const heads = [
  'actions',
  'profile_name',
  'amount_of_users',
  'amount_of_rules',
];

const UserAmount = ({ profile }) => {
  const users = useSelector(usersSelectors.fetch.getData);
  const userAmount = users?.filter((user) => user?.userProfile?.profileName === profile?.profileName).length;
  return isUndefined(userAmount) ? null : userAmount;
};

const transmuter = (data) => {
  const {
    [ableProfileFields.PROFILE_NAME]: profileName,
    [ableProfileFields.PROFILE_RULES]: profileRules,
  } = data;

  return [
    <Actions data={data} />,
    profileName,
    <UserAmount profile={data} />,
    profileRules?.length || 0,
  ];
};

export default {
  heads: heads?.map((head) => <Translate label={head} />),
  transmuter,
};
