import { put } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import RS from 'helpers/RS';
import { actions as userActions, selectors as userSelectors } from 'store/user';

const SUCCESS_STATE = 'DATA_WAS_FETCHED';

function* startUp() {
  yield put(userActions.fetchUser.Call());

  yield put(userActions.fetchNotifications.Call()); // TODO uncomment after api deploy
  return SUCCESS_STATE;
}

const startUpRS = RS({
  nestedPath: [],
  storeName: 'startUp',
  tryGenerator: startUp,
});

export const sagas = [
  startUpRS.sagas,
];

export const { reducer, actions } = startUpRS;

const getIsFailed = createSelector(
  userSelectors.fetchUser.getIsFailed,
  (isFailed) => isFailed,
);

const getHasData = createSelector(
  userSelectors.fetchUser.getData,
  (user) => !!user,
);

const { getIsBusy } = startUpRS.selectors;

export const selectors = {
  getIsBusy,
  getIsFailed,
  getHasData,
};
