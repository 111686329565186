import {
  cfdiTypes,
  typeDocToName,
  cfdiStates,
} from 'own/contants';

export const typeDocNameItemsDropdownSelect = Object.values(cfdiTypes).map((type) => ({
  name: typeDocToName[type],
  type,
}));

export const cfdiStatesValues = Object.values(cfdiStates);
