import React, { useEffect, useState } from 'react';
import { useI18n } from 'services/i18n';

import useSearch from 'services/search/useSearch';
import { useModal } from 'services/modal';
import { useHasRoles } from 'services/hasRoles';
import NewUpdateProfile from 'scenes/modals/new-update-profile';
import filter from 'helpers/filter';

import { useDispatch } from 'react-redux';
import { ableProfileFields } from 'own/contants';
import { SvgPlusSolid, SvgSyncSolid } from 'react-line-awesome-svg';
import { actions as profilesActions, selectors as profilesSelectors } from 'store/profiles';

import useSelectors from 'helpers/useSelectors';

import {
  Accordion,
  DataBox,
  TableBox,
  FormInbox,
  FormInboxFieldTypes,
} from 'ux-thebackyard';
import ManagementProfile from 'own/table-transmuters/management-profile';

import { roles } from '../constants';

const role = roles[1];

const valuesAbleProfileFields = Object.values(ableProfileFields);

const ProfileManagement = () => {
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const t = useI18n();

  const dispatch = useDispatch();
  const { params: { [role]: params = {} }, updateParams: updateParamsT } = useSearch();
  const updateParams = (p) => updateParamsT(p, role);

  const { renderComponent } = useModal();

  const [
    profiles,
    profileIsBusy,
  ] = useSelectors(
    profilesSelectors.fetch.getData,
    profilesSelectors.fetch.getIsBusy,
  );

  const handleShowModalNewProfile = () => renderComponent(NewUpdateProfile, { hideFooter: true });
  const handleFetchProfiles = () => dispatch(profilesActions.fetch.Call());

  const filterProfile = () => (
    setFilteredProfiles(filter({
      items: profiles,
      itemKeys: valuesAbleProfileFields,
      matcherItem: params,
    }))
  );

  const { profile: { full, update } } = useHasRoles();
  const headBtnsProps = [
    (update || full) && {
      label: t('new'), design: 'primary', svg: SvgPlusSolid, onClick: handleShowModalNewProfile,
    },
    {
      label: t('update'), design: 'shadow', svg: SvgSyncSolid, rounded: true, onClick: handleFetchProfiles,
    },
  ];

  const formConfig = [
    {
      label: t('profile_name'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableProfileFields.PROFILE_NAME,
    },
  ];

  useEffect(() => {
    if (!profiles) handleFetchProfiles();
  }, [profiles]);

  useEffect(() => {
    filterProfile();
  }, [profiles, params]);

  return (
    <DataBox label={t('profile_management')} separator headBtnsProps={headBtnsProps}>
      <Accordion label={t('filter_search')}>
        <FormInbox
          items={formConfig}
          value={params}
          onChange={updateParams}
          submitBtnProps={{ display: 'none' }}
        />
      </Accordion>

      <TableBox
        transmuter={ManagementProfile.transmuter}
        heads={ManagementProfile.heads}
        data={filteredProfiles}
        isBusy={profileIsBusy}
        noFoundMessage={t('no_profiles_found')}
        stickyHeaders
      />

    </DataBox>
  );
};

export default ProfileManagement;
