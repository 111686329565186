import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useSelectors from 'helpers/useSelectors';
import { useI18n } from 'services/i18n';
import useSearch from 'services/search/useSearch';

import { selectors as userSelectors, actions as userActions } from 'store/user';
import DocsUploader from 'components/docs-uploader';

import { PageInbox, DataBox } from 'ux-thebackyard';

import { roles } from '../constants';
import CFDIsTable from './CFDIsTable';
import PCsTable from './PCsTable';

const CFDIS = () => {
  const t = useI18n();
  const dispatch = useDispatch();
  const { createRoles } = useSearch();

  const [
    rfcs,
    isClient,
  ] = useSelectors(
    userSelectors.fetchRFCs.getData,
    userSelectors.fetchUser.getIsClient,
  );

  useEffect(() => {
    createRoles(roles);
  }, []);

  useEffect(() => {
    if (!rfcs) dispatch(userActions.fetchRFCs.Call());
  }, [rfcs]);

  return (
    <PageInbox label={t('cfdis')}>
      {!isClient && (
        <DataBox label={t('upload_documents')} separator>
          <DocsUploader />
        </DataBox>
      )}
      <CFDIsTable />
      {isClient && <PCsTable />}
    </PageInbox>
  );
};

export default CFDIS;
