import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useI18n } from 'services/i18n';
import styled from 'styled-components';
import { useModal } from 'services/modal';
import useSelectors from 'helpers/useSelectors';
import { selectors as userSelectors } from 'store/user';
import { selectors as entitySelectors, actions as entityActions } from 'store/entity';

import FormInputGroup from 'components/form-input-group';
import ChangePassword from 'scenes/modals/change-password';

import {
  DataBox,
  Box,
  Button,
  Spinner,
  TableBox,
} from 'ux-thebackyard';
import AreasInProfilePageTrans from 'own/table-transmuters/areas-in-profile-page';
import { Grid } from '@material-ui/core';

const Header = styled.h4`
    color: ${({ theme }) => theme.colors.primaryText};
    font-weight: 600;
    margin-bottom: 0;
    font-size: 1.2rem;
    margin-top: 0;
`;

const EditableInfo = (props) => {
  const t = useI18n();
  const dispatch = useDispatch();
  const { renderComponent } = useModal();

  const [
    userName,
    userEmail,
    userProfile,

    isBusyFetchEntity,
    entity,
  ] = useSelectors(
    userSelectors.fetchUser.getUserName,
    userSelectors.fetchUser.getUserEmail,
    userSelectors.fetchUser.getUserProfile,

    entitySelectors.fetch.getIsBusy,
    entitySelectors.fetch.getData,
  );
  const { profileName, profileAreas, profileRoles } = userProfile || {};

  const { entityName, entityType } = entity || {};

  useEffect(() => {
    if (!entity) dispatch(entityActions.fetch.Call());
  }, [entity]);

  const handleShowChangePassword = () => renderComponent(ChangePassword, { hideFooter: true });

  return (
    <DataBox separator label={t('account_config')} {...props}>
      <Box padding="1.4rem">
        <Header>{t('personal_data')}</Header>

        <Box marginTop="15px">
          <FormInputGroup
            readOnly
            label={t('name')}
            value={userName}
          />
          <FormInputGroup
            readOnly
            label={t('email')}
            value={userEmail}
          />
          <Box marginTop=".5rem">
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={10}>
                <Button
                  design="primary"
                  ml="auto"
                  onClick={handleShowChangePassword}
                  label={t('change_password')}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box padding="1.4rem">
        <Box display="flex">
          <Header>{t('tax_data')}</Header>
          {isBusyFetchEntity && <Spinner ml="2rem" size="1.5rem" width="5px" />}
        </Box>

        <Box marginTop="15px">
          <FormInputGroup
            readOnly
            label={t('entity_name')}
            value={entityName}
          />
          <FormInputGroup
            readOnly
            label={t('entity_type')}
            value={entityType}
          />
          <TableBox
            mt="2rem"
            transmuter={AreasInProfilePageTrans.transmuter}
            heads={AreasInProfilePageTrans.heads}
            data={profileAreas}
            stickyHeaders
          />
        </Box>

      </Box>
      <Box padding="1.4rem">
        <Header>{t('admin_data')}</Header>

        <Box marginTop="15px">
          <FormInputGroup
            readOnly
            label={t('profile_name')}
            value={profileName}
          />
          <FormInputGroup
            style={{ resize: 'none', height: '5rem' }}
            as="textarea"
            readOnly
            label={t('roles')}
            value={profileRoles?.join('\n')}
          />
        </Box>
      </Box>
    </DataBox>
  );
};

export default EditableInfo;
