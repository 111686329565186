import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useSelectors from 'helpers/useSelectors';
import { Redirect } from 'react-router';
import { PATH as loginPath } from 'scenes/login';

import { selectors as userSelectors } from 'store/user';
import { actions as startUpActions, selectors as startUpSelectors } from 'store/start-up';
import { actions as authActions, selectors as authSelectors } from 'store/auth';

import { userTypeToRoute } from 'helpers/redirect';
import { useI18n } from 'services/i18n';
import { useTheme } from 'styled-components';

import {
  DataBox,
  Spinner,
  Box,
  Text,
  Button,
  FormInbox,
  FormInboxFieldTypes,
  FormInboxValidationType,
} from 'ux-thebackyard';

/**
 * TODO. incluir la validacion de que las contraseñas sean las mismas, y poder ver las contraseñas en formato text opcionalmente
 */

const StartUp = () => {
  const [state, setState] = useState({});
  const [failed, setFailed] = useState(false);
  const dispatch = useDispatch();
  const t = useI18n();
  const theme = useTheme();

  const [
    hasData,
    isFailedStartUp,

    authData,
    isBusyNewPassword,
    isFailedNewPassword,
    errorMessageNewPassword,

    userType,
  ] = useSelectors(
    startUpSelectors.getHasData,
    startUpSelectors.getIsFailed,

    authSelectors.signIn.getData,
    authSelectors.completeNewPassword.getIsBusy,
    authSelectors.completeNewPassword.getIsFailed,
    authSelectors.completeNewPassword.getErrorMessage,

    userSelectors.fetchUser.getUserType,
  );

  const needNewPassword = authData?.challengeName === 'NEW_PASSWORD_REQUIRED';

  const handleOnSubmit = () => {
    dispatch(authActions.completeNewPassword.Call(state.newPassword));
  };

  useEffect(() => {
    if (!needNewPassword) dispatch(startUpActions.Call());
  }, [needNewPassword]);

  const handleFailedStartUP = () => {
    setFailed(true);
    setTimeout(() => dispatch(authActions.signOut.Call()), 5000);
  };

  useEffect(() => {
    if (isFailedStartUp) handleFailedStartUP();
  }, [isFailedStartUp]);

  if (!authData) return <Redirect to={loginPath} />;

  if (hasData && userType && !needNewPassword) return <Redirect to={userTypeToRoute(userType)} />;

  const itemsForm = [
    {
      label: t('new_password'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: 'newPassword',
      type: 'password',
      width: '100%',
      required: true,
      validations: [
        { type: FormInboxValidationType.REQUIRED },
      ],
    },
    {
      label: t('confirm_password'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: 'confirmPassword',
      type: 'password',
      width: '100%',
      required: true,
      validations: [
        { type: FormInboxValidationType.REQUIRED },
      ],
    },
  ];

  const formToChangePassword = (
    <DataBox
      label={t('change_password')}
      separator
      height="363px"
      borderRadius="8px"
      width="30rem"
    >
      <FormInbox
        items={itemsForm}
        value={state}
        onChange={setState}
      />
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        {isBusyNewPassword && <Spinner size="3rem" width="5px" />}
        {isFailedNewPassword && <Text color="red">{errorMessageNewPassword?.message}</Text>}
        <Button
          design="primary"
          rounded
          m="10px"
          p="12px 30px"
          onClick={handleOnSubmit}
          label={t('save')}
        />
      </Box>
    </DataBox>
  );

  const fetchingStartUpData = (
    <>
      {failed && (
        <DataBox
          label="Notificacion" // TODO translate
          separator
          width="20rem"
          height="20rem"
          borderRadius="8px"
        >
          <Box centeredContent height="100%">
            <Text>{t('problem_loading_start_up_data')}</Text>
          </Box>
        </DataBox>
      )}
      {!isFailedStartUp && (
        <Box centeredContent flexDirection="column">
          <Text
            marginBottom="10px"
            fontWeight="bld"
            color={theme.colors.inboxSpinner}
            fontSize="2rem"
          >
            {t('loading_start_up')}
          </Text>
          <Spinner color={theme.colors.inboxSpinner} />
        </Box>
      )}
    </>
  );

  return (
    <Box centeredContent width="100vw" height="100vh">
      {needNewPassword ? formToChangePassword : fetchingStartUpData }
    </Box>
  );
};

export default StartUp;
