import html2pdf from 'html2pdf.js';

const defaultOptions = {
  margin: 1,
  image: { type: 'jpeg', quality: 0.98 },
  pagebreak: {
    before: '.page',
  },
  html2canvas: { scale: 2 },
  jsPDF: { unit: 'in', format: 'tabloid', orientation: 'l' },
};

export const downloadPdf = (element, filename, options = {}) => {
  const opt = {
    ...defaultOptions,
    filename: filename.endsWith('pdf') ? filename : `${filename}.pdf`,
    ...options,
  };

  html2pdf().set(opt).from(element).save();
};

export const getPdfAsBase64 = (element, filename, options = {}) => {
  const opt = {
    ...defaultOptions,
    filename: filename.endsWith('pdf') ? filename : `${filename}.pdf`,
    ...options,
  };

  return html2pdf().set(opt).from(element).toPdf()
    .output('datauristring');
  // .then((pdfAsString) => {
  // The PDF has been converted to a Data URI string and passed to this function.
  // Use pdfAsString however you like (send as email, etc)! For instance:
  //  console.log(pdfAsString);
  // });

  // return pdfAsString;
};
