import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  LayoutInbox,
  Banner,
  BannerLogoInbox,
  SearchBannerInbox,
  FooterInbox,
} from 'ux-thebackyard';

import { Profile } from 'components/profile';
import { Notifications } from 'components/notifications';

const Layout = ({ sideBar: SideBar, ...remainderProps }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <LayoutInbox
      collapsed={collapsed}
      onCollapse={setCollapsed}
      sideBar={<SideBar collapsed={collapsed} />}
      banner={(
        <Banner>
          <BannerLogoInbox collapsed={collapsed} onCollapse={setCollapsed} />
          <SearchBannerInbox mr="1rem" />
          <Notifications mr="1rem" />
          <Profile mr="1rem" />
        </Banner>
      )}
      footer={<FooterInbox />}
      {...remainderProps}
    />
  );
};

Layout.propTypes = {
  sideBar: PropTypes.func,
};

export default Layout;
