import { sagas as authSagas } from './auth';
import { sagas as documentsSagas } from './documents';
import { sagas as reportsSagas } from './reports';
import { sagas as uploadDocsSagas } from './uploadDocs';
import { sagas as entitySagas } from './entity';
import { sagas as userSagas } from './user';
import { sagas as shareReportSagas } from './share-report';
import { sagas as addPurchaseOrderSagas } from './add-purchase-order';

import { sagas as usersSagas } from './users';
import { sagas as profilesSagas } from './profiles';
import { sagas as areasSagas } from './areas';
import { sagas as startUpSagas } from './start-up';
import { sagas as relationshipSagas } from './relationship';
import { sagas as systemSagas } from './system';

export default [
  ...authSagas,
  ...documentsSagas,
  ...reportsSagas,
  ...uploadDocsSagas,

  ...userSagas,
  ...shareReportSagas,
  ...addPurchaseOrderSagas,

  ...usersSagas,
  ...profilesSagas,
  ...areasSagas,
  ...entitySagas,

  ...startUpSagas,
  ...relationshipSagas,
  ...systemSagas,
];
