import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useI18n } from 'services/i18n';
import useSelectors from 'helpers/useSelectors';
import { actions as systemActions, selectors as systemSelectors } from 'store/system';

import {
  DataBox, Spinner, Text, Box,
} from 'ux-thebackyard';

const DownloadFile = ({ fileType = 'zip', data, title }) => {
  const t = useI18n();
  const dispatch = useDispatch();

  const [
    isBusy,
    isFailed,
    errorMessage,
    linkData,
  ] = useSelectors(
    systemSelectors.fetchDocument.getIsBusy,
    systemSelectors.fetchDocument.getIsFailed,
    systemSelectors.fetchDocument.getErrorMessage,
    systemSelectors.fetchDocument.getData,
  );

  useEffect(() => () => dispatch(systemActions.fetchDocument.SetData(null)), []);

  useEffect(() => {
    if (data) dispatch(systemActions.fetchDocument.Call({ doc: data, fileType }));
  }, [data]);

  return (
    <DataBox label={t(title)} width="30rem" separator>
      <Box p="10px" centeredContent minHeight="5rem">
        {isBusy && <Spinner size="3rem" width="5px" /> }
        {linkData && <a rel="noreferrer" target="_blank" href={linkData}>{t('download_file_link')}</a>}
        {isFailed && <Text mr="2rem" color="red">{errorMessage?.message}</Text>}
      </Box>
    </DataBox>
  );
};

DownloadFile.propTypes = {
  fileType: PropTypes.oneOf(['pdf', 'xml', 'zip']).isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

export default DownloadFile;
