import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useI18n } from 'services/i18n';

import useSelectors from 'helpers/useSelectors';
import useSearch from 'services/search/useSearch';
import {
  SvgSyncSolid,
} from 'react-line-awesome-svg';
import {
  actions as docsActions,
  selectors as docsSelectors,
} from 'store/documents';
import {
  ableParamsToFetch, cfdiTypes,
} from 'own/contants';
import { cfdiStatesValues } from 'own/helpers';
import { selectors as userSelectors } from 'store/user';

import {
  DataBox,
  Accordion,
  TableBox,
  FormInbox,
  FormInboxFieldTypes,
} from 'ux-thebackyard';
import PaymentComplementTrans from 'own/table-transmuters/payment-complement';

import { roles } from '../constants';

const role = roles[1];

const PCsTable = () => {
  const t = useI18n();
  const dispatch = useDispatch();
  const { search: { [role]: search = '' }, params: { [role]: params = {} }, updateParams: updateParamsT } = useSearch();
  const updateParams = (p) => updateParamsT(p, role);

  const [
    rfcsIssuer,
    rfcsReceiver,

    pcs,
    isBusy,
    pagination,
  ] = useSelectors(
    userSelectors.fetchRFCs.getRFCsIssuer,
    userSelectors.fetchRFCs.getRFCsReceiver,

    docsSelectors.getDocs2,
    docsSelectors.getDocs2IsBusy,
    docsSelectors.getDocs2Pagination,
  );

  const FetchPCs = () => dispatch(docsActions.FetchDocs2({
    ...params,
    [ableParamsToFetch.TYPE]: cfdiTypes.P,
  }));

  const handleChangePage = (pageT) => updateParams({ page: pageT });

  useEffect(() => {
    FetchPCs();
  }, [search]);

  const formConfig = [
    {
      label: t('receiver_rfc'),
      enableSearch: true,
      items: rfcsReceiver,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableParamsToFetch.RFC_RECEIVER,
    },
    {
      label: t('issuing_rfc'),
      enableSearch: true,
      items: rfcsIssuer,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableParamsToFetch.RFC_ISSUER,
    },
    {
      label: t('state'),
      enableSearch: true,
      items: cfdiStatesValues,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableParamsToFetch.STATE,
    },
    {
      label: t('date'),
      fieldType: FormInboxFieldTypes.DATE_RANGE,
      fieldNameStartDate: ableParamsToFetch.DATE_IN,
      fieldNameEndDate: ableParamsToFetch.DATE_END,
    },
    {
      label: t('uuid_fiscal_folio'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableParamsToFetch.UUID,
    },
    {
      label: t('total'),
      fieldType: FormInboxFieldTypes.NUMERIC_RANGE,
      fieldNameStartValue: ableParamsToFetch.TOTAL_IN,
      fieldNameEndValue: ableParamsToFetch.TOTAL_END,
    },
    {
      label: t('serie'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableParamsToFetch.SERIE,
    },
    {
      label: t('folio'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableParamsToFetch.FOLIO,
      type: 'number',
    },
  ];

  const headBtnsProps = [
    {
      label: t('update'), design: 'shadow', svg: SvgSyncSolid, rounded: true, onClick: FetchPCs,
    },
  ];

  return (
    <DataBox
      label={t('payment_complement_list')}
      headBtnsProps={headBtnsProps}
      separator
    >
      <Accordion label={t('filter_search')}>
        <FormInbox
          items={formConfig}
          value={params}
          onSubmit={updateParams}
          submitBtnProps={{ label: t('apply') }}
        />
      </Accordion>

      <TableBox
        transmuter={PaymentComplementTrans.transmuter}
        heads={PaymentComplementTrans.heads}
        data={pcs}
        isBusy={isBusy}
        noFoundMessage={t('no_payment_complements_found')}
        pagination={{
          page: pagination?.page,
          totalPages: pagination?.totalPages,
          totalItems: pagination?.totalDocs,
          pageSize: 50, // TODO
        }}
        onChangePage={handleChangePage}
        stickyHeaders
      />
    </DataBox>
  );
};

export default PCsTable;
