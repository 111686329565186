import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useI18n } from 'services/i18n';
import {
  SvgTrashAlt, SvgQuestionCircle, SvgCheckCircle, SvgTimesCircle,
  SvgUploadSolid,
} from 'react-line-awesome-svg';

import {
  Box,
  Icon,
  Text,
} from 'ux-thebackyard';

import { formatBytes } from 'helpers/general';
import PDFImageSrc from 'media/pdf.png';
import XMLImageSrc from 'media/xml.png';
import { isValidPair } from '../helpers';
// TODO ver si se puede migrar todo estos iconos para la otra library de iconos

const FlexBox = styled(Box)`
  display: flex;
  align-items: center; 
`;

const singleFileStyle = {
  border: 'solid 1px red',
  backgroundColor: 'rgba(240,0,0,0.1)',
  borderRadius: '5px',
};

const pairFileStyle = {
  border: 'solid 1px transparent',
  borderRadius: '5px',
};

const StyledImg = styled('img')`
  width: 1.8rem;
`;

// TODO: Integrar este componente en la library
const Avatar = styled(Box)`
  color: #fafafa;
  background-color: #bdbdbd;
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
`;

const IconButton = styled.button`
    border: 0; 
    background-color: transparent;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover{
      background-color: #DDD;
    }
    &:focus{
      outline: none;
    }
`;

const ListItemAvatar = styled(Box)`
    min-width: 56px;
    flex-shrink: 0;
`;

const ListItemText = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  margin-bottom: 6px;
  flex: 1 1 auto;
  min-width: 0;
`;

export const states = {
  FAILED: 'failed',
  UPLOADED: 'uploaded',
  NO_UPLOADED: 'noUploaded',
};

const FileListItem = ({ filesPair, onDelete }) => {
  const t = useI18n();
  const { pdf, xml, state = states.NO_UPLOADED } = filesPair || {};
  const isValid = isValidPair(filesPair);

  return (
    <FlexBox pl="2rem" pr="2rem" mt="10px" {...(isValid ? pairFileStyle : singleFileStyle)}>
      <FlexBox width="45%">
        <ListItemAvatar>
          <Avatar>
            {pdf ? (
              <StyledImg src={PDFImageSrc} alt="pdf" />
            ) : (
              <Icon svg={SvgQuestionCircle} size="25px" color="gray" />
            )}
          </Avatar>
        </ListItemAvatar>
        <ListItemText>
          <Text fontWeight="med" fontSize="md">
            {pdf?.name || t('unknownFile')}
          </Text>
          <Text fontWeight="reg" color="rgba(0, 0, 0, 0.54)" fontSize="sm">
            {(pdf?.size && formatBytes(pdf?.size)) || null}
          </Text>
        </ListItemText>
      </FlexBox>

      <FlexBox width="45%">
        <ListItemAvatar>
          <Avatar>
            {xml ? (
              <StyledImg src={XMLImageSrc} alt="xml" />
            ) : (
              <Icon svg={SvgQuestionCircle} size="25px" color="gray" />
            )}
          </Avatar>
        </ListItemAvatar>
        <ListItemText>
          <Text fontWeight="med" fontSize="md">
            {xml?.name || t('unknownFile')}
          </Text>
          <Text fontWeight="reg" color="rgba(0, 0, 0, 0.54)" fontSize="sm">
            {(xml?.size && formatBytes(xml?.size)) || null}
          </Text>
        </ListItemText>
      </FlexBox>

      <Box ml="auto" display="flex" alignItems="center">
        {isValid && (
        <>
          {state === states.NO_UPLOADED && <Icon size="25px" svg={SvgUploadSolid} color="#3f51b5" />}
          {state === states.UPLOADED && <Icon size="25px" svg={SvgCheckCircle} color="green" />}
          {state === states.FAILED && <Icon size="25px" svg={SvgTimesCircle} color="red" />}
        </>
        )}

        <IconButton onClick={onDelete}>
          <Icon svg={SvgTrashAlt} size="25px" color="gray" />
        </IconButton>
      </Box>
    </FlexBox>
  );
};

const filePropType = PropTypes.shape({
  name: PropTypes.string,
  size: PropTypes.number,
});

FileListItem.propTypes = {
  filesPair: PropTypes.shape({
    pdf: filePropType,
    xml: filePropType,
    state: PropTypes.oneOf(Object.values(states)),
  }).isRequired,
  onDelete: PropTypes.func,
};

export default FileListItem;
