import React from 'react';
import PropTypes from 'prop-types';
import { useModal } from 'services/modal';
import { useI18n } from 'services/i18n';

import {
  DataBox, Button, Text, Box,
} from 'ux-thebackyard';

const Confirm = ({
  title, message, onConfirm, isBusy, errorMessage,
}) => {
  const { ModalFooter } = useModal();
  const t = useI18n();

  return (
    <>
      <DataBox label={t(title)} width="30rem" separator>
        <Box p="10px" centeredContent minHeight="10rem">
          <Text>{t(message)}</Text>
        </Box>

      </DataBox>
      <ModalFooter
        showSpinner={isBusy}
        closeButtonLabel="cancel"
        message={!!errorMessage && ({ type: 'error', text: errorMessage })}
      >
        <Button
          design="primary"
          rounded
          m="10px"
          p="12px 30px"
          onClick={onConfirm}
          disabled={isBusy}
          label={t('accept')}
        />
      </ModalFooter>
    </>
  );
};

Confirm.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func,
  isBusy: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default Confirm;
