import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useI18n } from 'services/i18n';
import { useModal } from 'services/modal';
import {
  ableProfileFields, ableAreaFields, roles, modifiers,
} from 'own/contants';
import { actions as profilesActions, selectors as profilesSelectors } from 'store/profiles';
import { actions as areasActions, selectors as areasSelectors } from 'store/areas';

import useSelectors from 'helpers/useSelectors';
import PropTypes from 'prop-types';

import {
  DataBox,
  Button,
  FormInbox,
  FormInboxFieldTypes,
  FormInboxValidationType,
} from 'ux-thebackyard';

const valuesRoles = Object.values(roles);
const valuesModifiers = Object.values(modifiers);

const customObjValues = {
  [modifiers.relationship]: [
    roles.full,
    roles.read,
    roles.remove,
    roles.write,
  ],
  [modifiers.reports]: [
    roles.read,
    roles.full,
  ],
  [modifiers.sat]: [
    roles.full,
    roles.update,
    roles.remove,
    roles.write,
  ],
  [modifiers.entity]: [
    roles.update,
    roles.read,
  ],
  [modifiers.entity]: [
    roles.update,
    roles.read,
  ],
};

const itemsDropdownRoles = valuesModifiers.map((mod) => ({
  key: mod,
  inclusiveValue: roles.full,
  values: customObjValues[mod] || valuesRoles,
}));

const defaultProfile = {
  [ableProfileFields.PROFILE_NAME]: '',
  [ableProfileFields.PROFILE_AREAS]: [],
  [ableProfileFields.PROFILE_ROLES]: [],
  [ableProfileFields.PROFILE_RULES]: [],
};

const NewUpdateProfile = ({ profile }) => {
  const [currentProfile, setCurrentProfile] = useState(defaultProfile);
  const [submitBtnProps, setSubmitBtnProps] = useState({});

  const dispatch = useDispatch();
  const t = useI18n();

  const { onClose: onModalClose, ModalFooter } = useModal();

  const [
    areas,

    isBusyAddProfile,
    isSuccessAddProfile,

    isBusyUpdateProfile,
    isSuccessUpdateProfile,
  ] = useSelectors(
    areasSelectors.fetch.getData,

    profilesSelectors.add.getIsBusy,
    profilesSelectors.add.getIsSuccess,

    profilesSelectors.update.getIsBusy,
    profilesSelectors.update.getIsSuccess,
  );

  useEffect(() => () => {
    dispatch(profilesActions.add.NoStarted());
    dispatch(profilesActions.update.NoStarted());
  }, []);

  useEffect(() => {
    if (!areas?.length) dispatch(areasActions.fetch.Call());
  }, [areas]);

  useEffect(() => {
    if (isSuccessAddProfile || isSuccessUpdateProfile) {
      dispatch(profilesActions.fetch.Call());
      onModalClose();
    }
  }, [isSuccessAddProfile, isSuccessUpdateProfile]);

  useEffect(() => {
    if (profile) {
      const areasId = profile[ableProfileFields.PROFILE_AREAS]?.map((area) => area[ableAreaFields.ID]);

      setCurrentProfile({
        ...profile,
        [ableProfileFields.PROFILE_AREAS]: areasId,
      });
    }
  }, [profile]);

  const itemsForm = [
    {
      label: t('name'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: ableProfileFields.PROFILE_NAME,
      required: true,
      validations: [
        { type: FormInboxValidationType.REQUIRED },
      ],
    },
    {
      label: t('areas'),
      enableSearch: true,
      items: areas,
      optionTextField: ableAreaFields.AREA_NAME,
      optionValueField: ableAreaFields.ID,
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: ableProfileFields.PROFILE_AREAS,
      required: true,
      validations: [
        { type: FormInboxValidationType.REQUIRED },
      ],
    },
    {
      label: t('roles'),
      fieldType: FormInboxFieldTypes.DROPDOWN_MULTI_SELECT,
      items: itemsDropdownRoles,
      enableSearch: true,
      fieldName: ableProfileFields.PROFILE_ROLES,
      required: true,
      validations: [
        { type: FormInboxValidationType.REQUIRED }, // TODO verificar si hay roles por default
      ],
    },
  ];

  const handleOnSubmit = (profileT) => dispatch(profilesActions[profile ? 'update' : 'add'].Call(profileT));

  return (
    <>
      <DataBox label={t(profile ? 'update_profile' : 'new_profile')} separator width="35rem">
        <FormInbox
          items={itemsForm}
          value={currentProfile}
          onChange={setCurrentProfile}
          onSubmit={handleOnSubmit}
          changeExternSubmitBtnProps={setSubmitBtnProps}
        />
      </DataBox>
      <ModalFooter showSpinner={isBusyAddProfile || isBusyUpdateProfile}>
        <Button
          design="primary"
          rounded
          m="10px"
          p="12px 30px"
          label={t('upload')}
          {...submitBtnProps}
        />
      </ModalFooter>
    </>
  );
};

NewUpdateProfile.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any),
};

export default NewUpdateProfile;
