import React from 'react';
import { ToShortDate } from 'helpers/date';
import { useModal } from 'services/modal';
import RelatedDocs from 'scenes/modals/related-docs';
import { formatNumberToCurrency } from 'helpers/general';
import { TableActionsInbox, TableActionsInboxTypes } from 'ux-thebackyard';
import StateButton from 'components/state-button';
import Translate from 'components/translate';

const Actions = ({ data = {} }) => {
  const { renderComponent } = useModal();
  const handleOpenRelatedDocs = () => renderComponent(() => <RelatedDocs doc={data} />);

  const actions = [
    { type: TableActionsInboxTypes.RELATED_DOCS, onClick: handleOpenRelatedDocs },
  ];

  return (
    <TableActionsInbox actions={actions} />
  );
};

const heads = [
  'actions',
  'number',
  'issuing_rfc',
  'receiver_rfc',
  'total',
  'date',
  'state',
];

const transmuter = (data) => {
  const {
    date, noOrdenC, rfcIssuer, rfcReceiver, total,
  } = data;

  return [
    <Actions data={data} />,
    noOrdenC,
    rfcIssuer,
    rfcReceiver,
    formatNumberToCurrency(total),
    ToShortDate(date, 'string'),
    <StateButton data={data} />,
  ];
};

export default {
  heads: heads?.map((head) => <Translate label={head} />),
  transmuter,
};
