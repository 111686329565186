export const splitCommaSeparatedArray = (str) => {
  if (!str) return str;

  const value = str?.split?.(',');
  return typeof value === 'string' ? [value] : value;
};

export const formatNumberToCurrency = (number, currency = 'USD', locale = 'en-Us') => new Intl.NumberFormat(locale, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  currency,
  style: 'currency',
}).format(number);

export const formatBytes = (bytes, decimals) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
};

export const getInitials = (username) => {
  const nameSplit = String(username)
    .toUpperCase()
    .split(' ');

  if (nameSplit.length === 1) {
    return nameSplit[0] ? nameSplit[0].charAt(0) : '?';
  }

  return nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
};
