import React, { useEffect, useState } from 'react';
import { useI18n } from 'services/i18n';
import { useDispatch } from 'react-redux';
import useSelectors from 'helpers/useSelectors';
import { cfdiPropType } from 'helpers/propTypes';
import { actions, selectors } from 'store/relationship';

import RelatedDocsTrans from 'own/table-transmuters/related-docs';

import {
  TableBox,
  DataBox,
} from 'ux-thebackyard';

const RelatedDocs = ({ doc }) => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const t = useI18n();

  const [
    isBusy,
    relatedDocs,
    pagination,
  ] = useSelectors(
    selectors.fetch.getIsBusy,
    selectors.fetch.getData,
    selectors.fetch.getPagination,
  );

  const handleFetchRelatedDocs = () => dispatch(actions.fetch.Call({ page, doc }));

  useEffect(() => {
    if (doc) handleFetchRelatedDocs();
  }, [doc, page]);

  useEffect(() => {
    if (doc) dispatch(actions.SetRefDoc(doc)); // used in actions
  }, [doc]);

  return (
    <DataBox label={t('related_docs')} separator width="80vw">

      <TableBox
        transmuter={RelatedDocsTrans.transmuter}
        heads={RelatedDocsTrans.heads}
        data={relatedDocs}
        isBusy={isBusy}
        noFoundMessage={t('no_related_docs_found')}
        pagination={{
          page: pagination?.page,
          totalPages: pagination?.totalPages,
          totalItems: pagination?.totalDocs,
          pageSize: 50, // TODO
        }}
        onChangePage={setPage}
        height="15rem"
        stickyHeaders
      />
    </DataBox>
  );
};

RelatedDocs.propTypes = {
  doc: cfdiPropType,
};
export default RelatedDocs;
