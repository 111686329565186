import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import { call, put } from 'redux-saga/effects';
import RS from 'helpers/RS';
import {
  pageSize, ableParamsToFetchDocs, docsTypes, cfdiTypes,
} from 'own/contants';
import { getPagination } from '../helpers';

const NAME = 'relationship';

/// ///////////////////////////////////////////////////////////////

const SELECTED_ITEMS = 'selectedItems';
const SET_SELECTED_ITEMS_TO_RELATE = `${SELECTED_ITEMS}.SET_SELECTED_ITEMS_TO_RELATE`;
const SetSelectedItemsToRelate = (data) => ({
  type: SET_SELECTED_ITEMS_TO_RELATE,
  payload: data,
});

const SelectedItemsReducer = {
  [SELECTED_ITEMS]: (
    (state = [], action = {}) => {
      switch (action.type) {
        case SET_SELECTED_ITEMS_TO_RELATE:
          return action.payload;
        default:
          return state;
      }
    }
  ),
};

const getSelectedItems = (state) => state[NAME][SELECTED_ITEMS] || [];

/// ///////////////////////////////////////////////////////////////

const SELECTED_ALL = 'selectedAll';
const SET_SELECTED_ALL_TO_RELATE = `${SELECTED_ALL}.SET_SELECTED_ALL_TO_RELATE`;
const SetSelectedAllToRelate = (data) => ({
  type: SET_SELECTED_ALL_TO_RELATE,
  payload: data,
});

const SelectedAllReducer = {
  [SELECTED_ALL]: (
    (state = false, action = {}) => {
      switch (action.type) {
        case SET_SELECTED_ALL_TO_RELATE:
          return action.payload;
        default:
          return state;
      }
    }
  ),
};

const getSelectedAll = (state) => state[NAME][SELECTED_ALL];

/// ///////////////////////////////////////////////////////////////

const REF_DOC = 'refDoc';
const SET_REF_DOC = `${REF_DOC}.SET_REF_DOC`;
const SetRefDoc = (data) => ({
  type: SET_REF_DOC,
  payload: data,
});

const RefDocReducer = {
  [REF_DOC]: (
    (state = null, action = {}) => {
      switch (action.type) {
        case SET_REF_DOC:
          return action.payload;
        default:
          return state;
      }
    }
  ),
};

const getRefDoc = (state) => state[NAME][REF_DOC];

/// ////////////////////////////////////////////////////////////////////////////////

function* add(api, { payload: params }) {
  const response = yield call(api.addRelationships, { params });
  yield put(SetSelectedItemsToRelate([]));
  yield put(SetSelectedAllToRelate(false));
  return response;
}

const addRS = RS({
  nestedPath: [NAME],
  storeName: 'add',
  tryGenerator: add,
});

// /////////////////////////////////////////////////////////////////////////////////

function* fetch(api, { payload: { page, doc } = {} }) {
  const { uuid, type, _id } = doc;

  const isPurchase = type === docsTypes.PURCHASE;

  const params = {
    page,
    pageSize,
    type,
    ...(!isPurchase
      ? { [ableParamsToFetchDocs.UUID]: uuid }
      : {}
    ),
    ...(isPurchase
      ? { [ableParamsToFetchDocs.ID_ORDEN_C]: _id }
      : {}),
  };

  return yield call(api.fetchRelationships, { params });
}

const fetchRS = RS({
  nestedPath: [NAME],
  storeName: 'fetch',
  tryGenerator: fetch,
});

const getFetchData = createSelector(
  fetchRS.selectors.getData,
  (data) => data?.list || [],
);

const getFetchPagination = createSelector(
  fetchRS.selectors.getData,
  (data) => getPagination(data),
);

// /////////////////////////////////////////////////////////////////////////////////

function* remove(api, { payload: { refDoc, doc } = {} }) {
  const { _id, type } = refDoc;
  const { uuid } = doc;
  if (type !== docsTypes.PURCHASE || !uuid) {
    console.error('Does not have valid params');
    return null;
  }
  const params = {
    [ableParamsToFetchDocs.ID_ORDEN_C]: _id,
    cfdiDelete: [uuid],
  };
  return yield call(api.removeRelationship, { params });
}

const removeRS = RS({
  nestedPath: [NAME],
  storeName: 'remove',
  tryGenerator: remove,
});

// /////////////////////////////////////////////////////////////////////////////////

export const sagas = [
  addRS.sagas,
  fetchRS.sagas,
  removeRS.sagas,
];

export const reducer = {
  [NAME]: combineReducers({
    ...SelectedItemsReducer,
    ...SelectedAllReducer,
    ...RefDocReducer,

    ...addRS.reducer,
    ...fetchRS.reducer,
    ...removeRS.reducer,
  }),
};

export const actions = {
  SetSelectedItemsToRelate,
  SetSelectedAllToRelate,
  SetRefDoc,

  add: addRS.actions,
  fetch: fetchRS.actions,
  remove: removeRS.actions,
};

// Selectors
export const selectors = {
  getSelectedItems,
  getSelectedAll,
  getRefDoc,

  fetch: {
    ...fetchRS.selectors,
    getData: getFetchData,
    getPagination: getFetchPagination,
  },

  add: addRS.selectors,

  remove: removeRS.selectors,
};
