import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { call } from 'redux-saga/effects';
import RS from 'helpers/RS';
import { checkFetchDocsParams, removeNoAllowedParams } from 'helpers/api';
import {
  ableParamsToFetchDocs,
} from 'own/contants';

import { getPagination } from '../helpers';

const NAME = 'documents';

function* FetchDocuments(api, { payload: params }) {
  const allowedParams = removeNoAllowedParams(params, Object.values(ableParamsToFetchDocs));
  const updatedParams = checkFetchDocsParams(allowedParams);
  return yield call(api.getDocs, { params: updatedParams });
}

const docs1RS = RS({
  nestedPath: [NAME],
  storeName: 'docs1',
  tryGenerator: FetchDocuments,
});

const docs2RS = RS({
  nestedPath: [NAME],
  storeName: 'docs2',
  tryGenerator: FetchDocuments,
});

/// ////////////////////////////////////////////////////////////////////////////////

export const sagas = [
  docs1RS.sagas,
  docs2RS.sagas,
];

export const reducer = {
  [NAME]: combineReducers({
    ...docs1RS.reducer,
    ...docs2RS.reducer,
  }),
};

/// ////////////////////////////////////////////////////////////////////////////////

export const actions = {
  FetchDocs1: docs1RS.actions.Call,
  FetchDocs2: docs2RS.actions.Call,
};

// Selectors
const getDocs1Data = docs1RS.selectors.getData;
const getDocs1IsBusy = docs1RS.selectors.getIsBusy;

const getDocs1 = createSelector(
  getDocs1Data,
  (data) => data?.list || [],
);

const getDocs1Pagination = createSelector(
  getDocs1Data,
  (data) => getPagination(data),
);

/// //////////////////////////////////////////////////////////////////////

const getDocs2Data = docs2RS.selectors.getData;
const getDocs2IsBusy = docs2RS.selectors.getIsBusy;

const getDocs2 = createSelector(
  getDocs2Data,
  (data) => data?.list || [],
);

const getDocs2Pagination = createSelector(
  getDocs2Data,
  (data) => getPagination(data),
);

export const selectors = {
  getDocs1,
  getDocs1Pagination,
  getDocs1IsBusy,

  getDocs2,
  getDocs2Pagination,
  getDocs2IsBusy,
};
