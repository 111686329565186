import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToShortDate } from 'helpers/date';
import { formatNumberToCurrency } from 'helpers/general';
import {
  actions as relationshipActions,
  selectors as relationshipSelectors,
} from 'store/relationship';
import TypeDocField from 'components/type-doc-field';
import Translate from 'components/translate';
import { Checkbox } from 'ux-thebackyard';

const AllSelectCheckbox = () => {
  const dispatch = useDispatch();
  const selectedAll = useSelector(relationshipSelectors.getSelectedAll);
  const handleOnChange = (value) => {
    dispatch(relationshipActions.SetSelectedAllToRelate(value));
    if (value) dispatch(relationshipActions.SetSelectedItemsToRelate([]));
  };
  return (
    <Checkbox checked={selectedAll} /> // TODO
  );
};

const OneSelectCheckbox = ({ data = {} }) => {
  const { uuid } = data;
  const dispatch = useDispatch();
  const selectedAll = useSelector(relationshipSelectors.getSelectedAll);
  const selectedItems = useSelector(relationshipSelectors.getSelectedItems);

  const checked = selectedAll || selectedItems.includes(uuid);

  const handleOnChange = (value) => {
    dispatch(relationshipActions.SetSelectedItemsToRelate(
      value
        ? [...selectedItems, uuid]
        : selectedItems.filter((item) => item !== uuid),
    ));
  };

  return (
    <Checkbox checked={checked} onChange={handleOnChange} />
  );
};

const heads = [
  <AllSelectCheckbox />,
  'type',
  'serie',
  'folio',
  'date',
  'issuing_rfc',
  'receiver_rfc',
  'total',
  'uuid_fiscal_folio',
];

const transmuter = (data) => {
  const {
    uuid, serie, folio, date, rfcIssuer, rfcReceiver, total, type,
  } = data;

  return [
    <OneSelectCheckbox data={data} />,
    <TypeDocField type={type} />,
    serie,
    folio,
    ToShortDate(date, 'string'),
    rfcIssuer,
    rfcReceiver,
    formatNumberToCurrency(total),
    uuid,
  ];
};

export default {
  heads: heads?.map((head) => <Translate label={head} />),
  transmuter,
};
