import React, { useEffect } from 'react';
import { useI18n } from 'services/i18n';

import { useModal } from 'services/modal';
import useSelectors from 'helpers/useSelectors';
import { useHasRoles } from 'services/hasRoles';
import useSearch from 'services/search/useSearch';
import { useDispatch } from 'react-redux';

import { actions as userActions, selectors as userSelectors } from 'store/user';
import NewProvider from 'scenes/modals/new-provider';

import {
  SvgPlusSolid,
  SvgSyncSolid,
} from 'react-line-awesome-svg';

import {
  DataBox,
  Accordion,
  PageInbox,
  TableBox,
  FormInbox,
  FormInboxFieldTypes,
} from 'ux-thebackyard';
import ManagementProvider from 'own/table-transmuters/management-provider';

const Providers = () => {
  const t = useI18n();
  const dispatch = useDispatch();
  const { params, updateParams } = useSearch();

  const { renderComponent } = useModal();

  const [
    providers,
    providersIsBusy,
  ] = useSelectors(
    userSelectors.fetchProviders.getData,
    userSelectors.fetchProviders.getIsBusy,
  );

  const handleShowModalNewProvider = () => renderComponent(NewProvider, { hideFooter: true });
  const handleFetchUserProviders = () => dispatch(userActions.fetchProviders.Call());

  useEffect(() => {
    if (!providers) handleFetchUserProviders();
  }, [providers]);

  const { provider: { full, write } } = useHasRoles();

  const headBtnsProps = [
    (full || write) && {
      label: t('new'), design: 'primary', svg: SvgPlusSolid, onClick: handleShowModalNewProvider,
    },
    {
      label: t('update'), design: 'shadow', svg: SvgSyncSolid, rounded: true, onClick: handleFetchUserProviders,
    },
  ];

  const formConfig = [
    {
      label: t('receiver_rfc'),
      enableSearch: true,
      items: [],
      fieldType: FormInboxFieldTypes.DROPDOWN_SELECT,
      fieldName: 'moked1',
    },
    {
      label: t('user'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: 'moked2',
    },
    {
      label: t('email'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: 'moked3',
    },
    {
      label: t('social_reason'),
      fieldType: FormInboxFieldTypes.INPUT,
      fieldName: 'moked4',

    },
  ];

  return (
    <PageInbox label={t('providers')}>
      <DataBox label={t('providers_management')} separator headBtnsProps={headBtnsProps}>
        <Accordion label={t('filter_search')}>
          <FormInbox
            items={formConfig}
            value={params}
            onSubmit={updateParams}
            submitBtnProps={{ label: t('apply') }}
          />
        </Accordion>

        <TableBox
          transmuter={ManagementProvider.transmuter}
          heads={ManagementProvider.heads}
          height="calc(100vh - 430px)"
          data={providers}
          isBusy={providersIsBusy}
          noFoundMessage={t('no_providers_found')}
          stickyHeaders
        />
      </DataBox>
    </PageInbox>
  );
};

export default Providers;
