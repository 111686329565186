const NAME = 'modal';

// ACTIONS
const PUSH_COMPONENT_TO_RENDER = `${NAME}.PUSH_COMPONENT_TO_RENDER`;
const PushComponentToRender = (data) => ({
  type: PUSH_COMPONENT_TO_RENDER,
  payload: data,
});

const POP_COMPONENT_TO_RENDER = `${NAME}.POP_COMPONENT_TO_RENDER`;
const PopComponentToRender = () => ({
  type: POP_COMPONENT_TO_RENDER,
  payload: null,
});

export const actions = {
  PushComponentToRender,
  PopComponentToRender,
};

// REDUCER
const defaultState = {
  components: [],
};

const ModalReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case PUSH_COMPONENT_TO_RENDER: {
      const currentComponents = [...state.components];
      currentComponents.push(action.payload);
      return {
        ...state,
        components: currentComponents,
      };
    }
    case POP_COMPONENT_TO_RENDER: {
      const currentComponents = [...state.components];
      currentComponents.pop();
      return {
        ...state,
        components: currentComponents,
      };
    }
    default:
      return state;
  }
};

export const reducer = {
  [NAME]: ModalReducer,
};

// SELECTORS
const getComponents = (state) => state[NAME].components;

export const selectors = {
  getComponents,
};
